import CommonUtils from './common'
import paths from 'routers/paths'
import { FT_DEL_ACC, DEL_ACC } from 'constants/userProfile'
import { getParamFromURL } from 'utils/booking/common'
import { showLoading } from 'assets/javascripts/webapp-v2/common'


export const checkPramDeletionAccount = async (currentCustomer) => {
  const isDeletionAccount = getParamFromURL(DEL_ACC) === 'true'

  if(isDeletionAccount) {
    showLoading()
    if (currentCustomer.id) {
      CommonUtils.handleSignOut(currentCustomer.authentication_token, () => {
        window.location = `${paths.SIGN_IN}?${FT_DEL_ACC}=true`
      })
    } else {
      window.location = `${paths.SIGN_IN}?${FT_DEL_ACC}=true`
    }
  }
}