import _ from 'lodash'
// UTILS
import locationUtils from 'utils/common/location'
import { Utils } from 'utils/Utils'
import roundTripDiscountUtils from 'utils/common/booking/roundTripDiscount'
import {
  vehicleTypes as vehicleTypesUtils,
} from 'utils/new_booking/common'
// API
// ACTIONS
import * as locationActions from './locationActionCreators'
// CONSTANTS
import * as IMAGES from 'constants/imageConstants'
import { setDefaultMaxLocation } from 'utils/booking/common'
import { roundTripDiscountActionsCreator } from 'store/toolkit/newBooking/roundTripDiscount.reducer'
import { locationsActionsCreator } from 'store/toolkit/locations/locations.reducer'
import mapUtils from 'utils/common/map'
// ASSETS

export const updateRoundTripValue = value => (dispatch, getState) => {
  const {
    isOptimized,
    beforeOptimizedLocations
  } = getState()
  const tempLocationsBeforeOptimize = [...beforeOptimizedLocations]
  Promise.resolve(
    dispatch(roundTripDiscountActionsCreator.updateRoundTripDiscount(value))
  ).then(() => {
    if (isOptimized) {
      dispatch(locationActions.optimizeLocations(tempLocationsBeforeOptimize))
    }
    dispatch(locationActions.drawBookingLocationRoute())
  })
}

export const update = (value, updateValueOnly = false) => (dispatch, getState) => {
  if (updateValueOnly) {
    dispatch(updateRoundTripValue(value))
    return
  }
  const {
    locations,
    currentCustomer,
    extraInfos,
    isOptimized,
    beforeOptimizedLocations
  } = getState()
  const pickup = _.first(locations)
  const destination = _.last(locations)
  const tempLocationsBeforeOptimize = [...beforeOptimizedLocations]
  const locationPickup = locationUtils.locationPosition(pickup)

  let lastLegMarker
  if (window.google && window.google.maps) {
    lastLegMarker = new window.google.maps.Marker({
      position: new window.google.maps.LatLng(pickup.lat, pickup.lng),
      map: getState().googleMap.map,
      // animation:  window.google.maps.Animation.DROP,
      draggable: true,
      optimized: false,
      icon: IMAGES.ROUND_TRIP_DISCOUNT_PIN_NONE_ICON,
    })
  }

  if (value) {
    const locationDropOff = locationUtils.locationPosition(destination)
    const totalDropOffs = _.size(locations)
    const isDesEqualPickup = (locationPickup.lat === locationDropOff.lat || locationPickup.lng === locationDropOff.lng)
    const attrs = {
      description: '' || pickup.description,
      lat: undefined || locationPickup.lat,
      lng: undefined || locationPickup.lng,
      name: undefined || pickup.name,
      recipient_name: '' || pickup.recipient_name,
      recipient_phone: '' || pickup.recipient_phone,
      marker: undefined || lastLegMarker,
      phone_mask: pickup.phone_mask,
      address_components: pickup.address_components
    }
    if (totalDropOffs === 2
      && locationPickup.lat === locationDropOff.lat
      && locationPickup.lng === locationDropOff.lng) {
      dispatch(locationActions.addLocation(attrs))
    } else if (totalDropOffs === setDefaultMaxLocation(currentCustomer, extraInfos)
      && _.isUndefined(destination.lat) && _.isUndefined(destination.lng)) {
      dispatch(locationsActionsCreator.updateLocation({ id: destination.id, locationAttrs: attrs }))
    } else if (!isDesEqualPickup) {
      dispatch(locationActions.addLocation(attrs))
    }
    if (isOptimized && !isDesEqualPickup) {
      const lastLeg = { ...pickup }
      lastLeg.id = Utils.uniqueId()
      lastLeg.is_payer = extraInfos.default_payer_is_destination
      lastLeg.marker = lastLegMarker
      tempLocationsBeforeOptimize.push(lastLeg)
    }
  } else {
    // if round trip location is the last dropoff
    if (isOptimized) {
      tempLocationsBeforeOptimize.pop()
    }
    if (_.size(locations) === 2 && !_.isUndefined(pickup.lat)) {
      dispatch(locationActions.addLocation())
    }
    dispatch(locationActions.removeLocation(_.last(locations)))
  }
  Promise.resolve(
    dispatch(roundTripDiscountActionsCreator.updateRoundTripDiscount(value))
  ).then(() => {
    if (isOptimized) {
      dispatch(locationActions.optimizeLocations(tempLocationsBeforeOptimize))
    }
    dispatch(locationActions.drawBookingLocationRoute())
  })
}

export const updateIfRoundTripDiscountShouldShowChanged = vehicleTypeID => (dispatch, getState) => {
  const {
    locations,
    roundTripDiscount,
    timeType,
    currentCustomer,
    extraInfos,
  } = getState()
  if (!roundTripDiscount) { return }
  const vehicleType = _.find(vehicleTypesUtils(getState()), { id: vehicleTypeID })
  const enableRoundTrip = _.isUndefined(vehicleType)
    ? []
    : vehicleType.settings.enable_round_trip
  const shouldShowRT = roundTripDiscountUtils.shouldShow(
    locations,
    roundTripDiscount,
    timeType,
    currentCustomer.current_company_id,
    enableRoundTrip,
    setDefaultMaxLocation(currentCustomer, extraInfos),
    extraInfos,
  )
  if (!shouldShowRT && roundTripDiscount) { dispatch(update(false)) }
}
