export default {
  NEW_BOOKING: '/',
  NEW_BOOKING_SINGLE: '/bookings/new',

  EDIT_BOOKING: '/bookings/:id/edit',
  BOOK_AGAIN_BOOKING: '/bookings/:id/book_again',

  MULTIPLE_BOOKING: '/bookings/multiple',
  EZ_SPREAD_BOOKING: '/batches/ez_spread_sheet',
  SMART_PLANNER_BOOKING: '/batches/smart_load_planner',
  SELECT_AREA: '/business/select_area',

  // AUTHENTICATION
  SIGN_IN: '/customers/sign_in',
  SIGN_UP: '/customers/sign_up',
  RESET_PASSWORD: '/reset-password',
  CHANGE_PASSWORD: '/change-password',
  MERGE_ACCOUNT: '/merge-account',
  // My booking
  BOOKINGS: '/bookings',

	// My booking
	BOOKING_DETAIL: '/bookings/:id',

	// ERROR
	NOT_FOUND: "/404",

  //LOCATION
  LOCATIONS: '/locations',
  ADD_LOCATIONS: '/locations/new',
  LOCATION_DETAIL: '/locations/:id/edit',

  //EMPLOYEES
  EMPLOYEES: '/business/employees',
  NEW_EMPLOYEE: '/business/employees/new',

  //DRIVERS
  DRIVERS: '/preference_drivers',
  //API DASHBOARD
  API_DASHBOARD: '/business/api_dashboards',
  TEST_MY_BOOKING: '/business/api_dashboards/test_my_bookings',
  //WALLET
  WALLET_BUSINESS:'/business/credit_balances',
  WALLET:'/credit_balances',
  WALLET_TOPUP: '/credit_balances/topup',
  WALLET_BUSINESS_TOPUP: '/business/credit_balances/topup',


  //TRACKING
  TRACKING: '/bookings/:id/tracking',
  SHARE: '/bookings/:id/share',
  FOLLOW: '/bookings/:id/follow',

  // NONE BP PAYMENT
  PAYMENTS: '/bookings/:id/payment',
} as const
