import firebaseInit from 'utils/firebaseInit'

import {
  getBookingDetailAction, getBookingInfo, getDevinaInfo, updateBookingAction, getCustomerDriver, resetCustomerDriverAction,
  updateLocatingDuration,
  updateCSfindingTimeOutAt,
  updateDevinaStage,
  checkCancelable,
  updateBookingTrackingPage,
  getBookingPaymentDetailAction,
  getBookingPaymentDetailStatusAction,
} from '../common/bookingActionCreators'
import {
  FIREBASE_SHOPPING_ORDER_UPDATE,
  STATUS_LOCATING_DRIVER_TIMEOUT,
  IS_CEB_VA_PAYMENT,
  EXPIRED_EDIT_VA_ID
} from 'constants/bookingConstants'
import { SECTION_TYPE } from 'constants/trackingBookingConstants'
import { updateCurrentPopupID } from 'store/actions/new_booking/currentPopupIDActionCreators'
import { POPUP_CUSTOMER_CHANGES } from 'constants/common/popupConstants'

import { getParamFromURL } from 'utils/booking/common'

import BookingAPI from 'api/bookings'
import { isEmpty } from 'lodash-es'

const checkIsOwnerBooking = (bookingInfos, getState) => bookingInfos.customer_id === getState().currentCustomer.id
  && bookingInfos.id === getState().booking.id

export const startTracking = () => (dispatch, getState) => {
  const bookingPath = `customers/${getState().currentCustomer.id}/available_bookings`
  const csFindingPath = `customers/${getState().currentCustomer.id}/cs_finding_driver_bookings`
  const firebaseBooking = firebaseInit.listen(bookingPath)
  const firebaseCSFinding = firebaseInit.listen(csFindingPath)

  firebaseBooking.on('child_added', (snapshot) => {
    const bookingInfos = snapshot.val()
    if (checkIsOwnerBooking(bookingInfos, getState)) {
      dispatch(updateBookingTrackingPage())
    }
  })

  firebaseBooking.on('child_removed', (snapshot) => {
    const bookingInfos = snapshot.val()
    // driver has accepted the booking
    if (checkIsOwnerBooking(bookingInfos, getState)) {
      dispatch(getBookingDetailAction())
    }
  })

  firebaseCSFinding.on('child_added', (snapshot) => {
    const bookingInfos = snapshot.val()
    if (checkIsOwnerBooking(bookingInfos, getState)) {
      dispatch(getBookingDetailAction())
    }
  })

  firebaseCSFinding.on('child_removed', (snapshot) => {
    const bookingInfos = snapshot.val()
    if (checkIsOwnerBooking(bookingInfos, getState)) {
      dispatch(getBookingDetailAction())
    }
  })
}

export const driverPositionTracking = (booking) => {
  return new Promise((resolve) => {
    const firebaseDriver = firebaseInit.listen(`drivers/${booking.driver_id}`)
    firebaseDriver.once('value').then((snapshot) => {
      resolve(snapshot.val())
    })
  })
}

// export const driverTracking = () => (dispatch, getState) => {
//   const currentBooking = getState().booking
//   return new Promise((resolve) => {
//     const firebaseDriver = firebaseInit.listen(`vehicle_types/${currentBooking.vehicle_type_id}/offline_drivers/${currentBooking.driver_id}`)
//     firebaseDriver.once('value').then((snapshot) => {
//       resolve(dispatch(bookingActionsCreator.bookingUpdateDriverState({
//         value: !snapshot.exists()
//       })))
//     })
//   })
// }

export const startTrackingShoppingOrders = firebaseToken => (dispatch, getState) => {
  const currentBooking = getState().booking

  if (!firebaseToken) {
    console.warn('Missing firebase_token')
  }

  Promise.resolve(
    firebaseInit.load()
  ).then(() => {
    firebaseInit.authenticate(firebaseToken)
  })

  const shoppingOrderRef = firebaseInit.listen(`shopping_orders/${currentBooking.id}`)
  shoppingOrderRef.on('value', (snapshot) => {
    dispatch({
      type: FIREBASE_SHOPPING_ORDER_UPDATE,
      value: snapshot.val()
    })
  })

  return shoppingOrderRef
}

export const stopTrackingShoppingOrders = (shoppingOrderRef) => {
  // TODO: we need to check this ref is valid
  try {
    // After stoped tracking should we clear the current state, we keep it at the moment
    shoppingOrderRef.off()
  } catch (error) {
    console.warn('stopTrackingShoppingOrders failed')
  }
}

export const updateShoppingOrderCustomerItem = (shoppingOrderRef, { newValue, id }) => () => {
  // After stoped tracking should we clear the current state, we keep it at the moment
  const childRef = shoppingOrderRef.child(`customer_order/items/${id}`)

  // we don't dispatch any action here because we already listen on `value` event of firebase, it will trigger there
  childRef
    .update(newValue)
    .catch((err) => {
      console.warn('removeShoppingOrderCustomerItem failed', err)
    })
}

export const removeShoppingOrderCustomerItem = (shoppingOrderRef, { id }) => (dispatch, getState) => {
  const firebaseShoppingOrder = getState().shoppingOrder
  if (!firebaseShoppingOrder) {
    return
  }

  // we don't dispatch any action here because we already listen on `value` event of firebase, it will trigger there
  shoppingOrderRef
    .child(`customer_order/items/${id}`)
    .remove()
    .catch((err) => {
      console.warn('removeShoppingOrderCustomerItem failed', err)
    })
}

export const addShoppingOrderCustomerItem = (shoppingOrderRef, { newValue }) => (dispatch, getState) => {
  const firebaseShoppingOrder = getState().shoppingOrder
  if (!firebaseShoppingOrder) {
    return
  }

  // we don't dispatch any action here because we already listen on `value` event of firebase, it will trigger there
  shoppingOrderRef
    .child('customer_order/items')
    .update({
      [newValue.id]: newValue
    })
    .catch((err) => {
      console.warn('removeShoppingOrderCustomerItem failed', err)
    })
}

const updateDevinaTimeChanges = (bookingId, prev, next) => (dispatch) => {
  if (prev === next) {
    return
  }
  dispatch(updateCSfindingTimeOutAt(next))
  dispatch(getDevinaInfo({
    section_type: SECTION_TYPE.CUSTOMER_DEVINA_STAGE_INFO,
    bookingId
  }))
}
const updateLocatingTimeChanges = (prev, next) => (dispatch) => {
  if (prev === next) {
    return
  }
  // should not redraw the locating animation when the time changes are less than 5s ...
  // ... due to a callback issue on the backend side.
  const FIVE_SECONDS = 5
  const diff = next - prev
  if (diff >= 0 && diff <= FIVE_SECONDS) {
    return
  }
  dispatch(updateLocatingDuration({
    locatingDuration: next * 1000 - Date.now(),
    customerTimeoutAt: next
  }))
}

const checkEnableRecovery = bookingId => (dispatch,) => {
  dispatch(checkCancelable(bookingId, (response) => {
    if (!isEmpty(response)) {
      const {
        allowed_recovery: allowedRecovery,
      } = response
      dispatch(updateBookingAction({ allowedRecovery }))
    }
  }))
}


export const listenBookingList = bookingId => (dispatch, getState) => {
  const { currentCustomer } = getState()
  const bookingsPath = `customers/${currentCustomer.id}/bookings`
  const ref = firebaseInit.listen(bookingsPath)
  ref.on('child_removed', (snapshot) => {
    if (checkIsOwnerBooking(snapshot.val(), getState)) {
      dispatch(getBookingInfo({ bookingId }))
      dispatch(checkEnableRecovery(bookingId))
      dispatch(getBookingPaymentDetailAction(bookingId))
      dispatch(getBookingPaymentDetailStatusAction(bookingId))
    }
  })
}

const getDetailsAfterUpdate = (bookingId, dispatch) => {
  dispatch(getBookingInfo({ bookingId }))
  dispatch(getBookingPaymentDetailAction(bookingId))
  dispatch(getBookingPaymentDetailStatusAction(bookingId))
}

export const listenBooking = bookingId => (dispatch, getState) => {
  const { currentCustomer } = getState()
  const booking = getState().booking
  const bookingPath = `customers/${currentCustomer.id}/bookings/${bookingId}`
  const firebaseBooking = firebaseInit.listen(bookingPath)
  firebaseBooking.on('child_added', (snapshot) => {
    const { customerDriver, devina } = getState()
    switch (snapshot.key) {
      case 'status': {
        const status = snapshot.val()
        const bookingStatus = booking.status
        if (bookingStatus !== status) {
          dispatch(getBookingPaymentDetailAction(bookingId))
          dispatch(getBookingPaymentDetailStatusAction(bookingId))
          dispatch(getBookingInfo({ bookingId }))
        } else dispatch(updateBookingAction({ status }))
        break
      }
      case 'cs_finding_driver_timeout_at': {
        const prev = devina.csFindingDriverTimeoutAt
        const next = snapshot.val()
        dispatch(updateDevinaTimeChanges(bookingId, prev, next))
        break
      }
      case 'driver_id':
      case 'fleet_partner_id':
        if (!customerDriver.loading) {
          dispatch(getCustomerDriver({
            section_type: SECTION_TYPE.CUSTOMER_DRIVER,
            bookingId
          }))
        }
        break
      case 'customer_timeout_at': {
        const prev = devina.customerTimeoutAt
        const next = snapshot.val()
        dispatch(updateLocatingTimeChanges(prev, next))
        getDetailsAfterUpdate(bookingId, dispatch)
        break
      }
      case 'driver_accept_changed_request': {
        const showPopup = snapshot.val()
        const isCebVAPayment = getParamFromURL(IS_CEB_VA_PAYMENT) === 'true'
        const expiredEditBookingId = window.localStorage.getItem(EXPIRED_EDIT_VA_ID)
        dispatch(updateBookingAction({
          driver_accept_changed_request: showPopup
        }))
        const isMajor = localStorage.getItem('is_major_changes')
        if (showPopup && isMajor && !isCebVAPayment && !expiredEditBookingId) {
          dispatch(updateCurrentPopupID(POPUP_CUSTOMER_CHANGES))
        }
        localStorage.removeItem('is_major_changes')
        break
      }
      case 'canceled_by_driver':
        dispatch(updateBookingAction({
          canceled_by_driver: snapshot.val()
        }))
        break
      default:
        break
    }
  })
  firebaseBooking.on('child_changed', (snapshot) => {
    const { devina } = getState()
    switch (snapshot.key) {
      case 'status': {
        const status = snapshot.val()
        const bookingStatus = booking.status
        const showLoading = status === STATUS_LOCATING_DRIVER_TIMEOUT
        if (bookingStatus !== status) {
          dispatch(getBookingPaymentDetailAction(bookingId))
          dispatch(getBookingPaymentDetailStatusAction(bookingId))
        }
        dispatch(updateDevinaStage({}))
        dispatch(getBookingInfo({ bookingId, loading: showLoading }))
        dispatch(updateBookingAction({ status }))
        if (showLoading) {
          dispatch(checkEnableRecovery(bookingId))
        }
        break
      }
      case 'cs_finding_driver_timeout_at': {
        const prev = devina.csFindingDriverTimeoutAt
        const next = snapshot.val()
        dispatch(updateDevinaTimeChanges(bookingId, prev, next))
        break
      }
      case 'driver_id':
      case 'fleet_partner_id':
        dispatch(getCustomerDriver({
          section_type: SECTION_TYPE.CUSTOMER_DRIVER,
          bookingId
        }))
        break
      case 'customer_timeout_at': {
        const prev = devina.customerTimeoutAt
        const next = snapshot.val()
        dispatch(updateLocatingTimeChanges(prev, next))
        getDetailsAfterUpdate(bookingId, dispatch)
        break
      }
      case 'canceled_by_driver':
        dispatch(updateBookingAction({
          canceled_by_driver: snapshot.val()
        }))
        break
      default:
        break
    }
  })
  firebaseBooking.on('child_removed', (snapshot) => {
    switch (snapshot.key) {
      case 'driver_id':
      case 'fleet_partner_id':
        dispatch(resetCustomerDriverAction())
        break
      case 'canceled_by_driver':
        dispatch(updateBookingAction({
          canceled_by_driver: false
        }))
        break
      default:
        break
    }
  })
}