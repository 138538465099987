import { Suspense, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { BrowserRouter } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { v4 as uuidv4 } from 'uuid'
import RouteList from 'routers'
import firebaseInit from 'utils/firebaseInit'
import CustomerAPI from 'api/customers'
import areaApi from 'api/Area'
import * as utilsCommon from 'utils/booking/common'
import * as initApp from 'utils/initApp'
import StorageKeys from 'constants/storage-keys'
import { checkToShowLoadingForNewBooking } from './utils/new_booking/common'
import { Utils } from 'utils/Utils'
import {
  handleListenMessage,
  handleInitToken,
  setAccessToken,
  handleConnectCrossStorage,
  parseJwt,
} from './utils/crossStorage'
import * as areaActionCreators from 'store/actions/common/areaActionCreators'
import * as customerActionCreators from 'store/actions/common/customerActionCreators'

import NavBarContainer from './containers/NavBarContainer'
import FreshChat from 'utils/FreshChat'
import { initBasicModal } from 'assets/javascripts/webapp-v2/common/basic-modal'
import LoadingModal from 'components/common/loading_modal/LoadingModal'
import AuthenticationAPI from 'api/authentication'
import CommonUtils from 'utils/common'

import { CLIENT_ID, CLIENT_SECRET, LCL_CUSTOMER_URL } from 'constants/appConstants'
import { ThemeProvider } from 'styled-components'
import { SGlobal } from 'SGlobal'
import { theme } from 'components/common/Styled/STheme'
import { COMPANY_APPROVED_MODAL, SIGN_IN_MODAL } from 'components/new_booking/guest_flow/constants'
import { accountManageActions } from 'store/toolkit/accountManage/accountManage.reducer'
import accountAction from 'store/actions/common/accountAction'
import moment from 'moment'

const App = () => {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const currentCustomer = useSelector((state) => state.currentCustomer)
  const refreshToken = window.localStorage.getItem(StorageKeys.REFRESH_TOKEN) || ''
  const clientAccessToken = window.localStorage.getItem(StorageKeys.CLIENT_ACCESS_TOKEN) || ''
  const urlParams = new URLSearchParams(window.location.search)
  const isLoggedOut = urlParams.get('log_out_success')
  const authenticationToken = isLoggedOut ? '' : window.localStorage.getItem(StorageKeys.ACCESS_TOKEN) || ''
  const lastLoginEmployId = currentCustomer.last_login_employ_id || null

  const authenticationCode = utilsCommon.getParamFromURL('code')
  const areaIdUrl = utilsCommon.getParamFromURL('area_id')
  const countryCode = utilsCommon.getParamFromURL('country_code')
  if (areaIdUrl) window.localStorage.setItem(StorageKeys.AREA_ID, areaIdUrl)
  const areaIdLocal = window.localStorage.getItem(StorageKeys.AREA_ID)
  const extraInfos = useSelector((state) => state.extraInfos)
  const finalAreaId = Number(extraInfos?.id || areaIdUrl || areaIdLocal)

  const isShowHeaderAndChat = initApp.isShowHeaderAndChat()

  const isReady = (extraInfos?.id && (!authenticationToken || currentCustomer.id)) || !isShowHeaderAndChat

  useEffect(() => {
    if (finalAreaId && authenticationToken) {
      dispatch(customerActionCreators.getCurrentCustomer(authenticationToken, finalAreaId))
    }
    if (!clientAccessToken && !authenticationToken && !authenticationCode && finalAreaId) {
      window.location = CommonUtils.handleAuthenticationToAuthService()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [authenticationToken, finalAreaId])

  useEffect(() => {
    const getTokenByCode = async (authenticationCode) => {
      const params = new URLSearchParams({
        client_id: CLIENT_ID,
        client_secret: CLIENT_SECRET,
        grant_type: 'authorization_code',
        code: authenticationCode,
        code_verifier: window.localStorage.getItem(StorageKeys.VERIFY_CODE),
      })
      const { data: result } = await AuthenticationAPI.getToken(params)
      if (!result.error) {
        const timeToRequestToken = (result.expires_in / 3) * 2
        const timeExpired = moment().add(timeToRequestToken, 'seconds').toISOString()
        window.localStorage.setItem(StorageKeys.EXPIRED_TIME, timeExpired)
        window.localStorage.setItem(StorageKeys.CLIENT_ACCESS_TOKEN, result.access_token)
        window.localStorage.setItem(StorageKeys.CLIENT_REFRESH_TOKEN, result.refresh_token)
        window.localStorage.setItem(StorageKeys.X_CLIENT_SESSION, uuidv4())
        window.localStorage.removeItem(StorageKeys.VERIFY_CODE)
      }
      const url = new URL(document.URL)
      const urlParams = new URLSearchParams(url.search)
      urlParams.delete('code')
      urlParams.delete('iss')
      urlParams.delete('grant_type')
      url.search = urlParams.toString()
      if (!url.search) url.search = ''
      window.history.replaceState(null, null, url.href)
    }

    if (authenticationCode) {
      return getTokenByCode(authenticationCode)
    }
  
  }, [])

  useEffect(() => {
    // check to sync language to session
    initApp.syncLanguageToSession()

    // Check params to redirect to LCL
    initApp.checkParamLCL()

    initBasicModal()

    const getUserTokenByAccessToken = async () => {
      const params = {
        client_id: CLIENT_ID,
        client_secret: CLIENT_SECRET,
        token: authenticationToken,
      }
      const { data: result } = await AuthenticationAPI.getUserTokenByAccessToken(params)
      if (!result.error) {
        setAccessToken(result.access_token, result.refresh_token)
        window.localStorage.removeItem(StorageKeys.VERIFY_CODE)
      }
    }

    const urlParams = new URLSearchParams(window.location.search)
    const isLoggedOut = urlParams.get('log_out_success')
    if (isLoggedOut) {
      Utils.showToastrMessage('info', t('devise.sessions.signed_out'))
      setAccessToken('')
      window.history.replaceState({}, document.title, '/')
    }
    if (authenticationToken) {
      // sync jwt token to FTL token
      handleInitToken()
      if (authenticationToken && !refreshToken) {
        return getUserTokenByAccessToken()
      }
    } else {
      handleConnectCrossStorage()
    }

    // todo: we need to rehandle when use router dom
    // only for new booking
    checkToShowLoadingForNewBooking()

    window.addEventListener('message', handleListenMessage)
    return () => {
      window.removeEventListener('message', handleListenMessage)
    }
  }, [currentCustomer?.id])

  useEffect(() => {
    const checkEventApprovedCompany = async() => {
      const { data: checkEvent } = await CustomerAPI.checkEventApprovedCompany()
      if (checkEvent?.show_notification) {
        await accountAction.switchAccount(checkEvent?.value?.[0])(dispatch)
        dispatch(accountManageActions.updateModalAccountManage(COMPANY_APPROVED_MODAL))
        await CustomerAPI.cleanEventApprovedCompany()
      }
    }
    if (currentCustomer?.id) {
      checkEventApprovedCompany()
      firebaseInit.load()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentCustomer?.id])

  useEffect(() => {
    if (finalAreaId && authenticationToken) {
      dispatch(customerActionCreators.getCurrentCustomer(authenticationToken, finalAreaId, lastLoginEmployId))
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [authenticationToken, finalAreaId, lastLoginEmployId])

  useEffect(() => {
    const getCustomerSetting = () => {
      dispatch(
        customerActionCreators.getCustomerSettings(authenticationToken, finalAreaId, () => {}, extraInfos.country_code)
      )
    }
    if (!finalAreaId) return
    if (extraInfos?.country_code && !authenticationToken) getCustomerSetting()
    if (extraInfos?.country_code && authenticationToken && finalAreaId) {
      getCustomerSetting()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [finalAreaId, extraInfos.country_code, currentCustomer.id, !!lastLoginEmployId])

  useEffect(() => {
    if (!finalAreaId) {
      dispatch(areaActionCreators.getCurrentAreaCustomer(countryCode))
    }
    if (authenticationToken && !currentCustomer.id) return
    if (finalAreaId) {
      dispatch(areaActionCreators.getAreaInfo(finalAreaId))
      // sync area_id for session FTL
      areaApi.setNewCurrentAreaSession(finalAreaId)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [finalAreaId, currentCustomer.last_login_employ_id, currentCustomer.id])

  useEffect(() => {
    const lclBookingId = utilsCommon.getParamFromURL('spil_number')
    if (!lclBookingId) return
    const getLclInfo = async (lclBookingId) => {
      const {
        data: { data },
      } = await CustomerAPI.getInfoLclBooking(lclBookingId)
      window.location = `${LCL_CUSTOMER_URL}/tracking/${data.id}`
    }

    const decodeToken = parseJwt(authenticationToken)
    const companyIdFromToken = +decodeToken?.company_id || 0
    const companyIdNow = +currentCustomer.last_login_employ_id || 0

    if (!currentCustomer.id) {
      dispatch(accountManageActions.updateModalAccountManage(SIGN_IN_MODAL))
    } else if (currentCustomer.id && companyIdFromToken === companyIdNow) {
      getLclInfo(lclBookingId)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentCustomer.id, currentCustomer.last_login_employ_id, currentCustomer.authentication_token])

  //todo: init showloading, we need hide loading for each pages
  return (
    <ThemeProvider theme={theme}>
      <div className="app">
        <Suspense fallback={<LoadingModal />}>
          <BrowserRouter>
            <SGlobal />
            {isReady && (
              <>
                {isShowHeaderAndChat && (
                  <>
                    <FreshChat />
                    <NavBarContainer />
                  </>
                )}
                <RouteList />
              </>
            )}
            <LoadingModal />
          </BrowserRouter>
        </Suspense>
      </div>
    </ThemeProvider>
  )
}

export default App
