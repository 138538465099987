import StorageKeys from 'constants/storage-keys';
import areaApi from 'api/Area'
import common from './common'
import paths from 'routers/paths';
import { LCL_CUSTOMER_URL } from 'constants/appConstants';
import { isNewBooking } from './booking/common';

export const syncLanguageToSession = async () => {
  const urlParams = new URLSearchParams(window.location.search)
  const langFromUrl = urlParams.get('lang') && urlParams.get('lang').toLowerCase()
  const lang = langFromUrl && langFromUrl.toLowerCase()
  if(lang) {
    setTimeout(() => {
      areaApi.setNewCurrentLangCodeSession(lang)
    }, 1000);
    
  } else {
    const result = await areaApi.getCurrentLangCodeSession()
    const newLang = result.data?.lang
    const localLang = localStorage.getItem(StorageKeys.LANGUAGE)

    if(newLang && localLang !== newLang) {
      const urlParams = new URLSearchParams(window.location.search);
      urlParams.set('lang', newLang);
      window.location.search = urlParams;
    }    
  }
}

export const isShowHeaderAndChat = () => {
  const isSharePage = common.isShare()
  const isFollowPage = common.isFollow()
  const isSelectAreaPage = window.location.pathname === paths.SELECT_AREA
  return !isSharePage && !isFollowPage && !isSelectAreaPage
}

export const checkParamLCL = () => {
  const urlParams = new URLSearchParams(window.location.search);
  const isLcl = urlParams.get('lcl');

  if(isLcl && isNewBooking) {
    window.location.replace(`${LCL_CUSTOMER_URL}`);
  }
};

export default syncLanguageToSession