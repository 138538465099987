import React from 'react'
import { useTranslation } from 'react-i18next'
import SButton from 'components/common/Styled/SButton'
import ModalNormal from 'components/common/Modal/ModalNormal'
import { theme } from 'components/common/Styled/STheme'
import SFlex from 'components/common/Styled/SLayout/SFlex'
import STypography from 'components/common/Styled/STypography'
import { useAppDispatch, useAppSelector } from 'store/store'
import { VERIFICATION_CODE_MODAL } from 'components/new_booking/guest_flow/constants'
import { ContentWrapper, PhoneWrapper } from './SEditPhoneNumber'
import InputPhoneNumber from 'components/common/InputPhoneNumber'
import { IParamSignUp } from 'store/actions/common/accountAction'
import CustomerAPI from 'api/customers'
import toastr from 'utils/toast'
import { accountManageActions } from 'store/toolkit/accountManage/accountManage.reducer'

const EditPhoneNumber: React.FC<{
  dataSignUp?: IParamSignUp
  setDataSignUp: React.Dispatch<React.SetStateAction<IParamSignUp>>
  isNoStep3?: boolean
}> = ({ dataSignUp, setDataSignUp, isNoStep3 }) => {
  const { t } = useTranslation()
  const extraInfos = useAppSelector((state) => state.extraInfos)
  const dispatch = useAppDispatch()
  const handleClick = async () => {
    const params = {
      phone: dataSignUp?.phone || '',
      country_code: extraInfos.country_code,
    }
    const { data } = await CustomerAPI.updateCustomer(params)
    if (data?.error) toastr.error((data?.error && data?.error) || t('errors.customer.invalid_phone_number'))
    else dispatch(accountManageActions.updateModalAccountManage(VERIFICATION_CODE_MODAL))
  }

  const handleCustomerPhoneChange = (phone: string) => {
    dataSignUp && setDataSignUp({ ...dataSignUp, phone })
  }
  return (
    <ModalNormal
      topLevel={!isNoStep3}
      closeVariant="g1"
      customFixedWidth={'632px'}
      backgroundColor={theme.colors.white[1]}
      isOpen
    >
      <ContentWrapper>
        <STypography fw={500} mb={20} color={theme.colors.green[1]} fs={17}>
          {t('webapp.label.edit_number')}
        </STypography>
        <PhoneWrapper>
          <InputPhoneNumber
            isWebApp
            closestElement="verification-number"
            telInputProps={{ tabIndex: 1 }}
            phoneNumber={dataSignUp ? dataSignUp.phone : ''}
            countryCode={extraInfos.country_code}
            phoneField="customer[phone]"
            countryCodeField="customer[country_code]"
            onPhoneChange={handleCustomerPhoneChange}
            onPhoneBlur={handleCustomerPhoneChange}
            onPhoneFocus={handleCustomerPhoneChange}
          />
        </PhoneWrapper>

        <SFlex full mt={20}>
          <SButton
            w="100%"
            h="40px"
            mr={20}
            ml={20}
            onClick={() => handleClick()}
            variant="g2"
            br={`1px solid ${theme.colors.green[1]}`}
            fs={17}
            fw={500}
            type="button"
          >
            {t('webapp.action.confirm')}
          </SButton>
        </SFlex>
      </ContentWrapper>
    </ModalNormal>
  )
}

export default EditPhoneNumber
