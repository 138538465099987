import { DELIVEREE_PLACES_SERVICE_URL, NEW_GATEWAY } from 'constants/appConstants'
import { filter, isUndefined } from 'lodash'
import apiClient from 'services/axiosApp'
import mapUtils from 'utils/common/map'
import * as utilsCommon from 'utils/booking/common'
import { X_USE_CASE } from 'constants/CommonConstant'

const LocationAPI = {
  validLocations: async (
    locations,
    {
      timeType, serviceTypeID, vehicleTypeID, isSimpleData = false,
    },
    {
      countryCode,
      companyId,
      authenticationToken,
      isLogin = false,
      isEnableGoogleMap,
    },
    deviceType,
    callback,
    fullLoadView = true
  ) => {
    // Declare variable
    let requestString = ''
    let lat
    let lng
    const timeTypes = {
      now: 1,
      schedule: 2,
      full_day: 3,
      long_haul: 4,
    }

    // Handle logic
    const filterLocations = filter(locations, (location) => {
      let verify = false
      const list = [location.latitude, location.longitude]
      if (!list.includes(undefined)) {
        verify = true
      }

      const locationTmp = [location.lat, location.lng]
      if (!locationTmp.includes(undefined)) {
        return true
      }

      return verify
    })

    filterLocations.forEach((location, index) => {
      lat = isUndefined(location.marker) ? (location.lat || location.latitude) : mapUtils.getLatLngFromMarker(location.marker, isEnableGoogleMap).lat
      lng = isUndefined(location.marker) ? (location.lng || location.longitude) : mapUtils.getLatLngFromMarker(location.marker, isEnableGoogleMap).lng
      requestString += `locations[][lat]=${lat}&locations[][lng]=${lng}`
      if (index !== locations.size - 1) {
        requestString += '&'
      }
    })

    if (countryCode) {
      requestString += `country_code=${countryCode.toLowerCase()}`
    }

    if (fullLoadView) {
      if (timeType) {
        requestString += `&booking_type=${timeTypes[timeType]}`
        requestString += `&check_cod_pod[time_type]=${timeType}`
      }

      if (serviceTypeID) {
        requestString += `&service_type_id=${serviceTypeID}`
      }


      if (vehicleTypeID) {
        requestString += `&vehicle_type_id=${vehicleTypeID}`
      }

      if (isLogin && companyId && timeType) {
        requestString += `&check_cod_pod[company_id]=${companyId}`
      }

      if (isSimpleData) {
        requestString += '&simple_data=true'
      }
    }

    const urlApi = fullLoadView ? '/api/v3/locations/check_locations?' : '/cargo_api/v1/locations/check_locations?'
    try {
      const res = await apiClient.get(`${urlApi}${decodeURIComponent(requestString)}`, {
        headers: {
          'Device-Type': deviceType,
        }
      })
      return callback(res.data)
    } catch (err) {
      throw new Error(err)
    }
  },
  validLocationsInArea: async (locations, areaId, callback, authenticationToken, companyId, isEnableGoogleMap) => {
    let requestString = ''
    let lat
    let lng
    const validLocations = filter(locations, location => (location.lat || location.lng))

    if (!validLocations.length) {
      // TODO: we should warning here
      return callback({})
    }

    validLocations.forEach((location, index) => {
      if (isUndefined(location.marker)) {
        lat = location.lat
        lng = location.lng
      } else {
        lat = mapUtils.getLatLngFromMarker(location.marker, isEnableGoogleMap).lat
        lng = mapUtils.getLatLngFromMarker(location.marker, isEnableGoogleMap).lng
      }
      requestString += `locations[][lat]=${lat}&locations[][lng]=${lng}`
      if (index !== locations.size - 1) {
        requestString += '&'
      }
    })

    requestString += `&area_id=${areaId}&company_id=${companyId}`
    try {
      const res = await apiClient.get(`/api/v3/locations/check_correct_area?${decodeURIComponent(requestString)}`)
      return callback(res.data)
    } catch (err) {
      throw new Error(err)
    }
  },
  calculateCODPODFees: async (params, AuthenticationToken, callback) => {
    try {
      const res = await apiClient.post('/api/v3/locations/calculate_cod_pod', params)
      return callback(res.data)
    } catch (err) {
      throw new Error(err)
    }
  },
  getGeocode: (query, xUseCase = X_USE_CASE.NEW_BOOKING, isPublicAPI) => {
    const isVn = utilsCommon.getParamFromURL('lang') === 'vi'
    return apiClient.get(`${isPublicAPI ? NEW_GATEWAY : DELIVEREE_PLACES_SERVICE_URL}/api/v2/geocode${query.latlng ? '/reverse' : ''}`, {
      params: query,
      headers: {
        'Device-Type': 'Web',
        'x-use-case': xUseCase,
        ...(isVn && { 'Accept-Language': 'vi' }),
      },
    })
  },
  confirmLocation: async (accessToken, bookingID, dataSend, callback) => {
    try {
      const res = await apiClient.put(`/api/v3/bookings/${bookingID}/locations/confirm`, dataSend, {
        headers: {
          ...(accessToken && { Authorization: accessToken })
        }
      })
      return callback(res)
    } catch (err) {
      throw new Error(err)
    }
  },
  getPredictionLocations: async (query, xUseCase = X_USE_CASE.NEW_BOOKING) => {
    try {
      const isVn = utilsCommon.getParamFromURL('lang') === 'vi'
      const headers = {}
      if (isVn) {
        headers['Accept-Language'] = 'vi'
      }
      const res = await apiClient.get(`${DELIVEREE_PLACES_SERVICE_URL}/api/v2/place/autocomplete`, {
        params: query,
        headers: {
          ...headers,
          'x-use-case': xUseCase
        }
      })
      return res
    } catch (err) {
      throw new Error(err)
    }
  },
  getPlaceDetailApi: (query, source) => {
    const isVn = utilsCommon.getParamFromURL('lang') === 'vi'
    return apiClient.get(`${DELIVEREE_PLACES_SERVICE_URL}/api/v2/place/details`, {
      params: query,
      headers: {
        Authorization: '',
        "x-source": source,
        ...( isVn && { "Accept-Language": 'vi' })
      }
    })
  },
  getRoutesDirection: params => apiClient.post(`${DELIVEREE_PLACES_SERVICE_URL}/api/v2/routes/direction`, params, {
    headers: {
      Authorization: '',
    }
  }),
  //we can set steps = true to get encodedPolyline (for case draw route)
  getRoutesOptimize: params => apiClient.post(`${DELIVEREE_PLACES_SERVICE_URL}/api/v2/routes/optimize`, params, {
    headers: {
      Authorization: '',
    }
  })
}

export default LocationAPI
