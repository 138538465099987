import React, { useEffect, useRef, useState } from 'react'
import PropTypes from 'prop-types'
import './DeletionAccount.scss'
import I18n from 'i18n/i18n'

import {
  ARROW_BACK_WHITE
} from 'constants/imageConstants'
import _ from 'lodash'
import { useTranslation } from 'react-i18next'
import PopupWarning from './PopupWarning'
import CustomerAPI from 'api/customers'
import { hideLoading, showLoading } from 'assets/javascripts/webapp-v2/common'
import { FT_DEL_ACC } from 'constants/userProfile'

import Step1Reason from './Step1Reason'
import Step2Policy from './Step2Policy'
import Step3EnterPassword from './Step3EnterPassword'
import Step4AccountDeleted from './Step4AccountDeleted'
import { removeListParamOnURL } from 'utils/booking/common'
import CommonUtils from 'utils/common'
import { getDeviceId } from 'utils/cookie'

const DeletionAccount = ({
  currentCustomer, onClosePopup
}) => {
  const { t } = useTranslation()

  const [step, setStep] = useState(1)
  const [deletionAccount, setDeletionAccount] = useState({
    acceptDelete: false,
    reasons: [],
    deleteError: null,
    isCheckedOther: false
  })

  const [popup, setPopup] = useState(null)

  const timeoutRedirect = useRef(null)
  const timeoutRemoveNotify = useRef(null)

  useEffect(() => {
    const handleGetDeleteReason = async () => {
      const result = await CustomerAPI.getDeleteReason()
      if(result.status === 200) {
        setDeletionAccount({ ...deletionAccount, reasons: result.data.data })
      }
    }
    handleGetDeleteReason()
  }, [])

  const handleCloseDeletionPopup = () => {
    removeListParamOnURL([FT_DEL_ACC])
    onClosePopup()
  }
  const onClickBack = () => {
    resetDeleteError()
    if (step === 1) {
      handleCloseDeletionPopup()
    } else if(step === 4) {
      clearTimeout(timeoutRedirect.current)
      window.location.href = '/'
    } else {
      if(step === 2) {
        setDeletionAccount({ ...deletionAccount, acceptDelete: false })
      }
      setStep(step - 1)
    }
  }

  const getParamsReason =  () => {
    const reasonsChecked = deletionAccount.reasons.filter(reason => reason.isChecked)
    let reasonText = reasonsChecked.reduce(
      (prevText, reason) => prevText + `${!!prevText ? ', ' : ''}${reason.name}`,
      '',
    );
    if(deletionAccount.other_reason && deletionAccount.isCheckedOther) {
      reasonText += `${!!reasonText ? ', ' : ''}${deletionAccount.other_reason}`
    }
    return {
      "Reason": reasonText
    }
  }

  const checkToContinueStep4 = async () => {
    showLoading()
    const { status } = await CustomerAPI.deleteAccount(
      getDeviceId(), {
      delete_reason_ids: deletionAccount.reasons.filter(reason => reason.isChecked).map(reason => reason.id),
      password : deletionAccount.password,
      ...(deletionAccount.other_reason && deletionAccount.isCheckedOther && { other_reason : deletionAccount.other_reason })
    })
    hideLoading()
    if(status === 204) {
      setStep(4)
      timeoutRedirect.current = setTimeout(() => {
        window.location.href = '/'
      }, 5000)
    } else if(status === 401) {
      setDeletionAccount({ ...deletionAccount, passwordError: t('webapp.deletion_account.incorrect_password') })
    } else {
      timeoutRemoveNotify.current = setTimeout(() => {
        resetDeleteError()
      }, 3000)
      setDeletionAccount({ ...deletionAccount, deleteError: t('webapp.deletion_account.something_wrong_your_account') })
      setStep(2)
    }
  }

  const checkToContinueStep3 = async () => {
    showLoading()
    const { data, status } = await CustomerAPI.getDeleteMessage()
    if(status === 200) {
      setStep(3)
    } else if(status === 400) {
      const arrContent = data.error?.split('\n')
      setPopup({
        title: arrContent?.[0],
        content: arrContent?.[1]
      })
    }
    hideLoading()
  }

  const resetDeleteError = () => {
    clearTimeout(timeoutRemoveNotify.current)
    setDeletionAccount({ ...deletionAccount, deleteError: null })
  }
  const onClickContinue = async () => {
    if(step === 1) {
      CommonUtils.moengageTrackEvent("Select Delete Reason", getParamsReason())
    }

    if(step === 2) {
      resetDeleteError()
      checkToContinueStep3()
    } else if(step === 3) {
      checkToContinueStep4()
    } else {
      setStep(step + 1)
    }
  }

  const onUpdateDeleteAccount = (key, value) => {
    if(key === 'password' && deletionAccount.passwordError) {
      setDeletionAccount({ ...deletionAccount, passwordError: null, [key]: value })
    } else {
      setDeletionAccount({ ...deletionAccount, [key]: value })
    }
  }

  const onUpdateReasons = (indexReason, value) => {
    const reasons = [...deletionAccount.reasons]
    reasons[indexReason].isChecked = value
    setDeletionAccount({
      ...deletionAccount,
      reasons: reasons
    })
  }

  if (_.isEmpty(currentCustomer)) return null

  const hasCheckedReason = deletionAccount.reasons.some(reason => reason.isChecked) || (deletionAccount.isCheckedOther && deletionAccount.other_reason)
  const isDisableContinue = (step === 1 && !hasCheckedReason) || (step === 2 && !deletionAccount.acceptDelete) || (step === 3 && !deletionAccount.password)

  return (
    <div className="deletion-account">
      <div className="deletion-account-box">
        <div className="header">
          <span onClick={onClickBack} className="cur-pointer"><img src={ARROW_BACK_WHITE} alt='arrow-back' /></span>
          <div>{t("webapp.deletion_account.delete_account")}</div>
        </div>
        {step === 2 && deletionAccount.deleteError && (
          <div className="notify-error">
            {deletionAccount.deleteError}
          </div>
        )}
        <div className={`content ${step === 4 ? 'step4-content' : ''}`}>
          {step === 1 && (
            <Step1Reason
              deletionAccount={deletionAccount}
              onUpdateReasons={onUpdateReasons}
              onUpdateDeleteAccount={onUpdateDeleteAccount}
            />
          )} 
          {step === 2 && (
            <Step2Policy
              deletionAccount={deletionAccount}
              onUpdateDeleteAccount={onUpdateDeleteAccount}
            />
          )}
          {step === 3 && (
            <Step3EnterPassword
              deletionAccount={deletionAccount}
              onUpdateDeleteAccount={onUpdateDeleteAccount}
            />
          )}
          {step === 4 && (
            <Step4AccountDeleted
              deletionAccount={deletionAccount}
              onUpdateDeleteAccount={onUpdateDeleteAccount}
            />
          )}
        </div>
        {step !== 4 && (
          <div className="footer">
            <button
              type="button"
              className="gray Button"
              onClick={onClickBack}
            >
              {I18n.t('webapp.action.back')}
            </button>
            <button
              type="button"
              className={`red Button ${isDisableContinue ? 'disabled' : ''}`}
              onClick={onClickContinue}
            >
              {I18n.t('webapp.booking.continue')}
            </button>
          </div>
        )}
        {popup && (
          <PopupWarning
            popup={popup}
            onClosePopup={() => setPopup(null)}
          />
        )}
      </div>
      <div
        className="RightMenu-close-pane"
        // onClick={onClosePopup}
      />
    </div>
  )
}

DeletionAccount.propTypes = {
  currentCustomer: PropTypes.shape({}).isRequired,
  onClosePopup: PropTypes.func.isRequired,
  isUpdatedUser: PropTypes.bool.isRequired,
  setIsUpdatedUser: PropTypes.func.isRequired,
}

export default DeletionAccount
