import styled from "styled-components"
import { css } from "styled-components"

interface ISCol {
  mb?: number
  col?: number
  brd?: number
  br?: number
  blr?: number
  brr?: number
  tlr?: number
  trr?: number
}
interface ISContainer {
  col?: number
  rowGap?: number
  colGap?: number
  bgColor?: string
  mb?: number
}
const SContainer = styled.div<ISContainer>`
  width: 100%;
  display: grid;
  grid-template-columns: ${({ col }) => (col ? `repeat(${col}, minmax(0, 1fr))` : 'repeat(1, minmax(0, 1fr))')};
  grid-row-gap: ${({ rowGap }) => (rowGap ? `${rowGap}px` : '0')};
  row-gap: ${({ rowGap }) => (rowGap ? `${rowGap}px` : '0')};
  grid-column-gap: ${({ colGap }) => (colGap ? `${colGap / 16}px` : '0')};
  column-gap: ${({ colGap }) => (colGap ? `${colGap}px` : '0')};
  margin-bottom: ${({ mb }) => (mb ? `${mb}px` : 0)};
  background-color: ${({ bgColor }) => (bgColor ? bgColor : 'transparent')};
`
const SCol = styled.div<ISCol>`
  grid-column: ${({ col }) => (col ? `span ${col} / span ${col}` : 'span 1 / span 1')};
  margin-bottom: ${({ mb }) => (mb ? `${mb}px` : 0)};
  border-bottom-left-radius: ${({ blr }) => (blr ? `${blr}px` : 0)};
  border-bottom-right-radius: ${({ brr }) => (brr ? `${brr}px` : 0)};
  border-top-left-radius: ${({ tlr }) => (tlr ? `${tlr}px` : 0)};
  border-top-right-radius: ${({ trr }) => (trr ? `${trr}px` : 0)};
  ${({ brd }) =>
    brd &&
    css`
      border-radius: ${brd}px;
    `};
  ${({ br }) =>
    br &&
    css`
      border: ${br};
    `};
`
export { SContainer, SCol }
