import CustomerAPI from 'api/customers'
import { PUBLIC_URL } from 'constants/appConstants'
import StorageKeys from 'constants/storage-keys'
import { CrossStorageClient } from 'cross-storage'
import jwt_decode from 'jwt-decode'

export const setAccessToken = async (accessToken: string, refreshToken = '') => {
  localStorage.setItem(StorageKeys.ACCESS_TOKEN, accessToken)
  localStorage.setItem(StorageKeys.REFRESH_TOKEN, refreshToken)
  try {
    const storage = new CrossStorageClient(`${PUBLIC_URL}/hub.html`, { timeout: 10000 })
    await storage.onConnect()
    await storage.set(StorageKeys.ACCESS_TOKEN, accessToken)
    await storage.set(StorageKeys.REFRESH_TOKEN, refreshToken)
  } catch (error) {
    console.debug(error)
  }
}

export const clearTokenClientFlow = () => {
  localStorage.removeItem(StorageKeys.CLIENT_ACCESS_TOKEN)
  localStorage.removeItem(StorageKeys.CLIENT_REFRESH_TOKEN)
  localStorage.removeItem(StorageKeys.EXPIRED_TIME)
}

export const parseJwt = (token: string) => {
  return token && jwt_decode(token)
}

// switch account from old webapp
const checkSessionToConvertToken = async () => {
  const result = await CustomerAPI.reConvertToken()
  if (result.status === 200 && result?.data?.access_token) {
    setAccessToken(result.data.access_token, '')
  }
}

export const handleConnectCrossStorage = async () => {
  const storage = new CrossStorageClient(`${PUBLIC_URL}/hub.html`, { timeout: 10000 })
  await storage.onConnect()
  const accessTokenFromHub = (await storage.get(StorageKeys.ACCESS_TOKEN)) || ''
  const refreshTokenFromHub = (await storage.get(StorageKeys.REFRESH_TOKEN)) || ''
  console.debug(accessTokenFromHub, refreshTokenFromHub, 37)

  if (accessTokenFromHub) {
    setAccessToken(accessTokenFromHub, refreshTokenFromHub)
    CustomerAPI.updateAuthenticationToken()
  } else {
    checkSessionToConvertToken()
  }
}

export const handleInitToken = async () => {
  // sync jwt token to FTL token
  try {
    let accessToken = window.localStorage.getItem(StorageKeys.ACCESS_TOKEN) || ''
    let refreshToken = window.localStorage.getItem(StorageKeys.REFRESH_TOKEN) || ''
    const storage = new CrossStorageClient(`${PUBLIC_URL}/hub.html`, { timeout: 10000 })
    await storage.onConnect()
    console.debug(accessToken, refreshToken, 64)
    setAccessToken(accessToken, refreshToken)
    CustomerAPI.updateAuthenticationToken()
  } catch (err) {
    console.debug(err)
  } finally {
    CustomerAPI.updateAuthenticationToken()
  }
}

export const handleListenMessage = (event: { origin: any; data: string }) => {
  const authenticationToken = window.localStorage.getItem(StorageKeys.ACCESS_TOKEN) || ''
  console.debug(event, 'event76')
  if (event.origin === PUBLIC_URL) {
    let accessToken: any = typeof event.data === 'string' && event.data?.split(':')
    if (accessToken && accessToken?.length && accessToken[0] === 'auth') {
      accessToken = accessToken[1] || ''
      if (accessToken === authenticationToken) return
      setAccessToken(accessToken)
      window.location.reload()
    }
  }
}
