import React, { FC, useEffect, useState } from 'react'
import { useAppDispatch, useAppSelector } from 'store/store'
import SignIn from './SignIn'
import {
  COMPANY_APPROVED_MODAL,
  CONFIRM_PASSWORD_MODAL,
  EDIT_PHONE_NUMBER_MODAL,
  GO_TO_STEP1_MODAL,
  MERGE_MODAL,
  RESET_PASSWORD_MODAL,
  REVOLUTION_MODAL,
  SIGN_IN_MODAL,
  SIGN_UP_MODAL,
  SWITCH_ACCOUNT_MODAL,
  VERIFICATION_CODE_MODAL,
} from 'components/new_booking/guest_flow/constants'
import { hideLoading, showLoading } from 'assets/javascripts/webapp-v2/common'
import CommonUtils from 'utils/common'
import CustomerAPI from 'api/customers'
import { setAccessToken } from 'utils/crossStorage'
import { calculate, updateSubAccountCheckBox } from 'store/actions/common/bookingActionCreators'
import { addSubAccount } from 'store/actions/common/bookingActionCreators'
import { signInProgressFinished } from 'store/actions/common/bookingActionCreators'
import accountAction, { IParamSignUp } from 'store/actions/common/accountAction'
import { isPaymentBooking } from 'utils/booking/common'
import { getCustomerCreditAmount } from 'store/actions/common/customerActionCreators'
import { matchPath, useLocation, useNavigate } from 'react-router-dom'
import paths from 'routers/paths'
import { IUserInfo } from 'interface/IAccountManage'
import SignUp from './SignUp'
import WelcomeRevolution from './WelcomeRevolution'
import VerificationCode from './VerificationCode'
import EditPhoneNumber from './EditPhoneNumber'
import toastr from 'utils/toast'
import ResetPassword from './ResetPassword'
import { AccountManagementWrapper } from './SIndex'
import ConfirmNewPassword from './ConfirmNewPassword'
import { useTranslation } from 'react-i18next'
import { Utils } from 'utils/Utils'
import { currentCustomerActionsCreator } from 'store/toolkit/currentCustomer/currentCustomer.reducer'
import MergeAccount from './MergeAccount'
import GoToStep1 from './GoToStep1'
import { accountManageActions } from 'store/toolkit/accountManage/accountManage.reducer'
import ChooseAccount from './ChooseAccount'
import store from 'store/store'
import { currentStepActionsCreator } from 'store/toolkit/currentStep/currentStep.reducer'
import CompanyApproved from './CompanyApproved'

export interface SocialInfo {
  _provider: string
  _profile: Profile
  _token: Token
}
interface Token {
  accessToken: string
  idToken: string
  scope: string
  expiresIn: number
  firstIssued_at: number
  expiresAt: number
}
interface Profile {
  id: string
  name: string
  firstName: string
  lastName: string
  email: string
  profilePicURL: string
}
export interface MergeInfo {
  countryCode?: string
  email: string
  phone: string
  firstName: string
  lastName: string
  businessName: string
  referredBy: string
  access_token: string
  provider: string
  socialToken: string
}

const AccountManagementView: FC<{ view: string }> = ({ view }) => {
  const { currentCustomer, currentStep, booking, extraInfos, accountModal } = useAppSelector((s) => s)
  const { pathname, search } = useLocation()
  const [isSignUpSocial, setIsSignUpSocial] = useState(false)
  const [dataSignUp, setDataSignUp] = useState<IParamSignUp>({
    country_code: '',
    email: '',
    phone: '',
    first_name: '',
    last_name: '',
    business_name: '',
    referred_by: '',
    password: '',
    access_token: '',
    provider: '',
    invitation_token: '',
    identity: '',
  })
  const [dataMergeSocial, setDataMergeSocial] = useState<MergeInfo>({
    countryCode: '',
    email: '',
    phone: '',
    firstName: '',
    lastName: '',
    businessName: '',
    referredBy: '',
    access_token: '',
    provider: '',
    socialToken: '',
  })

  const [prePassword, setPrePassword] = useState('')
  const dispatch = useAppDispatch()
  const navigate = useNavigate()
  const { t } = useTranslation()
  const isNoStep3 = !!(matchPath(pathname || '', paths.SIGN_IN) || matchPath(pathname || '', paths.SIGN_UP))
  const checkBackToStep1 = (isAfterSignedUp = false) => {
    if (matchPath(pathname || '', paths.SIGN_IN) || matchPath(pathname || '', paths.SIGN_UP)) return
    const companyId = currentCustomer.current_company_id || currentCustomer.last_login_employ_id
    const isBp = !!companyId
    const isBackToStep1 =
      (!isAfterSignedUp || isBp) && (currentStep === 2 || currentStep === 3) ? GO_TO_STEP1_MODAL : ''
    dispatch(accountManageActions.updateModalAccountManage(isBackToStep1))
    hideLoading()
  }

  const checkRedirectPage = () => {
    if (isNoStep3) {
      showLoading()
      navigate(matchPath(pathname || '', paths.SIGN_IN) ? `/${search}` : '/')
      hideLoading()
    }
  }

  const trackSwitchAccountEventMoengage = async (
    currentCustomer: IUserInfo,
    id?: number | null,
    newCurrentCustomer?: IUserInfo
  ) => {
    const { isMultiple, isBatchEZ, isSmartLoad } = CommonUtils
    if (!isMultiple() && !isBatchEZ() && !isSmartLoad()) {
      const { oldId, newId } = CommonUtils.getMoengageId(currentCustomer, id)
      try {
        await window.Moengage.track_event('Switch Account', {
          'From Account': oldId,
          'To Account': newId,
          'At Step': CommonUtils.getAtStepAttributeMoengage(currentStep),
        })
        await window.Moengage.destroy_session()
        await CommonUtils.handleTrackMoengage(newCurrentCustomer, currentStep)
      } catch (err) {
        console.error(err)
      }
    }
  }

  const reCalculate = async () => {
    if (CommonUtils.isSingle()) {
      if (!isPaymentBooking(booking?.payment_method_for_non_bp)) calculate()(dispatch, store.getState)
      await getCustomerCreditAmount()(dispatch, store.getState)
    }
  }

  const switchAccountHandler = async (companyId: number | null, currentCustomerId: number, isSwitchStep3?: boolean) => {
    showLoading()
    dispatch(updateSubAccountCheckBox(false))
    dispatch(addSubAccount())
    const accountId = !companyId ? currentCustomerId : (companyId as number)
    const newCurrentCustomer = await accountAction.switchAccount(accountId)(dispatch)
    const res = await CustomerAPI.updateAuthenticationToken()
    if (isSwitchStep3) await trackSwitchAccountEventMoengage(currentCustomer, companyId, newCurrentCustomer)
    else await CommonUtils.handleTrackMoengage(currentCustomer, currentStep)
    checkBackToStep1()
    // sync token to session for FTL
    if (res?.status === 200) {
      dispatch(signInProgressFinished(true))
    }
    await accountAction.getSubAccountTags(companyId)(dispatch)
  }

  const handleSocialLogin = async (socialInfo: SocialInfo, err: { error: string }) => {
    if (err) {
      if (err.error === 'popup_closed_by_user') {
        const error = { message: t('webapp.action.login_social_incognito') }
        toastr.options = {
          positionClass: `toast-top-center ${isNoStep3 ? 'toast-social-login' : 'step3-social-login'}`,
        }
        Utils.showToastrMessage('info', error.message)
      }
      return null
    }
    if (socialInfo) {
      const profile = socialInfo?._profile || {}
      const provider = socialInfo?._provider || ''
      const accessToken =
        provider === 'facebook' ? socialInfo._token.accessToken || '' : socialInfo._token.idToken || ''
      const params = {
        email: profile.email,
        provider,
        access_token: accessToken,
      }
      showLoading()
      const { data } = await CustomerAPI.socialLogin(params)
      if (!data.success) {
        if (data.error_code === 403) {
          const error = { message: t('errors.messages.suspended') }
          toastr.options = {
            positionClass: `toast-top-center ${isNoStep3 ? 'toast-social-login' : 'step3-social-login'}`,
          }
          hideLoading()
          return Utils.showToastrMessage('error', error.message)
        }
        if (!data.object) {
          setDataSignUp((s) => ({
            ...s,
            email: profile.email,
            first_name: profile.firstName,
            last_name: profile.lastName,
            access_token: accessToken,
            provider,
          }))
          setIsSignUpSocial(true)
          dispatch(accountManageActions.updateModalAccountManage(SIGN_UP_MODAL))
        } else {
          setDataMergeSocial({
            countryCode: data?.object?.country_code || '',
            email: data?.object?.email || '',
            phone: data?.object?.phone || '',
            firstName: data?.object?.first_name || '',
            lastName: data?.object?.last_name || '',
            businessName: data?.object?.business_name || '',
            referredBy: data?.object?.referred_by || '',
            access_token: accessToken || '',
            provider: provider || '',
            socialToken: accessToken,
          })
          dispatch(accountManageActions.updateModalAccountManage(MERGE_MODAL))
        }
      } else {
        const { area_id: areaID } = extraInfos
        const response: IUserInfo = await CustomerAPI.getCustomer(data?.access_token, { area_id: areaID })
        if (response?.statusApi === 200) {
          setAccessToken(data.access_token)
          await CustomerAPI.updateAuthenticationToken()
          dispatch(
            currentCustomerActionsCreator.updateCustomer({
              ...response,
              authentication_token: data?.access_token,
            })
          )
          if (!response.last_login_employ_id) {
            reCalculate()
            dispatch(signInProgressFinished(true))
            CommonUtils.handleTrackMoengage(currentCustomer, currentStep)
          } else {
            switchAccountHandler(response.last_login_employ_id, response.id)
          }
          dispatch(accountManageActions.updateModalAccountManage(''))
          checkRedirectPage()
        }
      }
      hideLoading()
    }
  }

  const showModal = () => {
    switch (accountModal) {
      case SIGN_IN_MODAL:
        return (
          <SignIn
            isNoStep3={isNoStep3}
            onReCalculate={reCalculate}
            onCheckRedirectPage={checkRedirectPage}
            onSetPrePassword={setPrePassword}
            onSocialLogin={handleSocialLogin}
            onCheckBackToStep1={checkBackToStep1}
          />
        )
      case MERGE_MODAL:
        return (
          <MergeAccount
            isNoStep3={isNoStep3}
            onCheckRedirectPage={checkRedirectPage}
            dataMergeSocial={dataMergeSocial}
          />
        )
      case SIGN_UP_MODAL:
        return (
          <SignUp
            onCheckBackToStep1={checkBackToStep1}
            isNoStep3={isNoStep3}
            onCheckRedirectPage={checkRedirectPage}
            dataSignUp={dataSignUp}
            onReCalculate={reCalculate}
            onSetDataSignUp={setDataSignUp}
            onSocialLogin={handleSocialLogin}
            isSignUpSocial={isSignUpSocial}
          />
        )
      case RESET_PASSWORD_MODAL:
        return <ResetPassword isNoStep3={isNoStep3} />
      case REVOLUTION_MODAL:
        return <WelcomeRevolution isNoStep3={isNoStep3} />
      case VERIFICATION_CODE_MODAL:
        return (
          <VerificationCode
            isNoStep3={isNoStep3}
            onCheckBackToStep1={checkBackToStep1}
            onReCalculate={reCalculate}
            onCheckRedirectPage={checkRedirectPage}
          />
        )
      case EDIT_PHONE_NUMBER_MODAL:
        return <EditPhoneNumber isNoStep3={isNoStep3} setDataSignUp={setDataSignUp} dataSignUp={dataSignUp} />
      case SWITCH_ACCOUNT_MODAL:
        return <ChooseAccount onSwitchAccount={switchAccountHandler} />
      case CONFIRM_PASSWORD_MODAL:
        return <ConfirmNewPassword isNoStep3={isNoStep3} prePassword={prePassword} />
      case GO_TO_STEP1_MODAL:
        return <GoToStep1 />
      case COMPANY_APPROVED_MODAL:
        return <CompanyApproved onCheckBackToStep1={checkBackToStep1} />
      default:
        return <></>
    }
  }
  useEffect(() => {
    hideLoading()
    if (view) {
      dispatch(currentStepActionsCreator.changeStep(1))
      dispatch(accountManageActions.updateModalAccountManage(view))
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [view])

  useEffect(() => {
    if (currentCustomer.id && !currentCustomer.verify_sms_code && !currentCustomer.sms_confirmed_at && !accountModal) {
      dispatch(accountManageActions.updateModalAccountManage(VERIFICATION_CODE_MODAL))
    }
    if (currentCustomer.id) setDataSignUp((s) => ({ ...s, phone: currentCustomer.phone }))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentCustomer])

  return <AccountManagementWrapper isNoStep3={isNoStep3}>{showModal()}</AccountManagementWrapper>
}

export default AccountManagementView
