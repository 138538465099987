import React, { FC, useEffect } from 'react'

import { createPortal } from 'react-dom'

import { IModal } from './IModal'
import { Backdrop, ModalContainer, ModalWrapper, IconModal, IdModal, ContentModal } from './SModal'
import Typography from '../Styled/STypography'
import { theme } from '../Styled/STheme'

const target = document.querySelector('body') as Element

const Modal: FC<IModal> = (props) => {
  const { isOpen, onClickClose, children, title, imageTitle, idModal, modalAction } = props

  useEffect(() => {
    const keyEscDown = (e: any) => {
      if (e.code === 'Escape') {
        onClickClose?.()
      }
    }
    document.addEventListener('keydown', keyEscDown)
    return () => {
      document.removeEventListener('keydown', keyEscDown)
      document.body.style.overflow = ''
      document.body.style.touchAction = ''
    }
  }, [])

  useEffect(() => {
    if (isOpen) {
      document.body.style.overflow = 'hidden'
      document.body.style.touchAction = 'none'
    } else {
      document.body.style.overflow = ''
      document.body.style.touchAction = ''
    }
  }, [isOpen])

  if (!isOpen) {
    return null
  }

  return createPortal(
    <ModalWrapper id="modal">
      <Backdrop
        isOpen={isOpen}
        onClick={(e) => {
          e.preventDefault()
          onClickClose?.()
        }}
      />
      <ModalContainer
        backgroundColor={props.backgroundColor}
        style={props.style}
        width={props.customWidth}
        height={props.customHeight}
        minWidth={props.customMinWidth}
        minHeight={props.customMinHeight}
        padding={props.customPadding}
        fullscreen={props.fullscreen}
        maxHeight={props.customMaxHeight}
        maxWidth={props.customMaxWidth}
        fixedWidth={props.customFixedWidth}
        fixedHeight={props.customFixedHeight}
        borderRadius={props.borderRadius}
        overflow={props.overflow}
      >
        <IconModal>
          <img src={imageTitle} alt={imageTitle} className="m-h36px" />
        </IconModal>
        <ContentModal>
          {title && (
            <Typography mt={10} mb={10} color={theme.colors.yellow[1]} variant={'t4'}>
              {title}
            </Typography>
          )}
          {idModal && (
            <IdModal idM={props.idM}>
              {idModal}
            </IdModal>
          )}
          {children}
        </ContentModal>
        {modalAction && modalAction}
      </ModalContainer>
    </ModalWrapper>,
    target
  )
}

export default Modal
