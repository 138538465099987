import apiClient from 'services/axiosApp'
import commonUtils from '../utils/common'
import { API_GATEWAY, PAYMENT_API } from '../constants/appConstants'
import { PROVIDER_TYPE_PAYMENT_LIST, TWO_C_TWO_P_TYPE } from 'constants/bookingConstants'
import { COUNTRY_TH } from 'constants/dashBoardAnalyticsConstants'
import { COUNTRY_ID } from 'constants/dashBoardAnalyticsConstants'
import I18n from 'i18n/i18n'

const PaymentIntegrationAPI = {
  getAddingFundSettings: async (
    countryCode,
    authenticationToken,
  ) => apiClient.get('/api/v3/top_up/settings', {
    params: {
      country_code: countryCode
    },
    headers: {
      'App-Name': `${commonUtils.appName()} Webapp`,
      'Device-Type': 'Web'
    }
  }),
  getPresignedPayment: (
    customerId,
    countryCode,
    amount,
    transactionId,
    companyId
  ) => {
    const params = {
      action: 'payment',
      streamId: customerId,
      transactionId,
      content: {
        amount,
        remark:['TH', 'th'].includes(countryCode) ? 'Payment to Deliveree' : 'Payment to 2C2P',
        ...(!!companyId && { companyId })
      }
    }
    const country = countryCode ? countryCode.toLowerCase() : 'ph'
    let urlApiPresign = `${API_GATEWAY}/v1/payment-gateway/card/${country}/presign`
    if (PAYMENT_API) {
      urlApiPresign = `${PAYMENT_API}/api/v1/card/${country}/presign`
    }
    return apiClient.post(urlApiPresign, { params })
  },
  getStatusPayment: (
    customerId,
    countryCode,
    transactionId,
  ) => {
    const country = countryCode ? countryCode.toLowerCase() : 'ph'
    let urlApiStatusPayment = `${API_GATEWAY}/v1/payment-gateway/card/${country}/${customerId}/transaction/${transactionId}`
    if (PAYMENT_API) {
      urlApiStatusPayment = `${PAYMENT_API}/api/v1/card/${country}/${customerId}/transaction/${transactionId}`
    }
    return apiClient.get(urlApiStatusPayment)
  },
  removeFirebaseChannel: (channelPath) => apiClient.delete(`${API_GATEWAY}/v1/notification`, {
    params: {
      xPath: channelPath,
      timeOut: null // able null
    }
  }),
  preSignAPIPayment: (params, customerId, countryCode, type) => {
    const country = countryCode && countryCode.toLowerCase() !== 'vn'
      ? countryCode.toLowerCase()
      : (type === PROVIDER_TYPE_PAYMENT_LIST[TWO_C_TWO_P_TYPE] ? 'ph' : 'id');
    const paymentType = type === PROVIDER_TYPE_PAYMENT_LIST[TWO_C_TWO_P_TYPE] ? 'card' : 'virtual-account';
    const url = `${API_GATEWAY}/v2/payment-gateway/${paymentType}/${country}/presign`;
  
    return apiClient.post(url, params, {
      headers: {
        'x-owner': 'customer',
        'x-owner-id': customerId,
        'x-use-case': 'bookings',
      },
    });
  },
  getPaymentInstructionAPI: (params) => apiClient.get(`${API_GATEWAY}/v3/payment-gateway/virtual-account/id/instructions`, { params }),
  createVAAPI: (preSignedData) => apiClient.post(preSignedData.host, preSignedData.requestBody, {
      headers: preSignedData.headers,
    }),
  getPaymentSettingAPI: (countryCode, sectionType = '') => {
    const language = I18n.language
    const country = countryCode ? countryCode.toLowerCase() : 'id'
    const isTHAndID = COUNTRY_TH === countryCode || COUNTRY_ID === countryCode
    const acceptLang = isTHAndID && language === 'en' ? `en-${countryCode}` : language
    return apiClient.get(`${API_GATEWAY}/v1/payment-gateway/setting/customer?country=${country}&section_type=${sectionType}`, {
      headers: {
        'Accept-Language': acceptLang,
      },
    })
  },
  checkIsValidPaymentAPI: (id, params) => apiClient.post(`${API_GATEWAY}/v1/payment-gateway/setting/${id}/available`, { ...params }),
  getItemPaymentSettings : (id, countryCode) => {
    const language = I18n.locale
    const isTHAndPH = countryCode === 'TH' || countryCode === 'ID'
    const acceptLang = isTHAndPH && language === 'en' ? `en-${countryCode}` : language
    return apiClient.get(`${API_GATEWAY}/v1/payment-gateway/setting/${id}/customer`, {
      headers: {
        'Accept-Language': acceptLang,
      }
    })
  }
}

export default PaymentIntegrationAPI
