import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import SButton from 'components/common/Styled/SButton'
import ModalNormal from 'components/common/Modal/ModalNormal'
import { theme } from 'components/common/Styled/STheme'
import SFlex from 'components/common/Styled/SLayout/SFlex'
import STypography from 'components/common/Styled/STypography'
import { useAppDispatch } from 'store/store'
import { EDIT_PHONE_NUMBER_MODAL } from 'components/new_booking/guest_flow/constants'
import { ContentWrapper, SMSCodeWrapper } from './SVerificationCode'
import { MAX_CODE_VERIFY } from 'constants/newBookingConstants'
import CustomerAPI from 'api/customers'
import { currentCustomerActionsCreator } from 'store/toolkit/currentCustomer/currentCustomer.reducer'
import toastr from 'utils/toast'
import { debounce } from 'lodash-es'
import { accountManageActions } from 'store/toolkit/accountManage/accountManage.reducer'

const VerificationCode: React.FC<{
  onReCalculate: () => void
  onCheckBackToStep1: () => void
  onCheckRedirectPage: () => void
  isNoStep3?: boolean
}> = ({ onReCalculate, onCheckBackToStep1, onCheckRedirectPage, isNoStep3 }) => {
  const { t } = useTranslation()
  const [smsToken, setSmsToken] = useState('')
  const dispatch = useAppDispatch()

  const resendSmsTokenAfterSignUp = async () => {
    try {
      const { data } = await CustomerAPI.resendSmsToken()
      if (data?.error) toastr.error(data.error)
      else toastr.success(t('success.messages.send_sms_verify'))
    } catch {
      toastr.error(t('errors.normal.verify_failed'))
    }
  }

  const handleChange = async (e: React.ChangeEvent<HTMLInputElement>) => {
    const valueCode = e.target.value
    if (valueCode.length < MAX_CODE_VERIFY) return setSmsToken(valueCode)
    const { data } = await CustomerAPI.verifySmsToken({ sms_token: valueCode })
    setSmsToken('')
    if (data?.error) return toastr.error(data?.error ? data?.error : t('errors.normal.verify_failed'))
    onReCalculate()
    dispatch(currentCustomerActionsCreator.updateCustomer({ verify_sms_code: true }))
    onCheckBackToStep1()
    onCheckRedirectPage()
    dispatch(accountManageActions.updateModalAccountManage(''))
    toastr.success(t('success.messages.verify_code'))
  }

  return (
    <ModalNormal
      topLevel={!isNoStep3}
      closeVariant="g1"
      customFixedWidth={'632px'}
      backgroundColor={theme.colors.white[1]}
      isOpen
    >
      <ContentWrapper>
        <STypography fw={500} mb={25} color={theme.colors.green[1]} fs={17}>
          {t('webapp.label.verification_code')}
        </STypography>
        <STypography mb={20} align="center" fs={15}>
          {t('webapp.label.check_verification_code_and_enter_it')}
        </STypography>
        <SMSCodeWrapper>
          {Array(MAX_CODE_VERIFY)
            .fill('')
            .map((_, idx) => (
              <STypography ml={idx === 0 ? 0 : 46} tag="span" key={idx}>
                {smsToken[idx] || ''}
              </STypography>
            ))}
          <input value={smsToken} onChange={handleChange} type="text" />
        </SMSCodeWrapper>

        <SFlex full mt={30}>
          <SButton
            w="50%"
            h="40px"
            mr={10}
            fs={17}
            onClick={() => dispatch(accountManageActions.updateModalAccountManage(EDIT_PHONE_NUMBER_MODAL))}
            variant="w2"
            fw={500}
            type="button"
          >
            {t('webapp.label.edit_number')}
          </SButton>
          <SButton
            w="50%"
            h="40px"
            fw={500}
            fs={17}
            ml={10}
            onClick={debounce(() => resendSmsTokenAfterSignUp(), 500)}
            variant="g2"
            br={`1px solid ${theme.colors.green[1]}`}
            type="button"
          >
            {t('webapp.label.resend_code')}
          </SButton>
        </SFlex>
      </ContentWrapper>
    </ModalNormal>
  )
}

export default VerificationCode
