import axios, { AxiosRequestConfig, AxiosResponse } from 'axios'
import StorageKeys from 'constants/storage-keys'
import I18n from 'i18n/i18n'
import { cloneDeep, isUndefined } from 'lodash'
import AuthenticationAPI from 'api/authentication'
import { CLIENT_ID, CLIENT_SECRET } from 'constants/appConstants'
import CommonUtils from 'utils/common'
import { setAccessToken } from 'utils/crossStorage'
import moment from 'moment'
// import queryString from 'query-string'

let refreshTokenPromise: any = null

const serializeQuery: any = (params: any, prefix: any) => {
  const query = Object.keys(params).map((key) => {
    const value = params[key]

    if (params.constructor === Array) key = `${prefix}[]`
    else if (params.constructor === Object) key = prefix ? `${prefix}[${key}]` : key

    if (value === null || value === undefined) return `${key}=`
    else if (typeof value === 'object') return serializeQuery(value, key)
    else return `${key}=${encodeURIComponent(value)}`
  })

  return [].concat.apply([], query).join('&')
}

const apiClient = axios.create({
  baseURL: process.env.REACT_APP_API_BASE_URL,
  headers: {
    'Content-Type': 'application/json',
    'Device-Type': 'Webapp',
    'Accept-Language': I18n.language,
  },
  paramsSerializer: (params: any) => serializeQuery(params),
})

apiClient.interceptors.request.use((config: AxiosRequestConfig) => {
  const headers = cloneDeep(config.headers)
  const accessToken = localStorage.getItem(StorageKeys.ACCESS_TOKEN)
  const clientAccessToken = localStorage.getItem(StorageKeys.CLIENT_ACCESS_TOKEN)
  const clientSession = localStorage.getItem(StorageKeys.X_CLIENT_SESSION)
  const currentRefreshToken = localStorage.getItem(StorageKeys.CLIENT_REFRESH_TOKEN)
  const getTokenParams = new URLSearchParams({
    client_id: CLIENT_ID || '',
    client_secret: CLIENT_SECRET || '',
    grant_type: 'refresh_token',
    refresh_token: currentRefreshToken || '',
  })
  const now = moment()
  const expiredTime = moment(localStorage.getItem(StorageKeys.EXPIRED_TIME))
  const isAfter = now.isAfter(expiredTime)
  let finalToken
  finalToken = clientAccessToken ? clientAccessToken : accessToken
  if (!isUndefined(headers?.authorization)) {
    finalToken = headers?.authorization
    delete headers?.authorization
  } else if (!isUndefined(headers?.Authorization)) {
    finalToken = headers?.Authorization
    delete headers?.Authorization
  }

  if (isAfter && !refreshTokenPromise && currentRefreshToken) {
    refreshTokenPromise = AuthenticationAPI.getTokenByRefreshToken(getTokenParams)
      .then((token) => {
        if (token.status === 400 || token.status === 401) {
          if (accessToken) {
            // TODO: consider request user login again
          } else {
            localStorage.removeItem(StorageKeys.CLIENT_ACCESS_TOKEN)
            localStorage.removeItem(StorageKeys.CLIENT_REFRESH_TOKEN)
            localStorage.removeItem(StorageKeys.EXPIRED_TIME)
            window.location.href = CommonUtils.handleAuthenticationToAuthService()
            return window.location.reload()
          }
        }
        refreshTokenPromise = null
        return token.data
      })
      .catch((err: any) => {
        console.debug(err)
      })
    return refreshTokenPromise
      .then(({ access_token, refresh_token, expires_in }: { access_token: string; refresh_token: string, expires_in: number }) => {
        if (!accessToken) {
          const timeToRequestToken = (expires_in / 3) * 2
          const timeExpired = moment().add(timeToRequestToken, 'seconds').toISOString()
          window.localStorage.setItem(StorageKeys.EXPIRED_TIME, timeExpired)
          localStorage.setItem(StorageKeys.CLIENT_ACCESS_TOKEN, access_token)
          localStorage.setItem(StorageKeys.CLIENT_REFRESH_TOKEN, refresh_token)
        }
      })
      .catch((err: any) => console.debug(err))
  }

  return {
    ...config,
    headers: {
      ...headers,
      ...(clientSession ? { 'x-client-session': clientSession } : {}),
      ...(finalToken ? { authorization: 'Bearer ' + finalToken } : {}),
    },
  }
})

apiClient.interceptors.response.use(
  (response: AxiosResponse) => {
    // const isTypeBlob = response.config.responseType === 'blob'
    // if (!isTypeBlob && response && response.data && typeof response.data === "object") {
    //   return { ...response.data, statusApi: response.status }
    // }
    // return { ...response, statusApi: response.status }
    return response
  },
  (error: any) => {
    const originalConfig = error.config
    if (error.response) {
      // refresh token expired
      const accessToken = localStorage.getItem(StorageKeys.ACCESS_TOKEN)
      const currentRefreshToken = accessToken
        ? localStorage.getItem(StorageKeys.REFRESH_TOKEN)
        : localStorage.getItem(StorageKeys.CLIENT_REFRESH_TOKEN)
      if (
        error.response.status === 400 &&
        error.response.data.error_uri.includes('https://documentation.openiddict.com/errors')
      ) {
        if (accessToken) {
          // TODO: consider request user login again
        } else {
          localStorage.removeItem(StorageKeys.CLIENT_ACCESS_TOKEN)
          localStorage.removeItem(StorageKeys.CLIENT_REFRESH_TOKEN)
          localStorage.removeItem(StorageKeys.EXPIRED_TIME)
          window.location.href = CommonUtils.handleAuthenticationToAuthService()
          return window.location.reload()
        }
      }

      if (error.response.status === 403) {
        console.debug(error)
      }

      const getTokenParams = new URLSearchParams({
        client_id: CLIENT_ID || '',
        client_secret: CLIENT_SECRET || '',
        grant_type: 'refresh_token',
        refresh_token: currentRefreshToken || '',
      })

      if (error.response.status === 401 && !originalConfig._retry) {
        originalConfig._retry = true

        if (!refreshTokenPromise) {
          if (!currentRefreshToken && !accessToken) {
            return (window.location.href = CommonUtils.handleAuthenticationToAuthService())
          }
          refreshTokenPromise = AuthenticationAPI.getTokenByRefreshToken(getTokenParams)
            .then((token) => {
              if (token.status === 401) {
                if (accessToken) {
                  return window.location.href = CommonUtils.handleAuthenticationToAuthService()
                } else {
                  localStorage.removeItem(StorageKeys.CLIENT_ACCESS_TOKEN)
                  localStorage.removeItem(StorageKeys.CLIENT_REFRESH_TOKEN)
                  localStorage.removeItem(StorageKeys.EXPIRED_TIME)
                  window.location.href = CommonUtils.handleAuthenticationToAuthService()
                  return window.location.reload()
                }
              }
              refreshTokenPromise = null
              return token.data
            })
            .catch((err: any) => {
              console.debug(err)
            })
        }

        return refreshTokenPromise
          .then(({ access_token, refresh_token, expires_in }: { access_token: string; refresh_token: string, expires_in: number }) => {
            if (accessToken) {
              setAccessToken(access_token, refresh_token)
            } else {
              const timeToRequestToken = (expires_in / 3) * 2
              const timeExpired = moment().add(timeToRequestToken, 'seconds').toISOString()
              window.localStorage.setItem(StorageKeys.EXPIRED_TIME, timeExpired)
              localStorage.setItem(StorageKeys.CLIENT_ACCESS_TOKEN, access_token)
              localStorage.setItem(StorageKeys.CLIENT_REFRESH_TOKEN, refresh_token)
            }
            return apiClient({
              ...originalConfig,
              headers: {
                ...originalConfig['headers'],
                authorization: 'Bearer ' + access_token,
              },
            })
          })
          .catch((err: any) => console.debug(err))
      } else {
        delete axios.defaults.headers.common.Authorization
      }
    }
    return Promise.reject(error)
  }
)

export default apiClient
