const StorageKeys = {
  ACCESS_TOKEN: 'access_token',
  REFRESH_TOKEN: 'refresh_token',
  CLIENT_ACCESS_TOKEN: 'client_access_token',
  CLIENT_REFRESH_TOKEN: 'client_refresh_token',
  VERIFY_CODE: 'verify_code',
  LANG_SELECT: 'en',
  LANGUAGE: 'language',
  COUNTRY_CODE: 'country_code',

  AREA_ID: 'areaId',
  AREA_REDUCER: 'area_reducer',

  X_CLIENT_SESSION: 'x-client-session',
  EXPIRED_TIME: 'expired_time'
};

export default StorageKeys;
