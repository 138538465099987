export const CHANGE_CURRENT_STEP = 'CHANGE_CURRENT_STEP'
export const typeBooking = { single: 'SINGLE', multiple: 'MULTIPLE', ezSheet: 'EZ_SHEET', smartLoad: 'SMART_LOAD' }

export const X_USE_CASE = {
  SHOW_MORE: 'show_more',
  NEW_BOOKING: 'new_booking',
}
export const REMOVE_TAG_HTML_REGEX = /<(?:"[^"]*"['"]*|'[^']*'['"]*|[^'">])+>/g
export enum NUMBER_TYPE {
  POSITIVE_NUMBER = 'POSITIVE_NUMBER',
  POSITIVE_NEGATIVE_NUMBER = 'POSITIVE_NEGATIVE_NUMBER',
  DECIMAL_POSITIVE_NUMBER = 'DECIMAL_POSITIVE_NUMBER',
  DECIMAL_POSITIVE_NEGATIVE_NUMBER = 'DECIMAL_POSITIVE_NEGATIVE_NUMBER',
  DECIMAL_NUMBER = 'DECIMAL_NUMBER',
}
export const validNumber = /^-?\d*?\d*$/
export const validPositiveNumber = /[^0-9]/g
export const validPositiveNegativeNumber = /[^0-9-]/g
export const validDecimalPositiveNumber = /[^0-9.]/g
export const validDecimalPositiveNegativeNumber = /[^0-9.-]/g