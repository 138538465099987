import { eventTrackingSingleSignIn, eventTrackingSignUpSingle } from './new_booking/eventTrackingNewBooking'
import {
  eventTrackingMultipleConfirmBatchSignIn,
  eventTrackingMultipleConfirmBatchSignUp,
} from './multiple_bookings/eventTracking'
import {
  eventTrackingEZsheetNonloginSignIn,
  eventTrackingEZsheetNonloginSignUp,
  eventTrackingEZsheetConfirmBatchSignUp,
  eventTrackingEZsheetConfirmBatchSignIn,
} from './ez_spread_sheet/eventTracking'
import { eventTrackingSmartloadplannerNonloginStep1 } from './smart_planner/eventTracking'
import CommonUtils from './common'

export const checkSignInFromURL = () => {
  const queryPathName = window.location.pathname
  let signInName = ''
  if (queryPathName === '/bookings/multiple') {
    signInName = 'SignInMultiple'
  } else if (queryPathName === '/batches/ez_spread_sheet') {
    signInName = 'SignInEzSheet'
  } else if (queryPathName === '/' || queryPathName === '/bookings/new') {
    signInName = 'SignInSingle'
  }
  return signInName
}

export const trackingSignInAction = (currentStep) => {
  const signInName = checkSignInFromURL()
  if (window.confirmBookingMultiple) {
    eventTrackingMultipleConfirmBatchSignIn()
    window.confirmBookingMultiple = false
  } else if (window.confirmBookingEZsheet) {
    eventTrackingEZsheetConfirmBatchSignIn(currentStep)
    window.confirmBookingEZsheet = false
  } else if (window.confirmSmartLoadStep1) {
    eventTrackingSmartloadplannerNonloginStep1()
    window.confirmSmartLoadStep1 = false
  } else if (signInName === 'SignInSingle') {
    eventTrackingSingleSignIn(currentStep)
  } else if (signInName === 'SignInEzSheet') {
    eventTrackingEZsheetNonloginSignIn(currentStep)
  }
}

export const trackingSignUpAction = (currentStep) => {
  const signInName = checkSignInFromURL()
  if (window.confirmBookingMultiple) {
    eventTrackingMultipleConfirmBatchSignUp()
    window.confirmBookingMultiple = false
  } else if (window.confirmBookingEZsheet) {
    eventTrackingEZsheetConfirmBatchSignUp(currentStep)
    window.confirmBookingEZsheet = false
  } else if (signInName === 'SignInSingle') {
    eventTrackingSignUpSingle(currentStep)
  } else if (signInName === 'SignInEzSheet') {
    eventTrackingEZsheetNonloginSignUp(currentStep)
  }
}

export const selectLocationField = (source, isPTL) => {
  CommonUtils.moengageTrackEvent('Select Location Field', {
    "Booking location": source,
    "Product": isPTL ? "PTL" : "FTL",
  })
}

export const eventSetLocation = (source, location, coordinate = {}, isPTL) => {
  CommonUtils.moengageTrackEvent("Set Location", {
    "Location source": source,
    "Booking location": location,
    "GPS coordinate": `${coordinate.latitude},${coordinate.longitude}`,
    "Product": isPTL ? "PTL" : "FTL"
  })
}


export const getGoogleLocationsResults = (source, value) => {
  CommonUtils.moengageTrackEvent("Get Google Locations Results", {
    "Booking location": source,
    "Search value": value
  })
}
