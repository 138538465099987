import { css } from 'styled-components'
import { styled, theme } from '../STheme'
import { ITypography, Variants } from './ITypography'

const rootFontSizePx = 16
export const pxToRem = (n: number): string => `${n / rootFontSizePx}rem`
const colorVar = {
  w1: `
      color: ${theme.colors.white[1]};
      `,
  d1: `
      color: ${theme.colors.dark[4]};
      `,
}

const font: Record<string, string> = {
  t7: '700',
  t6: '600',
  t5: '500',
  t4: '400',
  i7: '700',
  i6: '600',
  i5: '500',
  i4: '400',
  p7: '700',
  p6: '600',
  p5: '500',
  p4: '400',
  body: '400',
  e6: '600',
  e5: '500',
  e4: '400',
}

const fontSize = (breakpoints: string): Record<Variants, string> => {
  return {
    t7: `${pxToRem(24)}`,
    t6: `${pxToRem(breakpoints === 'desktop' ? 48 : 18)};`,
    t5: `${pxToRem(24)};`,
    t4: `${pxToRem(22)};`,
    i7: `${pxToRem(22)};`,
    i6: `${pxToRem(breakpoints === 'desktop' ? 20 : 14)};`,
    i5: `${pxToRem(breakpoints === 'desktop' ? 16 : 14)};`,
    i4: `${pxToRem(14)};`,
    p7: `${pxToRem(12)};`,
    p6: `${pxToRem(10)};`,
    p5: `${pxToRem(8)};`,
    p4: `${pxToRem(16)};`,
    body: `${pxToRem(14)};`,
    e6: `${pxToRem(breakpoints === 'desktop' ? 16 : 14)};`,
    e5: `${pxToRem(breakpoints === 'desktop' ? 16 : 14)};`,
    e4: `${pxToRem(13)};`,
  }
}

export const P = styled.p<ITypography>`
  font-size: ${({ fs, variant }) => (fs ? pxToRem(fs) : fontSize('desktop')[variant || 'body'])};
  font-weight: ${({ variant, fw }) => (fw ? fw : font[variant || 'body'])};
  text-align: ${({ align }) => (align ? `${align}` : `inherit`)};
  height: ${({ h }) => (h ? h : 'auto')};
  background-color: ${({ bgColor }) => bgColor || 'transparent'};
  ${({ cVar }) => colorVar[cVar || 'd1']}
  ${({ cVar, color }) =>
    color
      ? css`
          color: ${color}!important;
        `
      : colorVar[cVar || 'd1']}
  ${({ link, decoration, w }) =>
    link &&
    css`
      outline: none;
      cursor: pointer;
      text-decoration: ${decoration ? decoration : 'none'};
      width: ${w ? w : 'auto'};
    `}
  ${({ center }) =>
    center &&
    css`
      display: flex;
      justify-content: center;
      align-items: center;
    `}
  ${({ hvColor }) =>
    hvColor &&
    css`
      :hover {
        color: ${hvColor};
      }
    `}
    ${({ hvStyle }) =>
    hvStyle &&
    css`
      :hover {
        text-decoration: ${hvStyle};
      }
    `}
  ${({ opa }) =>
    opa &&
    css`
      opacity: ${opa};
    `}
  ${({ fStyle }) =>
    fStyle &&
    css`
      font-style: ${fStyle};
    `}
  ${({ lh }) =>
    lh &&
    css`
      line-height: ${lh}px;
    `}
  ${({ m, mt, mr, mb, ml }) =>
    css`
      margin: ${m ? `${m}px` : `${mt || 0}px ${mr || 0}px ${mb || 0}px ${ml || 0}px`};
    `}
  ${({ p, pt, pr, pb, pl }) =>
    (p || pt || pr || pb || pl) &&
    css`
      padding: ${p ? `${p}px` : `${pt || 0}px ${pr || 0}px ${pb || 0}px ${pl || 0}px`};
    `}
  ${({ numOfLine }) => {
    if (numOfLine) {
      return `
      display: -webkit-box;
      -webkit-line-clamp: ${numOfLine};
      -webkit-box-orient: vertical;  
      overflow: hidden;`
    }
  }}
${({ upper }) =>
    upper &&
    css`
      text-transform: capitalize;
    `}
  ${({ wordBreak }) => {
    if (wordBreak) return `word-break: break-word;`
  }}
`
