/* eslint-disable react/no-danger */
import React, { forwardRef, useLayoutEffect } from 'react'
import PropTypes from 'prop-types'
import useViewportResize from 'hooks/useViewportResize'
import { useTranslation } from 'react-i18next'
import { Utils } from 'utils/Utils'
import { CONTACT_CS, SHOW_MORE_LOCATION } from 'constants/imageConstants'
import { NO_RESULTS } from './index'
const BUFFER_BOTTOM = 10
const DropdownList = forwardRef(
  (
    {
      listItems,
      onClickItem,
      wrapperRect,
      extraLeftSidePositionDropdown, // Extra start position dropdown from left side of the input
      currentPositionDropdown,
      countryCode,
      showMore,
      showContactCS,
      onClickShowMore
    },
    ref
  ) => {
    const { height: viewportHeight } = useViewportResize()
    const { t } = useTranslation()

    useLayoutEffect(() => {
      const { x, width, bottom } = wrapperRect
      if (!ref.current) return
      const extraWidth = 70
      ref.current.style.setProperty('top', `${bottom}px`)
      ref.current.style.setProperty('left', `${x - extraLeftSidePositionDropdown}px`)
      ref.current.style.setProperty('width', `${width + extraWidth + extraLeftSidePositionDropdown}px`)
      ref.current.style.setProperty('max-height', `${viewportHeight - (bottom + BUFFER_BOTTOM)}px`)
    }, [extraLeftSidePositionDropdown, ref, viewportHeight, wrapperRect])

    return (
      <ul ref={ref} className="dropdown-location Custom-Scroll-Group">
        {listItems.map((item, index) => {
          if(item.id === NO_RESULTS.id) {
            return !!item.isShow && (
              <li
                key="no-results"
                className="dropdown-item no-results"
              >
                <div className='dropdown-item-line'>
                  <span className="dropdown-title">{t('webapp.new_booking.step_1.location_not_found')}</span> 
                </div>
              </li>
            )
          }
          return (
            <li
              key={item.id}
              className={`dropdown-item ${currentPositionDropdown === index && 'active'}`}
              onClick={() => onClickItem(item)}
            >
              <div className='dropdown-item-line'>
                {item.contact_id && <span className="Place-Icon Place-Icon-Recent" />}
                <span className="dropdown-title" dangerouslySetInnerHTML={{ __html: item.htmlName || item.name }} />
              </div>
            </li>
          )
        })}
      {showMore && (
          <li className="show-more-autocomplete" key="show-more">
            <div>{t('webapp.label.dont_see_your_address')}</div>
            <div onClick={onClickShowMore}>
              <img src={SHOW_MORE_LOCATION} alt="shore more" />
              {t('webapp.label.show_more_address_results')}
            </div>
          </li>
        )}
        {showContactCS && (
          <li className="show-contact-autocomplete" key="show-contact">
            <span className="icon-contact-cs"><img alt='icon-contact-cs' src={CONTACT_CS} /></span>
            {t('webapp.label.not_able_to_locate')}
            <span className="action-autocomplete" onClick={() => Utils.openFreshChat({ countryCode })}>{t('webapp.action.contact_cs')}</span>
          </li>
        )}
        
      </ul>
    )
  }
)
DropdownList.propTypes = {
  listItems: PropTypes.instanceOf(Array),
  onClickItem: PropTypes.func,
  currentPositionDropdown: PropTypes.number,
  extraLeftSidePositionDropdown: PropTypes.number,
  wrapperRect: PropTypes.shape({
    width: PropTypes.number,
    bottom: PropTypes.number,
    x: PropTypes.number,
  }),
  countryCode: PropTypes.string,
  showMore: PropTypes.bool,
  showContactCS: PropTypes.bool,
  onClickShowMore: PropTypes.func
}
DropdownList.defaultProps = {
  listItems: [],
  onClickItem: () => { },
  currentPositionDropdown: 0,
  extraLeftSidePositionDropdown: 0,
  wrapperRect: {
    width: 0,
    bottom: 0,
    x: 0,
  },
  onClickShowMore: () => {}
}
export default DropdownList
