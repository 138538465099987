import React, { createRef } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import _ from 'lodash'
import Promise from 'bluebird'
import moment from 'moment'
import $ from 'jquery'

// UTILS
import * as UtilsCommon from 'utils/new_booking/common'
import { Utils } from 'utils/Utils'
import { TallyUtils } from 'utils/booking/TallyUtils'
import * as bookingUtils from 'utils/booking/common'
import { formatPhoneNumber } from 'utils/booking/LTLbooking'
import phoneNumberUtils from 'utils/common/phoneNumber'
import I18n from 'i18n/i18n'
// API
import LocationAPI from 'api/locations'
import SettingAPI from 'api/settings'
// ACTIONS
import * as extraServiceActionCreators from 'store/actions/new_booking/extraServiceActionCreators'
import * as locationActionCreators from 'store/actions/new_booking/locationActionCreators'
import * as bookingActionCreators from 'store/actions/common/bookingActionCreators'
import * as customerActionCreators from 'store/actions/common/customerActionCreators'
import * as popupActionCreators from 'store/actions/common/popupActionCreators'
import * as timeTypeActionCreators from 'store/actions/new_booking/timeTypeActionCreators'
import * as roundTripDiscountActionCreators from 'store/actions/new_booking/roundTripDiscountActionCreators'
import * as serviceTypeActionCreators from 'store/actions/new_booking/serviceTypeActionCreators'
import * as assignDriverActionCreators from 'store/actions/new_booking/assignDriverActionCreators'

// COMPONENTS
import Attachment from 'components/new_booking/attachment/Attachment'
import Badge from 'components/new_booking/extra_service/Badge'
import BadgeForCompany from 'components/new_booking/extra_service/BadgeForCompany'
import CODAndPOD from 'components/new_booking/cod_and_pod/CODAndPOD'
import CODAndPODPopover from 'components/new_booking/cod_and_pod/CODAndPODPopover'
import SelectionPerLocationPopover from 'components/new_booking/extra_service/SelectionPerLocationPopover'
import FirstTimeDiscountPopup from 'components/new_booking/discount_code/FirstTimeDiscountPopup'
import ExtraRequirementPopups from 'components/new_booking/extra_service/ExtraRequirementPopups'
import EstimatedTransitTimePopup from 'components/common/popup/EstimatedTransitTimePopup'
import JobOrderNumber from 'components/new_booking/job_order_number/JobOrderNumber'
import LocationContacts from 'components/new_booking/locations/LocationContacts'
import OutServiceAreaPopup from 'components/new_booking/locations/OutServiceAreaPopup'
import Map from 'components/new_booking/map/Map'
import Note from 'components/new_booking/note/Note'
import ExtraRequirementForFullDay from 'components/new_booking/extra_service/ExtraRequirementForFullDay'
import ServiceTypeComingSoonPopup from 'components/common/popup/service_type/ServiceTypeComingSoonPopup'
import StepButton from 'components/new_booking/step_button/StepButton'
import StepsHeader from 'components/common/steps_header/StepsHeader'
import Summary from 'components/new_booking/summary/Summary'
import VehicleTypePopups from 'components/new_booking/vehicle_type/VehicleTypePopups'
import Reimbursements from 'components/new_booking/extra_service/Reimbursements'
import ReimbursementPopups from 'components/new_booking/extra_service/ReimbursementPopups'
import CustomReimbursementPopups from 'components/new_booking/extra_service/CustomReimbursementPopups'
import EventNotificationPopup from 'components/new_booking/event/EventNotificationPopup'
import PickupTimePopup from 'components/new_booking/pickup_time/PickupTimePopup'
import PickupTimeWarningPopup from 'components/common/popup/PickupTimeWarningPopup'
import DriverPreferences from 'components/new_booking/driver_preferences/DriverPreferences'
import AssignDriverChangeBookingDetailsPopup from 'components/common/popup/assign_driver/AssignDriverChangeBookingDetailsPopup'
import ShowRoute from 'components/common/ShowRoute'
import NumberDrivers from 'components/common/NumberDrivers'
import ContainerSize from 'components/new_booking/container_size/ContainerSize'
import RecentDriversPopup from 'components/common/driver_preference/RecentDriversPopup'
import MoreGroupExtraServices from 'components/new_booking/extra_service/MoreGroupExtraServices'
import RequireSignatures from 'components/common/RequireSignatures'
import TextEditMode from 'components/new_booking/edit_mode/TextEditMode'
import BookingCalendar from 'components/common/BookingCalendar'
import ColorBanner from 'components/new_booking/color_banner/ColorBanner'
import StepOneContent from 'components/new_booking/Step1Content/StepOneContent'
import MiniTooltip from 'components/common/tooltip/MiniTooltip'
import OutServiceLTLPopup from 'components/new_booking/locations/OutServiceLTLPopup'
import ChangeTimeTypePopup from 'components/new_booking/locations/ChangeTimeTypePopup'
import { UtilsHTML } from 'utils/html'
import CashbackInfo from 'components/common/CashbackInfo'
import CashBackBar from 'components/common/CashBackBar'
import CashBackExplain from 'components/common/popup/CashBackExplain'

// CONSTANTS
import {
  LONG_HAUL,
  FULL_DAY,
  SHIPMENT_STATUS,
  FAV_DRIVER_ID,
  WEB_MULTIPLE,
  WEB_SINGLE,
  SCHEDULE,
  DEFAULT_ACCEPTABLE_TIME_TYPES,
  NOW,
  SECTION_TYPE_PAYMENT_LIST,
  CUSTOMER_SPECIAL_SETTINGS,
} from 'constants/bookingConstants'
import {
  POPUP_ID_CHANGE_INFO_AFTER_ASSIGNED_DRIVER,
  POPUP_ID_YOUR_RECENT_DRIVERS,
  POPUP_CASHBACK_EXPLAIN
} from 'constants/common/popupConstants'
import { BP_ACCOUNT } from 'constants/imageConstants'

// API
import TallySummary from 'components/common/TallySummary'
import LongHaulInvalidDatePopup from 'components/new_booking/pickup_time/LongHaulInvalidDatePopup'
import BookingAPI from '../api/bookings'

import { withNavigate } from 'hocs/withNavigate'
import { checkLocationsActionsCreator } from 'store/toolkit/newBooking/checkLocations.reducer'
import { documentReturnActionsCreator } from 'store/toolkit/newBooking/documentReturn.reducer'
import { bookingActionsCreator } from 'store/toolkit/bookings/booking.reducer'
import { hideLoading } from 'assets/javascripts/webapp-v2/common'
import { setAccessToken } from 'utils/crossStorage';
import { extraInfosActionsCreator } from 'store/toolkit/extraInfos/extraInfos.reducer'
// ASSETS
import FrontPage from 'components/FrontPage'
import CustomerAPI from 'api/customers'
import mapUtils from 'utils/common/map'
import CommonUtils from 'utils/common'
import { DRIVER_PREFERENCES } from 'constants/newBookingConstants'
import SwitchAccount from 'view/AccountManagementView/SwitchAccount'
import { SIGN_IN_MODAL } from 'components/new_booking/guest_flow/constants'
import { accountManageActions } from 'store/toolkit/accountManage/accountManage.reducer'
const LTL_CONSTANT = {
  MIN_UNIT_QUANTITY: 'MinUnitQuantity',
  TRANSPORT_TYPE_DEFAUL: 'TransportTypeDefault',
}

const mapStateToProps = state => ({
  currentStep: state.currentStep,
  locations: state.locations,
  extraInfos: state.extraInfos,
  bookAgainDetails: state.bookAgainDetails,
  timeType: state.timeType,
  selectedVehicleTypeID: state.selectedVehicleTypeID,
  selectedServiceTypeID: state.selectedServiceTypeID,
  currentPopupID: state.currentPopupID,
  isNewDriver: state.isNewDriver,
  extraServices: state.extraServices,
  currentCustomer: state.currentCustomer,
  dynamicTexts: state.extraInfos?.dynamicTexts,
  requireSignatures: state.requireSignatures,
  booking: state.booking,
  autoSwitchLongHaul: state.autoSwitchLongHaul,
  showDatePicker: state.showDatePicker,
  popupContentManagement: state.popupContentManagement || [],
  numberDrivers: state.countNumberOfOnlineDrivers,
  pickupTime: state.pickupTime,
  favDriverId: state.favDriverId,
  isCheckedSubAccount: state.checkSubAccount,
  subAccountTagPicked: state.subAccountTagPicked,
  isLoginStep3: state.isLoginStep3,
  serviceTypes: state.serviceTypes,
  stepActions: state.stepActions,
})

function mapDispatchToProps(dispatch) {
  return {
    extraServiceActions: bindActionCreators(extraServiceActionCreators, dispatch),
    locationActions: bindActionCreators(locationActionCreators, dispatch),
    assignDriverActions: bindActionCreators(assignDriverActionCreators, dispatch),
    bookingActions: bindActionCreators(bookingActionCreators, dispatch),
    customerActions: bindActionCreators(customerActionCreators, dispatch),
    popupActions: bindActionCreators(popupActionCreators, dispatch),
    checkLocationActions: bindActionCreators(checkLocationsActionsCreator, dispatch),
    documentActions: bindActionCreators(documentReturnActionsCreator, dispatch),
    timeTypeActions: bindActionCreators(timeTypeActionCreators, dispatch),
    roundTripDiscountActions: bindActionCreators(roundTripDiscountActionCreators, dispatch),
    serviceTypeActions: bindActionCreators(serviceTypeActionCreators, dispatch),
    bookingActionsCreator: bindActionCreators(bookingActionsCreator, dispatch),
    extraInfosActions: bindActionCreators(extraInfosActionsCreator, dispatch),
    accountManageActionsProps: bindActionCreators(accountManageActions, dispatch),
  }
}

class NewBookingContainer extends React.Component {
  static checkAfterSignedIn(object) {
    if (_.isNull(object) || _.isEmpty(object.authentication_token)) {
      setAccessToken('')
    }
  }

  constructor(props) {
    super(props)
    this.state = {
      closedEventNotificationPopup: false,
      loadDriverPreferences: false,
      finishedStep1: false,
      fullLoadView: true,
      pickupTimeLTL: null,
      handlingUnitValue: null,
      isValidLH: false,
      outsideList: [],
      errorDistanceList: [],
      isHaveLHAddress: false,
      isErrorPickupLocation: false,
      isShowPopupChangePickupLocation: false,
      extendedList: [],
      isShowChangeTimeType: false,
      isShowChangeTimeTypeClick: false,
      comingSoonServiceTypeName: '',
      isShowComingService: false,
      iconComingSoonService: '',
      isShowPickupTimePopup: false,
      isDateFetched: false,
      initDatePickup: null,
      isWaitingSetData: false,
      frontPageMenu: {},
    }
    this.handleClickOnStep = this.handleClickOnStep.bind(this)
    this.driverPreferencesPopup = createRef()
    this.refStep3Container = createRef(null)
    this.isLoadedAttachment = createRef(false)

    this.stepButton = null
    this.bookingPtlRequireSignatures = null
    // debounce 300ms to avoid calling api so many times
    this.triggerGetTallyDataAction = _.debounce(this.triggerGetTallyDataAction, 300)
    this.cashbackUpdateValueForService = _.debounce(this.cashbackUpdateValueForService, 300)
    this.triggerGetTallyTransitTimeAction = _.debounce(this.triggerGetTallyTransitTimeAction, 300)
    this.triggerValidateLocation = _.debounce(this.triggerValidateLocation, 300)
    this.triggerLoadEtaPopupDataIfNeeded = _.debounce(this.triggerLoadEtaPopupDataIfNeeded, 300)
    this.closePicker = this.closePicker.bind(this)
    this.handleViewStepOne = this.handleViewStepOne.bind(this)
    this.handleLTLPickUpTime = this.handleLTLPickUpTime.bind(this)
    this.handleHandlingUnitValue = this.handleHandlingUnitValue.bind(this)
    this.submitPartial = this.submitPartial.bind(this)
    this.setOutsideList = this.setOutsideList.bind(this)
    this.setListErrorDistance = this.setListErrorDistance.bind(this)
    this.handleErrorPickupLocation = this.handleErrorPickupLocation.bind(this)
    this.handleShowChangePickupLocation = this.handleShowChangePickupLocation.bind(this)
    this.setIsShowChangeTimeType = this.setIsShowChangeTimeType.bind(this)
    this.setIsShowChangeTimeTypeClick = this.setIsShowChangeTimeTypeClick.bind(this)
    this.handleCancelChangeTimeTypePopup = this.handleCancelChangeTimeTypePopup.bind(this)
    this.checkPramToGetShipment = this.checkPramToGetShipment.bind(this)
    this.checkTokenToSignIn = this.checkTokenToSignIn.bind(this)
    this.handleUpdateAuthenticationToken = this.handleUpdateAuthenticationToken.bind(this)
    this.setComingSoonServiceTypeName = this.setComingSoonServiceTypeName.bind(this)
    this.checkLocationsForPTL = _.debounce(this.checkLocationsForPTL, 300)
  }

  componentDidMount() {
    const {
      currentCustomer, bookAgainDetails, bookingActions, customerActions,
      selectedServiceTypeID, timeType, assignDriverActions, locations,
      selectedVehicleTypeID, stepActions, isNewDriver,
      extraInfos, documentActions, area, setIsWaitingCalculate,
    } = this.props
    const urlParams = new URLSearchParams(window.location.search)
    const isPtl = urlParams.get('ptl')
    const shipmentId = urlParams.get('shipmentId')
    const vehicleTypeId = urlParams.get('vehicleTypeId')
    const token = urlParams.get('token')
    const currentStepPtl = urlParams.get('step')
    const bookingIDParam = urlParams.get('booking_id')
    const isMKT = urlParams.get('is_mkt')

    if (bookingIDParam && !_.isNaN(Number(bookingIDParam))) {
      window.location.href = `/bookings/${bookingIDParam}`
    }

    const getDataFrontPageMenu = async (countryCode, areaId) => {
      const frontPageMenuData = await CustomerAPI.getFrontPageMainMenu(countryCode, areaId);
      this.setState({ frontPageMenu: frontPageMenuData.data })
    }

    getDataFrontPageMenu(area.country_code, area.id)

    if (isMKT && TallyUtils.validateDataForRecalculatingTally({
      timeType, vehicleTypeID: selectedServiceTypeID, locations
    })) {
      this.triggerGetTallyDataAction()
    }

    this.checkTokenToSignIn(currentStepPtl, shipmentId)
    const isRedirectPTL = token && currentStepPtl && shipmentId
    if (isRedirectPTL) {
      return
    }

    customerActions.getCustomerCreditAmount()

    if (isPtl === 'true') {
      this.setState({ fullLoadView: false })
    }

    if (extraInfos.document_return_address) {
      const bookingTracking =
        bookAgainDetails && bookAgainDetails.booking_tracking
          ? bookAgainDetails.booking_tracking
          : extraInfos.document_return_address;
      documentActions.updateDocumentReturn(bookingTracking)
    }

    // Get Favorite Amount number at step 2
    if (currentCustomer.authentication_token) {
      const params = {
        authentication_token: currentCustomer.authentication_token,
        include_fleet_driver: true,
        vehicle_type_id: selectedVehicleTypeID,
        area_id: currentCustomer.area_id,
        country_code: extraInfos.country_code,
        company_id: currentCustomer.current_company_id === 0 ? undefined : currentCustomer.current_company_id,
      }
      bookingActions.getFavoriteAmount(params)
    }

    if (bookingUtils.isEditBooking() && !UtilsCommon.isCustomerEditBooking()) {
      // Get assigned driver for edit flow
      if (bookingUtils.hasAssignDriver()) {
        const params = CommonUtils.getParamsForLoadFavoriteCommon(this.props, false)
        bookingActions.loadFavoriteDrivers(params, stepActions, (response) => {
          const assignableDrivers = _.isEmpty(response?.data.data) ? [] : response.data.data
          if (assignableDrivers.length > 0) {
            const driverId = +bookingUtils.getQueryVariable('driver_id')
            const assignableDriver = assignableDrivers.find(driver => driver.id === driverId
              || driver.fleet_partner_id === driverId)
            assignDriverActions.changeAssignDriver(assignableDriver)
          }
        })
      } else {
        // Get sendToFavorite default from booking when edit first load
        const { send_first_to_favorite: sendFirstToFavorite } = bookAgainDetails
        let defaultFav = sendFirstToFavorite ? DRIVER_PREFERENCES.favorite : DRIVER_PREFERENCES.all
        if (isNewDriver) defaultFav = DRIVER_PREFERENCES.assign
        bookingActions.updateBookingAttributes({
          driverPreferencesTab: defaultFav
        })
      }
    }

    // Noted: window.cancelToEdit is set by Rails
    if (bookingUtils.isEditBooking()) {
      this.setState({ isWaitingSetData: true })
      const {
        extraServiceActions, extraInfos,
        timeType, locationActions, checkLocationActions
      } = this.props
      const tasks = []
      tasks.push(
        new Promise((resolve) => {
          extraServiceActions.getExtraServices(() => {
            // recalculate price for tally(step2Price) when the customer edit booking
            if(UtilsCommon.isCustomerEditBooking()) {
              bookingActions.calculateExtraServicesPrice()
            }

            this.setState({
              loadDriverPreferences: true
            }, () => {
              if (UtilsCommon.isCancelToEdit()) {
                const bookingId = window.location.pathname.split('/')[2]
                if (!bookingUtils.isPaymentBooking(bookAgainDetails.payment_method_for_non_bp)) {
                  bookingActions.getBookingPaymentSettingAction(bookingId, SECTION_TYPE_PAYMENT_LIST[CUSTOMER_SPECIAL_SETTINGS])
                }
              }
            })

            resolve()
          })
        })
      )

      // update COD & POD
      const fees = bookAgainDetails.cod_pod_fees || 0
      if (timeType === LONG_HAUL) {
        $('#cod-pod-fees').html(`${I18n.t('webapp.pricing_display.free')}`)
      } else {
        $('#cod-pod-fees').html(`${Utils.formatFee(fees, extraInfos.country_language, extraInfos.currency)}`)
      }

      // Should call check location when edit booking to verify New gen POD
      const {
        locations,
        service_type_id: serviceTypeID,
        vehicle_type_id: vehicleTypeID
      } = bookAgainDetails

      const isEnableGoogleMap = mapUtils.isEnableGoogleMap(extraInfos)
      tasks.push(
        LocationAPI.validLocations(
          locations,
          { timeType, serviceTypeID, vehicleTypeID },
          {
            countryCode: currentCustomer.country_code || extraInfos?.country_code,
            companyId: currentCustomer.current_company_id,
            authenticationToken: currentCustomer.authentication_token,
            isLogin: currentCustomer.id,
            isEditBooking: true,
            isEnableGoogleMap
          },
          null,
          (resultApi) => {
            if (resultApi.status !== 400) {
              checkLocationActions.updateCheckLocationInfos(resultApi)
            }
          }
        )
      )

      tasks.push(locationActions.initTmpLocations())
      // when editing booking, all booking data is set in bookAgainDetails
      // Some logic reply on state.booking to calculate data and show info
      // We have to copy data from bookAgainDetails to booking.
      tasks.push(this.prepareTallyDataWhenEditing())
      tasks.push(this.triggerLoadEtaPopupDataIfNeeded())

      Promise.all(tasks)
        .then(() => Utils.sleep(2000))
        .then(() => (
          // Notes: During PickupTime init it trigger actionChangeTimeType and cause state.dataChange being set true
          // We need to reset it for 'cancel to edit' case,
          //    because we actually apply from model directly, not change by user
          // This is not good approach, but we don't have time to refactor code to handing dataChange better
          bookingActions.clearDataChangeForEditBooking()
        ))
        .then(() => (
          bookingActions.turnBookingAgainDataIntoState(bookAgainDetails)
        )).then(() => (this.setState({ isWaitingSetData: false })))
        .then(() => {
          if (UtilsCommon.isCancelToEdit()) {
            bookingActions.calculate(() => {
              setIsWaitingCalculate(false)
              hideLoading()
            })
          } else hideLoading()
        })
    }

    $('body').addClass('Step-Route')
    this.checkPramToGetShipment(shipmentId, vehicleTypeId)
    this.getPickUpData()

    const redirectTarget = urlParams.get('target')
    if (redirectTarget) {
      setTimeout(() => {
        return window.location.replace(`/${redirectTarget}&lang=${urlParams.get('lang')}`)
      }, 500);
    }
  }

  componentWillReceiveProps(nextProps) {
    const {
      currentStep
    } = this.props
    if (nextProps.currentStep && currentStep !== nextProps.currentStep && currentStep === 1) {
      this.setState({
        finishedStep1: true,
      })
    }
  }

  componentDidUpdate(prevProps, prevState) {
    const {
      timeType,
      locationActions,
      booking,
      timeTypeActions,
    } = this.props
    const { shipmentLTLDetail } = booking
    const {
      pickupTimeLTL,
      fullLoadView,
      isErrorPickupLocation,
      isDateFetched,
      initDatePickup
    } = this.state
    const urlParams = new URLSearchParams(window.location.search)
    const shipmentId = urlParams.get('shipmentId')
    const vehicleTypeId = urlParams.get('vehicleTypeId')
    if (isDateFetched && !pickupTimeLTL) {
      if (!shipmentId) {
        this.handleLTLPickUpTime(initDatePickup)
      } else if (shipmentLTLDetail) {
        const newPickUptime = _.get(shipmentLTLDetail, 'addresses.pickup.pickupDate')
        const validDate = newPickUptime && moment().isBefore(newPickUptime, 'day')
        if (fullLoadView) {
          const Id = vehicleTypeId.split('-')
          timeTypeActions.changePickupTime(moment(Number(Id[2])).toISOString())
          const changePickupTime = Id[3]
          if (changePickupTime) {
            timeTypeActions.showDatePicker(true)
          }
        }
        if (validDate) {
          this.handleLTLPickUpTime(newPickUptime, true)
        } else {
          this.handleLTLPickUpTime(initDatePickup)
        }
      }
    }

    if (timeType !== prevProps.timeType) {
      locationActions.resetDropOffZones()
    }

    const detectedChanges = this.detectBookingDataChanges(prevProps)
    // TODO: we temporarily disable auto hide time type according to locations until new LONG HAUL feature
    // if (detectedChanges.isLocationChanged || detectedChanges.isServiceVehicleTimeTypeChanged) {
    //   this.triggerValidateLocation()
    // }
    this.triggerRecountCashback(detectedChanges, prevProps, prevState)

    if (detectedChanges.isServiceVehicleTimeTypeChanged && this.isEnabledTally()) {
      this.triggerLoadEtaPopupDataIfNeeded()
    }

    if (fullLoadView) {
      this.invalidateChangeForTally(prevProps, detectedChanges)
    }

    if (!fullLoadView && detectedChanges.isLocationChanged) {
      this.checkLocationsForPTL()
    }

    if (!fullLoadView && isErrorPickupLocation) {
      this.handleErrorPickupLocation(false)
    }
    
    if (!fullLoadView && fullLoadView !== prevState.fullLoadView) this.getSettingsCustomerByArea()
  }

  /**
   * Set value for isHaveLHAddress state
   * @param {Boolean} isHaveLHAddress
   */
  setIsHaveLHAddress(isHaveLHAddress) {
    this.setState({ isHaveLHAddress })
  }

  setOutsideList(outsideList) {
    this.setState({
      outsideList,
    })
  }

  setExtendedList(extendedList) {
    this.setState({ extendedList })
  }

  setIsShowChangeTimeType(isShowChangeTimeType) {
    this.setState({ isShowChangeTimeType })
  }

  setIsShowChangeTimeTypeClick(isShowChangeTimeTypeClick) {
    this.setState({ isShowChangeTimeTypeClick })
  }

  setListErrorDistance(errorDistanceList) {
    this.setState({
      errorDistanceList
    })
  }

  setComingSoonServiceTypeName(comingSoonServiceTypeName) {
    this.setState({
      comingSoonServiceTypeName,
    })
  }

  setShowComingSoonService = (isShowComingService) => {
    this.setState({
      isShowComingService
    })
  }

  setIconComingSoonService = (iconComingSoonService) => {
    this.setState({
      iconComingSoonService
    })
  }

  setIsShowPickupTimePopup = (isShowPickupTimePopup) => {
    this.setState({
      isShowPickupTimePopup
    })
  }

  getSettingsCustomerByArea = async () => {
    const { id } = this.props.extraInfos
    if (_.isNull(this.bookingPtlRequireSignatures)) {
      const result = await SettingAPI.getSettingsCustomerByArea(id)
      if (result.status === 200) {
        this.bookingPtlRequireSignatures = result.data.booking_ptl_require_signatures
      }
    }
  }

  getPickUpData = async () => {
    const { extraInfos } = this.props
    const {
      area_id: areaId,
      enable_same_day_ptl_booking: enableSameDayPtlBooking,
      ltl_booking_end_time: endTime
    } = extraInfos
    const date = moment().add(enableSameDayPtlBooking ? 0 : 1, 'day')
    try {
      const response = await BookingAPI.getPickupLTL(areaId)
      const { pickup_window: pickupWindow, is_next_day: isNextDay } = response?.data
      if (pickupWindow && !isNextDay) {
        const pickup = pickupWindow.split(':')
        date.set('hour', pickup[0])
        date.set('minute', pickup[1])
      } else {
        const pickup = endTime.split(':')
        date.set('hour', pickup[0])
        date.set('minute', pickup[1])
      }
    } catch (error) {
      const pickup = endTime.split(':')
      date.set('hour', pickup[0])
      date.set('minute', pickup[1])
    } finally {
      this.setState({
        initDatePickup: date,
        isDateFetched: true
      })
    }
  }

  // if we use selector we can optimize performance
  getEtaPopupData() {
    const {
      area,
      timeType,
      popupContentManagement,
    } = this.props
    const popupType = bookingUtils.getETAPopupTypeByTimeType(timeType)
    const popupData = popupContentManagement.find(
      item => (item.area_id === area.id && item.popup_type === popupType)
    )

    return popupData && popupData.data
  }

  checkLocationsForPTL() {
    const {
      locations,
      currentCustomer,
      area,
      extraInfos
    } = this.props
    const {
      fullLoadView,
    } = this.state
    const isEnableGoogleMap = mapUtils.isEnableGoogleMap(extraInfos)
    Promise.resolve(LocationAPI.validLocations(
      locations,
      {},
      {
        countryCode: area.country_code,
        companyId: currentCustomer.current_company_id,
        authenticationToken: currentCustomer.authentication_token,
        isLogin: currentCustomer.id,
        isEnableGoogleMap
      },
      null,
      (res) => {
        if (res.status !== 400) {
          const indexList = bookingUtils.getIndexListOfValidAddress(locations)
          const { outside_list: outsideList, extended_list: extendedList } = res
          const {
            formatOutSideList
          } = bookingUtils.getOSAAndESAList(locations, indexList, outsideList, extendedList)
          const finalOSAList = formatOutSideList
          this.setOutsideList(finalOSAList)
        }
      },
      fullLoadView
    ))
  }

  checkPramToGetShipment(shipmentId, vehicleTypeId) {
    const {
      bookingActions, serviceTypeActions, timeTypeActions
    } = this.props
    const { fullLoadView } = this.state
    if (shipmentId) {
      bookingActions.getShipmentLTLDetail(
        shipmentId
      )
      if (vehicleTypeId) {
        const Id = vehicleTypeId.split('-')
        const serviceTypeID = Id[0]
        const vehicleTypeID = Id[1]
        this.handleViewStepOne(true)
        serviceTypeActions.changeServiceType(Number(serviceTypeID), Number(vehicleTypeID))
        timeTypeActions.actionChangeTimeType(SCHEDULE)
        timeTypeActions.changeTimeTypeUI(SCHEDULE)
      }
      if (fullLoadView && !vehicleTypeId) {
        this.handleViewStepOne(false)
      }
    }
  }

  checkTokenToSignIn = async (currentStepPtl, shipmentId) => {
    const { currentCustomer } = this.props
    const urlParams = new URLSearchParams(window.location.search)
    const jwtToken = urlParams.get('token')
    const companyId = urlParams.get('companyId')

    let isLoginWithJwtToken = false
    let isSwitchAccount = false
    if (jwtToken) {
      urlParams.delete('token')
      urlParams.delete('companyId')
      const oldToken = window.localStorage.getItem('access_token')
      isLoginWithJwtToken = !oldToken
      isSwitchAccount = oldToken && oldToken !== jwtToken && companyId
      setAccessToken(jwtToken)
      CustomerAPI.updateAuthenticationToken(jwtToken)

      const newUrl = `${window.location.pathname}?${urlParams.toString()}`
      window.history.pushState(null, '', newUrl)
    }

    if (isSwitchAccount) {
      this.handleUpdateAuthenticationToken()
    } else if (_.isEmpty(currentCustomer.authentication_token) || isLoginWithJwtToken) {
      this.handleUpdateAuthenticationToken(currentStepPtl, shipmentId)
    }
  }

  handleUpdateAuthenticationToken(currentStepPtl, shipmentId) {
    const token = window.localStorage.getItem('access_token')
    const isRedirectPtl = currentStepPtl && shipmentId && token
    const {
      extraInfos
    } = this.props
    if (isRedirectPtl) {
      $('#loading-modal').addClass('visible')
      const defaultCountry = extraInfos.country_code.toLowerCase()
      window.location.replace(`${Utils.getLTLCustomerDomain(defaultCountry)}/booking/${shipmentId}?step=${currentStepPtl}&token=${token}`)
    } else {
      this.calculateAfterSignIn()
    }
  }

  handleCancelChangeTimeTypePopup() {
    const { outsideList, extendedList } = this.state
    const newList = _.union(outsideList, extendedList)
    this.setOutsideList(newList)
    this.setIsShowChangeTimeType(false)
    const element = document.getElementById(`booking_locations_attributes_${newList[0]}_name`)
    element.click()
  }

  triggerLoadEtaPopupDataIfNeeded() {
    const {
      popupActions,
      currentCustomer,
      area,
      timeType,
      popupContentManagement,
    } = this.props
    const popupType = bookingUtils.getETAPopupTypeByTimeType(timeType)
    const popupData = popupContentManagement.find(
      item => (item.area_id === area.id && item.popup_type === popupType)
    )

    if (!popupData) {
      popupActions.fetchPopupContent(area.id, timeType, currentCustomer)
    }
  }

  prepareTallyDataWhenEditing() {
    const {
      bookAgainDetails,
    } = this.props

    const { isValidLH } = this.state

    if (this.isEnabledTally()) {
      const {
        bookingActions,
        locationActions,
      } = this.props

      const bookingData = TallyUtils.extractTallyDataFromBookingAgainDetail(bookAgainDetails)

      return Promise
        .resolve(bookingActions.updateBookingAttributes(bookingData))
        .then(() => (
          // trigger this action to setup location and put into state.
          // atm this logic is specific for tally, if we have similar feature please refactor it.
          locationActions.setUpLocations(this.isShopping())
        ))
        .then(() => {
          // refetch tally data for step1
          const params = {
            specificStep: 1,
            isLocationChanged: false,
            isValidLH,
          }

          return bookingActions.getTallyData(params)
        })
        .then(() => {
          const tasks = []
          if (bookingData.time_type === FULL_DAY) {
            tasks.push(bookingActions.getTallyTransitTime({
              specificStep: 1,
              isLocationChanged: false,
              isValidLH,
              callback: () => { },
            }))
          }
          return Promise.all(tasks)
        })
        .then(() => {
          this.setState({
            finishedStep1: true,
          })
        })
    }

    return Promise.resolve(true)
  }

  /**
   * We only trigger any additional api call only when tally setting is enabled
   *    otherwise keep the flow as it is without tally.
   */
  invalidateChangeForTally(prevProps, detectedChanges) {
    if (!this.isShowedTallybar()) {
      return
    }

    const {
      timeType,
      currentStep,
      bookingActions,
    } = this.props

    const {
      finishedStep1
    } = this.state

    // const detectedChanges = this.detectBookingDataChanges(prevProps)
    const dataChanged = Object.values(detectedChanges).some(v => (!!v))
    const isChangeStep = prevProps.currentStep !== currentStep

    let task
    if (dataChanged || isChangeStep) {
      const {
        selectedServiceTypeID: vehicleTypeID,
        locations,
      } = this.props

      if (TallyUtils.validateDataForRecalculatingTally({ timeType, vehicleTypeID, locations })) {
        this.triggerGetTallyTransitTimeAction(detectedChanges, isChangeStep, prevProps.currentStep)
        if (!(isChangeStep && currentStep === 1)) {
          task = Promise.promisify(this.triggerGetTallyDataAction, { context: this })(detectedChanges)
        }
      }
    }

    const isResetData = currentStep === 1
      && (detectedChanges.isServiceVehicleTimeTypeChanged || detectedChanges.isLocationChanged)
      && finishedStep1

    if (isResetData) {
      Promise.resolve(task).then(() => {
        this.setState({
          finishedStep1: false,
        })

        bookingActions.resetTallyDataStep2()
      })
    }
  }

  triggerRecountCashback(detectedChanges, prevProps, prevState) {
    const {
      currentStep
    } = this.props
    const {
      isHaveLHAddress
    } = this.state
    const dataChanged = Object.values(detectedChanges).some(v => (!!v))
    if (dataChanged
      || (prevProps.currentStep !== currentStep)
      || (prevState.isHaveLHAddress !== isHaveLHAddress)
    ) {
      this.cashbackUpdateValueForService(prevProps)
    }
  }

  detectBookingDataChanges(prevProps) {
    /**
     * We don't need to detect change for optimize route and return discount (round_trip_discount)
     *    because change of optimize route or return discount will make change to locations
     */
    const oldValue = {
      timeType: prevProps.timeType,
      vehicleTypeID: prevProps.selectedVehicleTypeID,
      serviceTypeID: prevProps.selectedServiceTypeID,
      locations: prevProps.locations,
      extraServices: prevProps.extraServices,
      codPodFees: prevProps.booking && prevProps.booking.cod_pod_fees,
      pickupTime: prevProps.pickupTime,
      currentCompanyId: prevProps.currentCustomer.current_company_id
    }

    const {
      timeType,
      selectedVehicleTypeID: vehicleTypeID,
      selectedServiceTypeID: serviceTypeID,
      locations,
      extraServices = {},
      booking: { cod_pod_fees: codPodFees },
      pickupTime,
      currentCustomer: { current_company_id: currentCompanyId }
    } = this.props

    const newValue = {
      serviceTypeID,
      timeType,
      vehicleTypeID,
      locations,
      extraServices,
      codPodFees,
      pickupTime,
      currentCompanyId
    }

    return bookingUtils.detectBookingDataChanges(oldValue, newValue)
  }

  cashbackUpdateValueForService(prevProps) {
    const {
      currentStep,
      bookingActions,
      customerActions,
      locations,
      timeType,
      selectedVehicleTypeID,
    } = this.props
    const {
      isHaveLHAddress
    } = this.state
    if (currentStep === 1) {
      const data = {
        locations_attributes: locations,
        time_type: timeType,
        vehicle_type_id: selectedVehicleTypeID
      }
      customerActions.calculateCustomerCashbackPercent(data, isHaveLHAddress)
    }
    if (currentStep === 2) {
      bookingActions.calculateCashback(null, prevProps.currentStep === currentStep)
    }
  }

  triggerValidateLocation() {
    const { locationActions } = this.props

    locationActions.validateLocations()
  }

  triggerGetTallyTransitTimeAction(changes, isChangeStep, prevStep) {
    const {
      currentStep,
      bookingActions,
      timeType,
    } = this.props

    const {
      isLocationChanged,
      isTransitTimeChanged,
    } = changes

    const { isValidLH } = this.state

    const params = {
      specificStep: currentStep,
      isLocationChanged,
      isValidLH,
      callback: () => { }
    }

    // for full-day we call separate api to recalculate transit time
    //    but other time type still use the same calculate api
    if ((isTransitTimeChanged || isChangeStep) && prevStep !== 3 && currentStep === 2 && timeType === FULL_DAY) {
      bookingActions.getTallyTransitTime(params)
    }
  }
  triggerGetTallyDataAction(changes = {}, callback) {
    const {
      currentStep,
      bookingActions,
      locations,
      selectedServiceTypeID,
      selectedVehicleTypeID,
      currentCustomer,
      timeType,
      area,
      bookingActionsCreator,
      extraInfos
    } = this.props
    if (currentStep === 2) return Promise.resolve(bookingActions.calculateExtraServicesPrice())
    const { isLocationChanged } = changes

    const params = {
      specificStep: currentStep,
      isLocationChanged,
    }
    const isSimpleData = true
    const { locationCount } = bookingUtils.calculateAndFormatLocationList(locations)
    if (locationCount === 0) return null
    const isEnableGoogleMap = mapUtils.isEnableGoogleMap(extraInfos)
    return Promise.resolve(LocationAPI.validLocations(
      locations,
      {
        timeType,
        serviceTypeID: selectedServiceTypeID,
        vehicleTypeID: selectedVehicleTypeID,
        isSimpleData,
      },
      {
        countryCode: area.country_code,
        companyId: currentCustomer.current_company_id,
        authenticationToken: currentCustomer.authentication_token,
        isLogin: currentCustomer.id,
        isEnableGoogleMap
      },
      null,
      (res) => {
        if (res?.status !== 400) {
          const isValidLH = res?.long_haul_address_valid && res?.long_haul_pickup?.is_valid
          this.setIsHaveLHAddress(!!isValidLH)
          const indexList = bookingUtils.getIndexListOfValidAddress(locations)
          const outsideList = res?.outside_list ?? []
          const extendedList = res?.extended_list ?? []
          const {
            formatOutSideList, formatExtendList, normalOSAList,
          } = bookingUtils.getOSAAndESAList(locations, indexList, outsideList, extendedList)
          let finalOSAList = formatOutSideList
          let finalExtendList = formatExtendList
          bookingActions.getTallyData({ ...params, isValidLH }).then(() => (callback && callback())).then(() => {
            if (timeType !== NOW) {
              finalOSAList = normalOSAList
              finalExtendList = []
            }
            bookingActionsCreator.updateOutSideList({ payload: finalOSAList })
            if (_.includes(DEFAULT_ACCEPTABLE_TIME_TYPES, timeType)) {
              this.setOutsideList(finalOSAList)
              this.setExtendedList(finalExtendList)
            }
          })
        }
      }
    ))
  }

  displayStep(step) {
    const { currentStep } = this.props
    if (step === currentStep) {
      return { display: 'block' }
    }
    return { display: 'none' }
  }

  handleAfterCloseEventNotificationPopup(isShow) {
    this.setState({ closedEventNotificationPopup: isShow })
  }

  calculateAfterSignIn() {
    const { bookingActions, currentStep } = this.props
    if (currentStep === 3) {
      bookingActions.calculate(() => { })
    }
  }

  handleClickOnStep(step, currentStep) {
    const paramStep = currentStep - step
    this.stepButton.prevStep(paramStep)
  }

  /**
   * To Handle Tabview on Step 1
   * @param {boolean} isShow value will show current view on step 1
   */
  handleViewStepOne(isShow) {
    const {
      locationActions,
      locations,
      roundTripDiscountActions,
      timeType,
      extraInfos
    } = this.props

    // Reset Data of Each View
    if (timeType === FULL_DAY && locations.length === 1) {
      locationActions.resetLocations()
      this.setState({ fullLoadView: isShow })
    } else {
      Promise.resolve(
        locationActions.resetOptimizeLocations()
      ).then(() => {
        roundTripDiscountActions.update(false)
        locationActions.resetLocations()
        this.setState({ fullLoadView: isShow })
      })
    }
    const isEnableGoogleMap = mapUtils.isEnableGoogleMap(extraInfos)
    locations.forEach((location) => {
      if (location.marker) {
        if (isEnableGoogleMap) {
          location.marker.setMap(null)
          window.google.maps.event.clearListeners(location.marker, 'dragend')
        } else {
          location.marker.remove();
        }
      }
    })
  }

  handleLTLPickUpTime(e, isNotMomentTime = false) {
    const { booking, extraInfos } = this.props
    const urlParams = new URLSearchParams(window.location.search)
    const isBookAgain = urlParams.get('book_again')
    const isDisabledFTL = _.get(booking, 'shipmentLTLDetail.shipmentDetail.paymentMethod', 0) !== 0 && !isBookAgain
    const {
      ltl_booking_end_time: endTime
    } = extraInfos
    const isSameDay = moment(e).isSame(new Date(), 'day')
    const splitEndTime = endTime.split(':')
    const closingTime = moment().set('hour', splitEndTime[0]).set('minute', splitEndTime[1])
    const isTimeGreaterClosing = moment().diff(closingTime) > 0
    if (isSameDay && isTimeGreaterClosing && !isNotMomentTime && !isDisabledFTL) {
      this.setState({ pickupTimeLTL: moment(e).add(1, 'days') })
    } else {
      this.setState({ pickupTimeLTL: moment(e) })
    }
  }

  /**
   * Handle event click for each handling unit
   * @param {string} id of handling unit
  */
  handleHandlingUnitValue(newItem) {
    this.setState({ handlingUnitValue: newItem })
  }

  /**
   * handle Submit Data on Partial Form
   */
  submitPartial() {
    const {
      locations, bookingActions, booking
    } = this.props
    const {
      handlingUnitValue, pickupTimeLTL, outsideList
    } = this.state
    const { configLTLData, shipmentLTLDetail } = booking

    if (outsideList.length > 0) return

    let defaultTransportType = ''
    let minUnitQuantity = ''

    const handleUnitValue = (handlingUnitValue && handlingUnitValue.key) || booking.handlingUnitData[0].key
    if (configLTLData) {
      defaultTransportType = configLTLData.find(item => item.key === LTL_CONSTANT.TRANSPORT_TYPE_DEFAUL).value
      minUnitQuantity = configLTLData.find(item => item.key === LTL_CONSTANT.MIN_UNIT_QUANTITY).value
    }

    const urlParams = new URLSearchParams(window.location.search)
    const transportFromUrl = urlParams.get('transport')
    defaultTransportType = transportFromUrl || defaultTransportType

    let itemFormat = {
      Items: [{
        HandlingUnitId: handleUnitValue,
        UnitQuantity: minUnitQuantity || 1,
      }],
      status: 1,
      TransportTypeId: shipmentLTLDetail ? shipmentLTLDetail.transportTypeId : defaultTransportType,
    }

    const isPtl = urlParams.get('ptl')
    const length = urlParams.get('length')
    const width = urlParams.get('width')
    const height = urlParams.get('height')
    const weight = urlParams.get('weight')

    if (isPtl === 'true' && length && width && height && weight) {
      itemFormat.Items[0] = {
        ...itemFormat.Items[0],
        length: Number(length),
        width: Number(width),
        height: Number(height),
        weight: Number(weight),
      }
    }

    // Phone Pickup
    const phoneNumber = locations[0]?.recipient_phone || ''
    const dialCode = phoneNumberUtils.getCallingCode(
      phoneNumber,
    )
    // Phone Destination
    const phoneNumber1 = locations[1]?.recipient_phone || ''
    const dialCode1 = phoneNumberUtils.getCallingCode(
      phoneNumber1,
    )

    const jsonAddressPickup = JSON.stringify(locations[0].address_components || [])
    const jsonAddressDes = JSON.stringify(locations[1].address_components || [])
    const addressFormat = {
      Pickup: {
        AddMe: !!((locations[0].recipient_name && locations[0].recipient_phone)),
        Address: locations[0].name,
        Location: { latitude: locations[0].lat, longitude: locations[0].lng, },
        PickupDate: (moment(pickupTimeLTL)).utc().format('YYYY-MM-DDTHH:mm:ss.SSSSZ'),
        ShipmentId: shipmentLTLDetail ? shipmentLTLDetail.id : null,
        contactName: locations[0].recipient_name,
        contactPhone: formatPhoneNumber(phoneNumber, dialCode),
        ShortAddress: locations[0].name.split(',')[0],
        ContactDialCode: dialCode,
        Note: locations[0].description,
        isPay: true,
        jsonAddress: jsonAddressPickup
      },
      Destinations: [
        {
          AddMe: !!((locations[1].recipient_name && locations[1].recipient_phone)),
          Address: locations[1].name,
          Location: { latitude: locations[1].lat, longitude: locations[1].lng, },
          ShipmentId: shipmentLTLDetail ? shipmentLTLDetail.id : null,
          contactName: locations[1].recipient_name,
          contactPhone: formatPhoneNumber(phoneNumber1, dialCode1),
          ShortAddress: locations[1].name.split(',')[0],
          ContactDialCode: dialCode1,
          Note: locations[1].description,
          isPay: false,
          jsonAddress: jsonAddressDes
        }
      ]
    }
    itemFormat.addresses = {
      ...addressFormat
    }
    if (SHIPMENT_STATUS.DRAFT !== shipmentLTLDetail?.status) {
      itemFormat.shipmentDetail = {
        requireSignature: this.bookingPtlRequireSignatures
      }
    }
    if (shipmentLTLDetail) {
      const { items, addresses } = shipmentLTLDetail
      const isBookAgain = urlParams.get('book_again')
      const newItems = _.cloneDeep(items)
      newItems[0].handlingUnitId = handleUnitValue
      itemFormat.Items = newItems
      if (SHIPMENT_STATUS.DRAFT === shipmentLTLDetail.status) {
        itemFormat = {
          ...itemFormat,
          id: shipmentLTLDetail.id,
          addresses: {
            ...addresses,
            pickup: {
              ...addresses.pickup,
              ...addressFormat.Pickup
            },
            destinations: [{
              ...addresses.destinations[0],
              ...addressFormat.Destinations[0]
            }]
          }
        }
        bookingActions.updateBookingForLTL(itemFormat, shipmentLTLDetail.id)
      } else if (isBookAgain) {
        bookingActions.createBookingForLTL(itemFormat)
      }
    } else {
      bookingActions.createBookingForLTL(itemFormat)
    }
  }

  isEnabledTally() {
    const {
      extraInfos,
      serviceTypes,
      selectedServiceTypeID,
      selectedVehicleTypeID,
    } = this.props

    const selectedServiceType = _.filter(serviceTypes, e => e.id === selectedServiceTypeID)[0] || {}
    const vehicleType = _.find(selectedServiceType.vehicle_types, item => (+item.id === +selectedVehicleTypeID))

    return TallyUtils.isEnabledTally({ extraInfos, vehicleType })
  }

  isShowedTallybar() {
    const {
      currentStep,
    } = this.props

    return this.isEnabledTally() && (currentStep === 1 || (!this.isShopping() && currentStep === 2))
  }

  isShopping() {
    const {
      serviceTypes,
      selectedServiceTypeID,
    } = this.props

    const defaultService = _.filter(serviceTypes, e => e.id === selectedServiceTypeID)[0] || {}

    return _.some(defaultService.vehicle_types, e => e.is_shopping === true)
  }

  closePicker(cancel, selectedDateTime) {
    const { bookingActions } = this.props || {}
    if (!cancel) {
      const { timeTypeActions } = this.props
      timeTypeActions.changePickupTime(selectedDateTime)
      timeTypeActions.showDatePicker(false)
      $('#loading-modal').addClass('visible')
      bookingActions.calculate(() => {
        this.stepButton.handleDebounce()
        $('#loading-modal').removeClass('visible')
      })
    }
  }

  handleShowChangePickupLocation(value) {
    this.setState({ isShowPopupChangePickupLocation: value })
  }

  handleErrorPickupLocation(value) {
    this.setState({ isErrorPickupLocation: value })
  }

  static renderAddNewBookingToBatch(fullLoadView) {
    const urlParams = new URLSearchParams(window.location.search)
    const batchId = urlParams.get('batch_id')
    if (!Number(batchId) || !fullLoadView) {
      return null
    }

    const urlDirectsBachToBatchManage = `/batches?batch_id=${batchId}`

    return (
      <div className="booking-edit-mode__container">
        <div className="booking-edit-mode_block">
          <div className="booking-edit-mode">
            {I18n.t('webapp.new_booking.booking_added_to_batch')}
            :
            {' '}
            <a href={urlDirectsBachToBatchManage} target="_blank" rel="noopener noreferrer" type="button">
              {batchId}
            </a>
          </div>
        </div>
      </div>
    )
  }

  renderCashBackInfo() {
    const {
      booking,
      extraInfos,
      currentStep
    } = this.props
    const displayPrice = Utils.formatFee(booking.cash_back_amount, extraInfos.country_language, booking.currency)

    if (currentStep !== 3 || !booking.cash_back_amount) return null

    return (
      <CashbackInfo cashbackAmount={displayPrice} />
    )
  }

  render() {
    const {
      area, currentStep, timeType, firstTimeDiscount, serviceTypes,
      selectedVehicleTypeID, selectedServiceTypeID,
      currentPopupID, isNewDriver, assignDriverActions, extraInfos,
      currentCustomer, dynamicTexts,
      locations, booking, autoSwitchLongHaul, showDatePicker, pickupTime,
      numberDrivers, vehicleInfos, bookingActions, subAccountTagPicked, isCheckedSubAccount,
      accountManageActionsProps, isGetSuccessDataEdit, isWaitingCalculate, setIsWaitingCalculate,
    } = this.props
    const {
      closedEventNotificationPopup,
      loadDriverPreferences,
      fullLoadView,
      pickupTimeLTL,
      handlingUnitValue,
      outsideList,
      errorDistanceList,
      isHaveLHAddress,
      isErrorPickupLocation,
      isShowPopupChangePickupLocation,
      extendedList,
      isShowChangeTimeType,
      isShowChangeTimeTypeClick,
      comingSoonServiceTypeName,
      isShowComingService,
      iconComingSoonService,
      isShowPickupTimePopup,
      initDatePickup,
      isWaitingSetData,
      frontPageMenu,
    } = this.state
    const showDriverPreference = (isNewDriver ? loadDriverPreferences : true) && currentStep === 2
    const isBusiness = !!currentCustomer?.last_login_employ_id
    const isShopping = this.isShopping()
    const defaultService = _.filter(serviceTypes, e => e.id === selectedServiceTypeID)?.[0] || {}
    const vehicleType = _.find(defaultService?.vehicle_types, { id: selectedVehicleTypeID })
    const tallyData = TallyUtils.calculateTallyData({
      locations,
      vehicleTypeID: selectedVehicleTypeID,
      timeType,
    }, { booking, specificStep: currentStep, vehicleType })
    const tallyBarVisibility = {
      price: true,
      distance: true,
      transitTime: true,
    }
    tallyBarVisibility.transitTime = !isShopping
    const isShowChangePopup = !_.isEmpty(extendedList) && timeType === NOW && isShowChangeTimeType
    const selectedServiceType = defaultService
    const selectedVehicleType = _.find(selectedServiceType?.vehicle_types, item => (+item.id === +selectedVehicleTypeID))
      || selectedServiceType?.vehicle_types?.[0]
    const isEnabledTally = this.isEnabledTally()

    const etaPopupData = this.getEtaPopupData()
    const etaNotesIconVisible = !!etaPopupData
    const numberOfDrivers = (numberDrivers && numberDrivers.number_of_drivers)
      || (vehicleInfos && vehicleInfos.number_of_drivers)
    const unitName = (!_.isEmpty(numberDrivers) && numberDrivers.unit)
      || (!_.isEmpty(vehicleInfos) && vehicleInfos.unit)
    const totalFees = (!currentCustomer.current_company_id && !booking.use_credit)
      ? booking.display_total_fees
      : booking.total_fees
    const showResponsiblePayment = totalFees > 0
    const isCeb = UtilsCommon.isCustomerEditBooking()
    const createdBy = _.get(booking, 'created_by', WEB_SINGLE)
    const isEditWebMultipleBooking = (createdBy === WEB_MULTIPLE && isCeb)
    // Config  BookingCalendar on step 3
    const brandIco = extraInfos.country_code === 'PH' ? 'transportify' : 'deliveree'
    const minMinutes = UtilsCommon.minuMumBufferMinutes(vehicleType, timeType, 0, true)
    /* eslint-disable react/no-danger */

    const curUrl = window.location.href
    const url = new URL(curUrl)
    const driverId = url.searchParams.get(FAV_DRIVER_ID)
    if (currentCustomer && _.isEmpty(currentCustomer.authentication_token)) {
      if (!_.isEmpty(driverId)) {
        window.localStorage.setItem(FAV_DRIVER_ID, driverId)
        accountManageActionsProps.updateModalAccountManage(SIGN_IN_MODAL)
      }
      window.localStorage.setItem(FAV_DRIVER_ID, '')
    } else if (!_.isEmpty(driverId)) {
      window.localStorage.setItem(FAV_DRIVER_ID, driverId)
    }

    const cashBackPercent = booking.cash_back_reward
    const urlParams = new URLSearchParams(window.location.search)
    const isHideFrontPage =
      urlParams.get('ftl') ||
      urlParams.get('ptl') ||
      urlParams.get('frontPage') ||
      urlParams.get('is_switch_area') ||
      urlParams.get('is_mkt') ||
      urlParams.get('location[0][name]') ||
      urlParams.get('location[1][name]') ||
      urlParams.get('mk_booking[locations]') ||
      urlParams.get('shipmentId') ||
      urlParams.get('batch_id') || 
      urlParams.get('skip') === 'fp'
      
    const frontPageBg = {
      backgroundImage: `url(${frontPageMenu.background_image || ''})`,
      backgroundSize: 'cover' 
    }

    const isShowFrontPage = !isHideFrontPage && extraInfos.turn_on_front_page && (window.location.pathname === '/' || window.location.pathname === '/bookings/new')
    const isEnableGoogleMap = mapUtils.isEnableGoogleMap(extraInfos)
    return (
      <div>
        <div className="Booking">
          <div className="SingleBookingLayout main-view" style={isShowFrontPage ? frontPageBg : {}}>
            {isShowFrontPage ? <FrontPage frontPageMenu={frontPageMenu} />
              : (<div id='step-wrapper' className="BookingWizard">
                {isCeb && (<TextEditMode />)}
                {NewBookingContainer.renderAddNewBookingToBatch(fullLoadView)}
                {showDatePicker && (<ColorBanner timeType={timeType} />)}
                <StepsHeader
                  configTitle={isShopping ? [
                    { step: 1, title: I18n.t('webapp.new_booking.step_1_header') },
                    { step: 2, title: I18n.t('webapp.new_booking.step_2_header_list') },
                    { step: 3, title: I18n.t('webapp.new_booking.step_3_header') },
                  ] : []}
                  currentStep={currentStep}
                  onClick={this.handleClickOnStep}
                />
                <div ref={this.refStep3Container} className="Content ContentUnsetPosition">
                  <div id="Step-01" style={this.displayStep(1)}>
                    <StepOneContent
                      errorDistanceList={errorDistanceList}
                      setListErrorDistance={this.setListErrorDistance}
                      isShopping={isShopping}
                      defaultValue={defaultService}
                      myShopping={this.myShopping}
                      area={area}
                      currentStep={currentStep}
                      currentCustomer={currentCustomer}
                      booking={booking}
                      handleViewStepOne={this.handleViewStepOne}
                      fullLoadView={fullLoadView}
                      handlingUnitValue={handlingUnitValue}
                      pickupTimeLTL={pickupTimeLTL}
                      handleLTLPickUpTime={this.handleLTLPickUpTime}
                      handleHandlingUnitValue={this.handleHandlingUnitValue}
                      isEditWebMultipleBooking={isEditWebMultipleBooking}
                      outsideList={outsideList}
                      setOutsideList={this.setOutsideList}
                      countryCode={extraInfos.country_code}
                      extraInfos={extraInfos}
                      isHaveLHAddress={isHaveLHAddress}
                      isShowPopupChangePickupLocation={isShowPopupChangePickupLocation}
                      handleErrorPickupLocation={this.handleErrorPickupLocation}
                      handleShowChangePickupLocation={this.handleShowChangePickupLocation}
                      setIsShowChangeTimeTypeClick={this.setIsShowChangeTimeTypeClick}
                      isShowChangeTimeTypeClick={isShowChangeTimeTypeClick}
                      extendedList={extendedList}
                      setComingSoonServiceTypeName={this.setComingSoonServiceTypeName}
                      setShowComingSoonService={this.setShowComingSoonService}
                      setIconComingSoonService={this.setIconComingSoonService}
                      setIsShowPickupTimePopup={this.setIsShowPickupTimePopup}
                      initDatePickup={initDatePickup}
                      isEnableGoogleMap={isEnableGoogleMap}
                    />
                  </div>
                  {
                    !isShopping && (
                      <div id="Step-02" style={this.displayStep(2)}>
                        {showDriverPreference && (
                          <DriverPreferences
                            area={area}
                            selectedVehicleTypeID={selectedVehicleTypeID}
                            refNode={this.driverPreferencesPopup}
                          />
                        )
                        }
                        <ExtraRequirementForFullDay />
                        <MoreGroupExtraServices
                          expanded={false}
                          content={() => (
                            <div className="flex flex-start White-bg Radius-Top-Default pt20 pr15 pb20 pl15" key="dynamic-texts">
                              {!!currentCustomer.current_company_id && <img src={BP_ACCOUNT} alt="Business account" width="35" className="mr10" />}
                              {dynamicTexts && <div className="default-smaller-font default-smaller-font-color italic" dangerouslySetInnerHTML={{ __html: dynamicTexts.show_more_text }} />}
                            </div>
                          )}
                          isBusiness={isBusiness}
                          extraRender={() => [
                            <ContainerSize key="containerSize-options" />,
                            <CODAndPOD key="cod-pod-options" />,
                          ]}
                        >
                          <Badge />
                          <BadgeForCompany />
                          {currentStep === 2 && currentCustomer.id !== 0
                            && ([
                              <Reimbursements key="reimbursement" />,
                              <ReimbursementPopups key="reimbursementPopup" />,
                              <CustomReimbursementPopups key="customReimbursementPopup" />
                            ])
                          }
                          <div className="mt15 pb15">
                            {UtilsHTML.renderOptionTitle(I18n.t('webapp.new_booking.step_2.notes_and_attachments_title'))}
                          </div>
                          <Note />
                          <JobOrderNumber />
                          {currentStep === 2 && <Attachment
                            isLoadedAttachment={this.isLoadedAttachment}
                            setIsLoadedAttachment={(isLoadedAttachment) => { this.isLoadedAttachment.current = isLoadedAttachment }}
                          />}
                        </MoreGroupExtraServices>
                      </div>
                    )
                  }
                  {currentStep === 3
                    && (
                      <div id="Step-03" style={this.displayStep(3)}>
                        {(this.isEnabledTally() && !isShopping) && (
                          <TallySummary
                            serviceType={selectedServiceType}
                            vehicleType={selectedVehicleType}
                            timeType={timeType}
                            pickupTime={+booking.pickup_time * 1000}
                            distance={booking.total_distance}
                            transitTime={booking.transit_time}
                            worstTransitTime={booking.worst_transit_time}
                            countryCode={extraInfos.country_code}
                            etaNotesIconVisible={etaNotesIconVisible}
                          />
                        )}
                        <Summary
                          isShopping={isShopping}
                          currentCustomer={currentCustomer}
                          isBusiness={isBusiness}
                          refStep3Container={this.refStep3Container}
                          setIsWaitingCalculate={setIsWaitingCalculate}
                          isWaitingCalculate={isWaitingCalculate}
                        />
                        {locations.length && (
                          <LocationContacts
                          showResponsibleForPayment={showResponsiblePayment}
                          isDisabledContact={isShopping}
                          currentCustomer={currentCustomer}
                          isBusiness={isBusiness}
                          requireSignaturesComponent={!isShopping ? (
                            <RequireSignatures
                              fieldId="booking[require_signatures]"
                              fieldName="booking_require_signatures"
                            />
                          ) : (
                            <div className="Require-Sinatures reset pl pr">
                              <span className="flex-index small-font default-smaller-font-color">
                                {I18n.t('webapp.shopping.contactless_no_signature_required')}
                              </span>
                            </div>
                          )}
                        />
                        )}
                        {(!isShopping && !_.isEmpty(currentCustomer.employs)) ? (
                          <SwitchAccount
                            currentCustomer={currentCustomer}
                            subAccountTagPicked={subAccountTagPicked}
                            isCheckedSubAccount={isCheckedSubAccount}
                          />
                        ) : null}

                        {showDatePicker
                          && (
                            <BookingCalendar
                              isShowOkBtn
                              brandIcon={brandIco}
                              selectedDateTime={pickupTime}
                              minMinutes={minMinutes}
                              closePicker={this.closePicker}
                              maximumPickupDays={extraInfos.maximum_pickup_time_days}
                              modalCssClassName="PickupTime-DatePicker-Custom"
                              countryCode={extraInfos.country_code}
                              isShowCancelBtn={false}
                            />
                          )
                        }
                      </div>
                    )
                  }
                </div>
                {this.renderCashBackInfo()}
                {
                  (currentStep !== 3 && fullLoadView) && (
                    <CashBackBar
                      tallyData={tallyData}
                      tallyBarVisibility={tallyBarVisibility}
                      isShopping={isShopping}
                      showTallybar={this.isShowedTallybar() && fullLoadView}
                      timeType={timeType}
                      isSingle
                      currentStep={currentStep}
                    />
                  )
                }
                <StepButton
                  isShopping={isShopping}
                  onRef={(ref) => { this.stepButton = ref }}
                  myShopping={this.myShopping}
                  fullLoadView={fullLoadView}
                  submitPartial={this.submitPartial}
                  outsideList={outsideList}
                  errorDistanceList={errorDistanceList}
                  isErrorPickupLocation={isErrorPickupLocation}
                  handleShowChangePickupLocation={this.handleShowChangePickupLocation}
                  extendedList={extendedList}
                  setIsShowChangeTimeType={this.setIsShowChangeTimeType}
                  setShowComingSoonService={this.setShowComingSoonService}
                  setIconComingSoonService={this.setIconComingSoonService}
                  isWaitingSetData={isWaitingSetData}
                  isWaitingCalculate={isWaitingCalculate}
                />
                <CODAndPODPopover area={area} />
                {isShowComingService && (
                  <ServiceTypeComingSoonPopup
                    comingSoonServiceTypeName={comingSoonServiceTypeName}
                    countryCode={extraInfos.country_code}
                    iconComingSoonService={iconComingSoonService}
                    setShowComingSoonService={this.setShowComingSoonService}
                  />
                )}
                <SelectionPerLocationPopover />
                <VehicleTypePopups />
                <ExtraRequirementPopups />
                <OutServiceAreaPopup />
                <OutServiceLTLPopup
                  fullLoadView={fullLoadView}
                  isOutServiceLTL={booking.isOutServiceLTL}
                  changeStatusOutService={bookingActions.updateOutOfServicePartialLoad}
                />
                {
                  isEnabledTally && (
                    <EstimatedTransitTimePopup
                      currentCustomer={currentCustomer}
                      areaId={area.id}
                      timeType={timeType}
                      popupIdPrefix="single-booking"
                      popupData={etaPopupData}
                    />
                  )
                }
                {isShowPickupTimePopup
                  && (
                    <PickupTimePopup
                      setIsShowPickupTimePopup={this.setIsShowPickupTimePopup}
                    />
                  )}
                {autoSwitchLongHaul && showDatePicker && <LongHaulInvalidDatePopup />}
                <PickupTimeWarningPopup
                  popupIdPrefix="single-booking"
                />
                {isShowChangePopup && (
                  <ChangeTimeTypePopup
                    setIsShowChangeTimeTypeClick={this.setIsShowChangeTimeTypeClick}
                    setIsShowChangeTimeType={this.setIsShowChangeTimeType}
                    handleCancelChangeTimeTypePopup={this.handleCancelChangeTimeTypePopup}
                  />
                )}
                {closedEventNotificationPopup && (<FirstTimeDiscountPopup firstTimeDiscount={firstTimeDiscount} />)}
                <div id="BookingPopupEye" />
                <div id="CommonPopup" />
                <div id="DefaultPopup" className="DynamicPopupWrapper" />
                <div id="DimensionPopupEye" className="DynamicPopupWrapper" />
                <div
                  id="DimensionPopupDefault"
                  className="DynamicPopupWrapper"
                  ref={this.driverPreferencesPopup}
                />
                {currentPopupID === POPUP_ID_CHANGE_INFO_AFTER_ASSIGNED_DRIVER && (
                  <AssignDriverChangeBookingDetailsPopup
                    assignDriverActions={assignDriverActions}
                  />
                )
                }
                {currentPopupID === POPUP_CASHBACK_EXPLAIN && (
                  <CashBackExplain
                    cashBackPercent={cashBackPercent}
                  />
                )}
              </div>)
            }
            {!isShowFrontPage
              && (
              <Map
                area={area}
                serviceTypes={serviceTypes}
                selectedVehicleTypeID={selectedVehicleTypeID}
                selectedServiceTypeID={selectedServiceTypeID}
                searchNearby={isShopping && !isBusiness}
                fullLoadView={fullLoadView}
                isEnableGoogleMap={isEnableGoogleMap}
              />)}
            {currentPopupID === POPUP_ID_YOUR_RECENT_DRIVERS && (
              <RecentDriversPopup />)
            }
            {/* always show MiniTooltip not depend on setting field extraInfos.turn_on_front_page */}
            <MiniTooltip />
            {
              numberOfDrivers > 0
              && fullLoadView
              && <NumberDrivers numberOfOnlineDrivers={numberOfDrivers} unitNameOfVehicleType={unitName} />
            }
          </div>
          <div id="CebChangeConfirmedPopup" />
        </div>
        <EventNotificationPopup
          area={area}
          actionWhenCloseEventNotificationPopup={isShow => this.handleAfterCloseEventNotificationPopup(isShow)}
        />
        {!isShowFrontPage && isEnableGoogleMap && <ShowRoute area={area} extraInfos={extraInfos} fullLoadView={fullLoadView} />}
        <div id="PopupExistAccount" className="DynamicPopupWrapper" />
        {/* <MyShopping onRef={(ref) => { this.myShopping = ref }} /> */}
      </div>
    )
  }
}

NewBookingContainer.defaultProps = {
  currentPopupID: null,
  bookAgainDetails: null,
  selectedVehicleTypeID: null,
  firstTimeDiscount: null,
  selectedServiceTypeID: null,
  isNewDriver: false,
  currentCustomer: undefined,
  dynamicTexts: undefined,
  timeType: undefined,
  locations: [],
  booking: {},
  extraServices: {},
  autoSwitchLongHaul: false,
  showDatePicker: false,
  popupContentManagement: [],
  roundTripDiscountActions: {},
  subAccountTagPicked: {},
  isCheckedSubAccount: false,
  isLoginStep3: false,
  isWaitingCalculate: false,
  setIsWaitingCalculate: () => {},
}

NewBookingContainer.propTypes = {
  currentStep: PropTypes.number.isRequired,
  serviceTypes: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  extraInfos: PropTypes.shape({
    ltl_booking_end_time: PropTypes.string,
    enable_same_day_ptl_booking: PropTypes.bool,
    area_id: PropTypes.number
  }).isRequired,
  area: PropTypes.shape({}).isRequired,
  extraServiceActions: PropTypes.shape({}).isRequired,
  locationActions: PropTypes.shape({}).isRequired,
  assignDriverActions: PropTypes.shape({}).isRequired,
  authenticationActions: PropTypes.shape({}),
  bookingActions: PropTypes.shape({}).isRequired,
  customerActions: PropTypes.shape({}).isRequired,
  checkLocationActions: PropTypes.shape({}).isRequired,
  isNewDriver: PropTypes.bool,
  currentPopupID: PropTypes.string,
  bookAgainDetails: PropTypes.shape({}),
  selectedVehicleTypeID: PropTypes.number,
  selectedServiceTypeID: PropTypes.number,
  firstTimeDiscount: PropTypes.shape({}),
  currentCustomer: PropTypes.shape({}),
  dynamicTexts: PropTypes.shape({}),
  timeType: PropTypes.string,
  locations: PropTypes.arrayOf(PropTypes.object),
  booking: PropTypes.shape({}),
  extraServices: PropTypes.shape({}),
  autoSwitchLongHaul: PropTypes.bool,
  showDatePicker: PropTypes.bool,
  popupActions: PropTypes.shape({}).isRequired,
  popupContentManagement: PropTypes.arrayOf(PropTypes.object),
  documentActions: PropTypes.shape({}).isRequired,
  numberDrivers: PropTypes.shape({}),
  pickupTime: PropTypes.string.isRequired,
  timeTypeActions: PropTypes.shape({}).isRequired,
  vehicleInfos: PropTypes.shape({}),
  roundTripDiscountActions: PropTypes.shape({}),
  subAccountTagPicked: PropTypes.shape({}),
  isCheckedSubAccount: PropTypes.bool,
  serviceTypeActions: PropTypes.shape({}).isRequired,
  isLoginStep3: PropTypes.bool,
  isWaitingCalculate: PropTypes.bool,
  setIsWaitingCalculate: PropTypes.func,
}


export default connect(mapStateToProps, mapDispatchToProps)(withNavigate(NewBookingContainer))
