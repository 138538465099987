import moment from 'moment'

// UTILS
import phoneNumberUtils from '../common/phoneNumber'
import { correctTransiTime } from './common'
import I18n from 'i18n/i18n'

// CONSTANTS
import {
  LIMIT_CONVERT_TO_M3,
  LIMIT_CONVERT_TO_TON,
  ONE_M3_CM3,
  ONE_TON_KG,
  LIMIT_CONVERT_TO_M,
  ONE_M_CM,
  GOOGLE_REQUEST_PER_CALL,
  DELAY_GEOCODING
} from 'constants/smartPlannerConstants'
import Utils from 'utils/Utils'
import _ from 'lodash'
import locationUtils from '../common/location'

/* eslint-disable import/prefer-default-export */
const combileAddressImport = (excelRow) => {
  if (_.isEmpty(excelRow)) {
    return undefined
  }

  const arrAddress = []
  if (((excelRow?.address_1 && excelRow?.address_2)
    && excelRow?.address_1?.includes(excelRow?.address_2)
    && excelRow?.address_1?.length > excelRow?.address_2?.length)
    || (excelRow?.address_1 && !excelRow?.address_2)
  ) {
    arrAddress.push(_.trim(excelRow.address_1))
  } else {
    arrAddress.push(_.trim(excelRow.address_2))
  }
  if (excelRow.district) {
    arrAddress.push(_.trim(excelRow.district))
  }
  if (excelRow.city) {
    arrAddress.push(_.trim(excelRow.city))
  }
  if (excelRow.state_province) {
    arrAddress.push(_.trim(excelRow.state_province))
  }
  const textCompact = _.compact(arrAddress)
  const textCombile = _.join(textCompact, ', ')
  return _.replace(textCombile, ',', ' ')
}

export const generateMapping = (template) => {
  const { customer_default_mapping: defaultMapping, mapping } = template
  const locationMapping = { ...defaultMapping }

  Object.entries(mapping).forEach(([key, value]) => {
    if (value) {
      locationMapping[key] = value
    }
  })

  return locationMapping
}

const buildLocationObject = ({
  locationExel,
  rowIndex,
  template,
}) => {
  let location = _.cloneDeep(locationExel)

  if (template) {
    const mapping = generateMapping(template)
    location = {
      tmp_id: [Math.random().toString(36).slice(2), rowIndex].join('-'),
      item_length: _.trim(locationExel[mapping.item_length]),
      item_width: _.trim(locationExel[mapping.item_width]),
      item_height: _.trim(locationExel[mapping.item_height]),
      item_weight: _.trim(locationExel[mapping.item_weight]),
      item_quantity: _.trim(locationExel[mapping.item_quantity]),
      address_1: _.trim(locationExel[mapping.address_1]),
      address_2: _.trim(locationExel[mapping.address_2] || ''),
      district: _.trim(locationExel[mapping.district] || ''),
      city: _.trim(locationExel[mapping.city] || ''),
      latitude: _.trim(locationExel[mapping.latitude]),
      longitude: _.trim(locationExel[mapping.longitude]),
      job_order_number: _.trim(locationExel[mapping.job_order_number]),
      name: _.trim(locationExel[mapping.name]),
      phone: _.trim(locationExel[mapping.phone]),
      postcode: _.trim(locationExel[mapping.postcode]),
      state_province: _.trim(locationExel[mapping.state_province]),
      location_note: _.trim(locationExel[mapping.location_note])
    }
  }

  const address = combileAddressImport(location)
  if (location.latitude) {
    return new Promise((resolve) => {
      locationUtils.handleCallGeocodeAPI({ latlng: `${location.latitude},${location.longitude}` }, (results) => {
        if (!_.isEmpty(results)) {
          const addressResult = results.find(item => item?.rawResponse?.types?.includes('street_address'))?.rawResponse || results[0].rawResponse
          if (!_.isEmpty(addressResult)) {
            location = {
              ...location,
              address_1: addressResult.formatted_address,
              address_2: '',
              district: '',
              city: '',
              state_province: ''
            }
          }
          return resolve(location)
        }
        return resolve(false)
      })
    })
  }
  return new Promise((resolve) => {
    locationUtils.handleCallGeocodeAPI({ address }, (results) => {
      if (!_.isEmpty(results)) {
        const lat = results[0].rawResponse.geometry.location.lat
        const lng = results[0].rawResponse.geometry.location.lng
        location = {
          ...location,
          latitude: lat,
          longitude: lng
        }
        return resolve(location)
      } return resolve(false)
    })
  })
}

const getValidLocations = ({
  locationsExel,
  template,
  totalIdx,
  validLocation = [],
  chunkIdx = 0,
  countryCode
}) => {
  const rowToBuild = locationsExel[chunkIdx] || locationsExel
  const promises = _.map(rowToBuild, (location, idx) => buildLocationObject({
    locationExel: location,
    rowIndex: idx,
    template,
    countryCode
  }))
  return Promise.all(promises)
    .then((locations) => {
      const results = [..._.cloneDeep(validLocation), ...locations]

      const nextIdx = chunkIdx + 1
      if (nextIdx < totalIdx) {
        return Utils.delay(DELAY_GEOCODING).then(() => getValidLocations({
          locationsExel,
          template,
          totalIdx,
          validLocation: results,
          chunkIdx: nextIdx,
          countryCode
        }))
      }

      return results
    })
}

export const buildLocationParams = (exelRows, template, countryCode) => {
  const chunkLocations = _.reduce(_.drop(exelRows), (rows, key, index) => (
    (
      !(index % GOOGLE_REQUEST_PER_CALL) ? rows.push([key]) : rows[rows.length - 1].push(key)
    ) && rows
  ), [])
  return getValidLocations({
    locationsExel: chunkLocations,
    template,
    totalIdx: chunkLocations.length,
    countryCode
  })
}

export const buildPickupParams = (pickupContact) => {
  if (_.isEmpty(pickupContact)) return {}

  const { address, latitude, longitude } = pickupContact
  const addressArr = address.split(',')

  return {
    address_1: addressArr[0],
    address_2: addressArr[1],
    district: addressArr[2],
    city: addressArr[3],
    latitude,
    longitude
  }
}

export const convertToLocationObject = (locations) => {
  let locationObj = {}
  _.forEach(locations, (location) => {
    locationObj = {
      ...locationObj,
      [location.tmp_id]: location
    }
  })

  return locationObj
}

export const buildLocationAddress = (location, excelRow) => {
  const locationTemp = location
  locationTemp.name = combileAddressImport(excelRow)
  locationTemp.lat = excelRow.latitude
  locationTemp.lng = excelRow.longitude
  return locationTemp
}

const buildLocationContact = (location, excelRow, countryCode) => {
  const result = phoneNumberUtils.formatPhoneNumber(
    excelRow.phone,
    countryCode,
    true
  )
  const locationTemp = location
  locationTemp.recipient_name = excelRow.name
  locationTemp.recipient_phone = result.phoneNumber || ''
  locationTemp.location_note = excelRow.location_note
  return locationTemp
}

export const matrixRowToLocationObject = (locationParams, countryCode, bkIndex) => {
  const {
    excelRow,
    rowIndex,
    newLocation,
    currentCustomer
  } = locationParams

  if (_.isEmpty(excelRow)) {
    return {}
  }

  let location = { ...newLocation }
  location.order = rowIndex
  location.temp_id = excelRow.tmp_id
  location.consolidates = bkIndex
  location = buildLocationAddress(location, excelRow)
  location = buildLocationContact(location, excelRow, countryCode)
  location = { ...location, items: excelRow.items }
  location = { ...location, can_toggle_need_pod: currentCustomer.can_toggle_pod }
  return location
}

const round = (value, precision) => {
  const multiplier = 10 ** (precision || 0)
  return Math.round(value * multiplier) / multiplier
}

const convertToCubicMeter = (value, isPrecision = true) => (
  isPrecision ? round(value / ONE_M3_CM3, 1) : (value / ONE_M3_CM3)
)

export const convertSizeUnit = (totalSize) => {
  if (totalSize > LIMIT_CONVERT_TO_M3) {
    return `${convertToCubicMeter(totalSize)} ${I18n.t('batches.label.m_3')}`
  }

  return `${totalSize} ${I18n.t('batches.label.cm_3')}`
}

export const convertDimensionUnit = (size) => {
  if (size > LIMIT_CONVERT_TO_M) {
    return `${round(size / ONE_M_CM, 1)} ${I18n.t('batches.label.meter')}`
  }

  return `${size} ${I18n.t('batches.label.centimeter')}`
}

export const convertWeightUnit = (totalWeight) => {
  if (totalWeight > LIMIT_CONVERT_TO_TON) {
    return `${round(totalWeight / ONE_TON_KG, 1)} ${I18n.t('batches.label.tons')}`
  }

  return `${totalWeight} ${I18n.t('batches.label.kg')}`
}

export const calculateCargoUtilised = (totalSize, cargoVolume) => (
  cargoVolume ? round((convertToCubicMeter(totalSize, false) / cargoVolume) * 100) : 0
)

export const calculateVolume = ({
  length,
  width,
  height
}) => ((length && width && height) ? length * width * height : 0)

export const groupDataBy = (data = [], keys = []) => {
  const dataGroup = data.reduce((acc, cur) => {
    let curKey = ''
    keys.forEach((key) => {
      if (cur[key]) {
        curKey += `${typeof cur[key] === 'string' ? cur[key].toLowerCase().trim() : cur[key]},`
      }
    })
    const tempResult = _.cloneDeep(acc)
    if (tempResult[curKey]) {
      tempResult[curKey].push(cur)
    } else {
      tempResult[curKey] = [cur]
    }
    return tempResult
  }, {})
  return dataGroup
}

export const generateCargoInfoForBooking = (locations, vehicleType = {}) => {
  let totalSize = 0
  let totalItems = 0
  let totalWeight = 0
  const { cargo_cubic_meter: cargoVolume } = vehicleType

  const locationItems = _.compact(_.flatten(_.map(locations, ({ items }) => items)))
  const infoItems = locationItems.length ? locationItems : locations

  _.forEach(infoItems, ({
    item_length: itemLength,
    item_width: itemWidth,
    item_height: itemHeight,
    item_quantity: itemQuanity,
    item_weight: itemWeight
  }) => {
    const bookingVolume = calculateVolume({
      length: itemLength,
      width: itemWidth,
      height: itemHeight
    })

    if (itemQuanity) {
      if (itemWeight) {
        totalWeight += (itemWeight * itemQuanity)
      }

      totalSize += bookingVolume * itemQuanity
      totalItems += itemQuanity
    }
  })

  const keyGroup = ['lat', 'lng', 'recipient_name', 'recipient_phone']
  const locationsGroup = groupDataBy(locations, keyGroup)

  return {
    totalDropoff: Object.values(locationsGroup).length,
    totalItems,
    totalSize: convertSizeUnit(totalSize),
    totalWeight: convertWeightUnit(totalWeight),
    cargoUtilised: calculateCargoUtilised(totalSize, cargoVolume),
    totalWeightWithoutConverted: totalWeight,
    totalSizeWithoutConverted: totalSize
  }
}

export const generateCargoInfoForAllBookings = (optimizedLocations) => {
  const totalLocations = _.flatten(optimizedLocations)
  return generateCargoInfoForBooking(totalLocations)
}

export const generateEstTimeForBooking = ({ pickupTime, transitTime, countryCode }) => {
  const estTime = moment(pickupTime).add(transitTime, 's')

  return Utils.formatDateTime(moment(estTime), countryCode)
}

export const generateEstTimeForAllBookings = (bookings = []) => {
  if (bookings.length) {
    let longestEstTime = 0

    _.forEach(bookings, (booking, index) => {
      const transitTime = correctTransiTime(booking.step_plan_transit_time)
      const estBookingTime = moment(booking.pickup_date_time).add(transitTime, 's')

      if (!index) {
        longestEstTime = moment(booking.pickup_date_time)
      }

      if (moment(estBookingTime).isAfter(longestEstTime)) {
        longestEstTime = estBookingTime
      }
    })

    return longestEstTime.format()
  }

  return ''
}

export const getLocationItem = location => ({
  item_length: location.item_length,
  item_width: location.item_width,
  item_height: location.item_height,
  item_weight: location.item_weight,
  item_quantity: location.item_quantity,
  tmp_id: location.tmp_id || location.temp_id
})

export const generateItemsKey = (obj) => {
  const tempObj = _.clone(obj)
  const location = _.omit(tempObj, [
    'item_width',
    'item_length',
    'item_height',
    'item_weight',
    'item_quantity'
  ])

  if (location.items) {
    return location
  }

  const item = getLocationItem(obj)

  return {
    ...location,
    items: [
      getLocationItem(item)
    ]
  }
}

const formatPhoneNumber = (phone, countryCode) => {
  const result = phoneNumberUtils.formatPhoneNumber(
    phone,
    countryCode,
    true,
    true
  )

  return _.get(result, 'phoneNumber', '')
}

const groupByLatLng = locations => (
  _.reduce(
    locations,
    (result, location) => {
      const lat = location.latitude || location.lat
      const lng = location.longitude || location.lng
      const key = `${lat}, ${lng}`
      const tempResult = _.clone(result)

      if (tempResult[key]) {
        tempResult[key].push(location)
      } else {
        tempResult[key] = [location]
      }

      return tempResult
    },
    {}
  )
)

const generateCombinedLocation = (locations, loadFromDraft, countryCode) => (
  _.reduce(
    locations,
    (result, location) => {
      const items = result.items || []
      const id = location.tmp_id || location.temp_id

      items.push({
        item_length: _.get(location, 'items[0].item_length', 0),
        item_width: _.get(location, 'items[0].item_width', 0),
        item_height: _.get(location, 'items[0].item_height', 0),
        item_weight: _.get(location, 'items[0].item_weight', 0),
        item_quantity: _.get(location, 'items[0].item_quantity', 0),
        tmp_id: id,
        name: loadFromDraft ? location.recipient_name : location.name,
        phone: formatPhoneNumber(loadFromDraft ? location.recipient_phone : location.phone, countryCode),
        job_order_number: location.job_order_number,
      })

      return {
        ...location,
        items
      }
    },
    {}
  )
)

export const setOrderLocations = (locations) => {
  let anchorIdx = 0

  return _.map(locations, (location) => {
    const mergeItems = []

    const groupItemsByPIC = groupDataBy(location.items, ['name', 'phone'])

    Object.values(groupItemsByPIC).forEach((items) => {
      anchorIdx += 1
      mergeItems.push(...items.map(item => ({
        ...item,
        order: anchorIdx
      })))
    })

    return {
      ...location,
      items: mergeItems
    }
  })
}

export const combineSameLocations = ({
  locations,
  loadFromDraft = false,
  countryCode
}) => {
  const sameLocations = {}
  const locationsGroupedByLatLng = groupByLatLng(locations)
  const orderId = _.map(locations, location => location.tmp_id || location.temp_id)

  _.forEach(Object.values(locationsGroupedByLatLng), (locationsByLatLng) => {
    if (locationsByLatLng.length === 1) {
      const location = locationsByLatLng[0]
      const id = location.tmp_id || location.temp_id

      if (location.items) {
        const phone = loadFromDraft ? location.recipient_phone : location.phone
        sameLocations[id] = {
          ...location,
          items: [{
            ...location.items[0],
            name: loadFromDraft ? location.recipient_name : location.name,
            phone: formatPhoneNumber(phone, countryCode, true),
            job_order_number: location.job_order_number,
          }]
        }
      } else {
        sameLocations[id] = { ...location }
      }
    } else {
      const combinedLocation = generateCombinedLocation(locationsByLatLng, loadFromDraft, countryCode)
      sameLocations[combinedLocation.tmp_id || combinedLocation.temp_id] = combinedLocation
    }
  })

  const combinedLocations = _.compact(_.map(orderId, id => sameLocations[id]))

  return setOrderLocations(combinedLocations)
}
