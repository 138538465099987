import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import { useDispatch, useSelector } from 'react-redux'

// ACTIONS

// COMPONENTS
import PopupEyeArea from '../common/popup/area_popup_eye/PopupEyeArea'
import CustomerDNDCheckbox from '../common/CustomerDNDCheckbox'
import BusinessAccountsList from './BusinessAccountsList'

// Utils
import { Utils } from 'utils/Utils'
import I18n from 'i18n/i18n'

// ASSETS
import {
  ARROW_UP_RIGHT,
  BUSINESS_DEFAULT_LOGO,
  IMAGE_PLACE_HOLDER
} from 'constants/imageConstants'
import _ from 'lodash'
import { currentCustomerActionsCreator } from 'store/toolkit/currentCustomer/currentCustomer.reducer'
import { updateCustomerSetting } from 'store/actions/userProfileActionCreators'
import { COUNTRY_ID, COUNTRY_PH, COUNTRY_TH } from 'constants/dashBoardAnalyticsConstants'

const UserProfile = (props) => {
  const dispatch = useDispatch()
  const {
    currentCustomer, setIsShowEdit
  } = props
  const extraInfos = useSelector(state => state.extraInfos)
  const referenceCode = _.get(currentCustomer, 'referral_code')
  const isRenderBusinessAccount = !_.isEmpty(currentCustomer)
  const isCompany = _.get(currentCustomer, 'last_login_employ_id')
  const { company } = _.isEmpty(currentCustomer) ? {} : Utils.getCompanyAccount(currentCustomer)
  const companyName = _.get(company, 'name', '')
  const customerName = _.get(currentCustomer, 'name', '')

  const closeModal = () => {
    setIsShowEdit(false)
    dispatch(currentCustomerActionsCreator.toggleProfilePanel(false))
  }

  const onChangeSettings = (checked, key) => {
    dispatch(updateCustomerSetting({ checked, key }))
  }

  if (_.isEmpty(currentCustomer)) return null

  const RenderItemCard = ({ title, onClick }) => {
    return (
        <div className="Box-List-Item RightMenu-referral" onClick={onClick}>
          <div className="RightMenu-referral--code">
            {title}
          </div>
          <span>
            <img src={ARROW_UP_RIGHT} alt="icon-question" />
          </span>
        </div>
    )
  }

  const RenderContentTermsConditions = ({ extraInfos, currentCustomer }) => {
    const { country_code, tc_link: { bp: bp_link, non_bp: non_bp_link }, privacy_policy_link = '' } = extraInfos;
    const companyId = currentCustomer.current_company_id || currentCustomer.last_login_employ_id
    const isBp = !!companyId;
    let termsOfUseText = '';
    let dataPrivacyText = '';

    const handleClick = (link) => {
      window.open(link, '_blank');
    };

    switch (country_code) {
        case COUNTRY_PH:
            termsOfUseText = isBp ? I18n.t('webapp.label.business_account_terms_of_use') : I18n.t('webapp.label.ordinary_account_terms_of_use');
            dataPrivacyText = I18n.t('webapp.label.data_privacy_policy');
            break;
        case COUNTRY_TH:
            termsOfUseText = I18n.t('webapp.label.terms_of_use');
            dataPrivacyText = I18n.t('webapp.label.data_privacy_policy');
            break;
        default:
            termsOfUseText = I18n.t('webapp.label.terms_of_use');
            dataPrivacyText = I18n.t('webapp.label.data_privacy_and_security');
            break;
    }

    return (
        <>
            <RenderItemCard title={termsOfUseText} onClick={() => handleClick(isBp ? bp_link : non_bp_link)} />
            <RenderItemCard title={dataPrivacyText} onClick={() => handleClick(privacy_policy_link)} />
        </>
    );
  }

  return (
    <div className="user-profile default RightMenu">
      <div className="RightMenu-Box">
        <div
          onClick={closeModal}
          className="Box-Head-Close"
          role="presentation"
        >
          <i className="material-icons">
            close
          </i>
        </div>
        <div className="Box-Head">
          <div className="RightMenu-avatar-block">
            <div className="rounded Avatar">
              <img
                alt={companyName}
                onError={(e) => {
                  e.target.src = isCompany ? BUSINESS_DEFAULT_LOGO : IMAGE_PLACE_HOLDER
                }}
                src={isCompany ? _.get(company, 'photo_url') : currentCustomer.avatar_square}
              />
            </div>
            <div className="TitleSubtitle">
              <div className="TitleSubtitle-title">
                {isCompany ? companyName : customerName}
              </div>
              <div className="TitleSubtitle-subtitle__mail">
                {!_.isEmpty(currentCustomer) && currentCustomer.email}
              </div>
            </div>
            <div className="RightMenu-actions">
              <button
                type="button"
                className="Button RightMenu-actions--edit"
                onClick={() => setIsShowEdit(true)}
              >
                {I18n.t('webapp.label.edit_profile')}
              </button>
            </div>
          </div>
        </div>
        <div className="Box-Content overflow-y-auto">
          <div className="Box-List">
            <div className="RightMenu-Block RightMenu-account">
              <div className="RightMenu-title">
                {I18n.t('webapp.label.switch_account')}
              </div>
              {isRenderBusinessAccount && <BusinessAccountsList userInfo={currentCustomer} />}
            </div>
            <div className="RightMenu-Block RightMenu-manage--booking">
              <div className="RightMenu-title">
                {extraInfos.country_code === COUNTRY_ID ? I18n.t('webapp.label.share_eta_driver_id') : I18n.t('webapp.label.share_eta_driver')}
              </div>
              <div className="Box-List-Item" id="share-eta-content">
                <div className="flex-grow-1">
                  {I18n.t('webapp.label.share_eta_driver_desc')}
                </div>
                <span>
                  <div className="Overlay-Checkbox Green-Checkbox With-Icon">
                    <input
                      type="checkbox"
                      name="settings_value"
                      id="settings_value"
                      value={currentCustomer.send_sms_to_recipients}
                      checked={currentCustomer.send_sms_to_recipients}
                      onChange={e => onChangeSettings(e.target.checked, 'send_sms_to_recipients')}
                    />
                    <label>
                      <i className="b material-icons Icon">
                        check
                      </i>
                    </label>
                  </div>
                </span>
              </div>
              <div className="RightMenu-title">
                {I18n.t('webapp.label.referral_code')}
              </div>
              <div className="Box-List-Item RightMenu-referral">
                <div className="RightMenu-referral--code">
                  {I18n.t('webapp.label.share_your_code')}
                </div>
                <span>
                  {referenceCode}
                </span>
              </div>
              <div className="RightMenu-title">
                {I18n.t('webapp.label.option_title')}
              </div>
              <div className="Box-List-Item mb80 RightMenu__show-phone">
                <PopupEyeArea
                  currentCustomer={currentCustomer}
                  areaId={currentCustomer.area_id}
                  icon="visibility"
                  iconClass="b material-icons Icon cur-pointer large-icon default-color-icon"
                  labelName={I18n.t('webapp.label.allow_driver_to_call_desc')}
                  popupItems={{ title: 'ar_sc_pn_td_title', content: 'ar_sc_pn_td_content' }}
                  popupType="ar_sc_pn_td"
                />
                <CustomerDNDCheckbox currentCustomer={currentCustomer} onChangeSettings={onChangeSettings}/>
              </div>
              <div className="RightMenu-title">
                {I18n.t('webapp.label.terms_and_conditions')}
              </div>
              <RenderContentTermsConditions
                extraInfos={extraInfos}
                currentCustomer={currentCustomer}
              />
            </div>
          </div>
        </div>
      </div>
      <div
        className="RightMenu-close-pane"
        onClick={closeModal}
      />
    </div>
  )
}

UserProfile.propTypes = {
  currentCustomer: PropTypes.shape({}).isRequired,
  setIsShowEdit: PropTypes.func.isRequired,
  isUpdatedUser: PropTypes.bool.isRequired,
  setIsUpdatedUser: PropTypes.func.isRequired,
}

export default UserProfile
