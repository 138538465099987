import { useEffect, useRef, useState } from 'react'
import { useSelector } from 'react-redux'
import PropTypes from 'prop-types'
import CustomerAPI from '../../api/customers'
import {
  LIVE_CHAT,
  WALLET_ICON,
  FINAL_LEO_GRAPHIC,
  BACK_TO_TOP_ICON,
} from '../../constants/imageConstants'
import { Utils } from '../../utils/Utils'
import BookingCard from 'components/common/BookingCard'
import Card from 'components/common/Card'
import { useTranslation } from 'react-i18next'
import CommonUtils from 'utils/common'

const FrontPage = ({ frontPageMenu }) => {
  const [listBooking, setListBooking] = useState([])
  const frontPageRef = useRef()
  const [visible, setVisible] = useState(false)
  const { t } = useTranslation()
  const { currentCustomer, extraInfos }  = useSelector(({ currentCustomer, extraInfos }) => ({
    currentCustomer,
    extraInfos,
  }))

  const countryCode = extraInfos?.country_code || currentCustomer.country_code
  const liveChatContent = countryCode !== 'th' ? t('lcl.chat24h') : t('lcl.chatTh')
  const isBusiness = !!currentCustomer?.last_login_employ_id
  const isShowWallet =
    currentCustomer.id &&
    currentCustomer?.credit?.amount > 0 &&
    ((isBusiness && extraInfos?.enabled_ewallet?.includes('business')) ||
      (!isBusiness && extraInfos?.enabled_ewallet?.includes('individual')))
  const isShowBatch = extraInfos?.enable_batch_multiple
  const isShowSmartLoad = extraInfos?.enable_smart_load
  const isShowAppExclusive = currentCustomer.id && (isShowBatch || isShowSmartLoad)

  const handleChooseService = async (service) => {
    const companyId = currentCustomer?.current_company_id || ''
    const param = Utils.buildParamToFTL({
      countryCode: 'id',
      areaId: extraInfos?.area_id,
      companyId,
    })

    try {
      await CommonUtils.moengageTrackEvent(service.moe_event)
    } finally {
      switch (service.data_type) {
        case 'LCL':
          window.location.replace(`${service.url}?${param}`)
          break
  
        default:
          window.open(service.url, service.target || '_self')
          break
      }
    }
  }

  useEffect(() => {
    const getBooking = async () => {
      const {
        data: { data },
      } = await CustomerAPI.getFrontPageBooking({
        from_time: '',
        to_time: '',
        sort_dir: 'desc',
        page: 1,
        per_page: 3,
        'company_ids[]': currentCustomer?.last_login_employ_id || 0,
      })
      setListBooking(data || [])
    }
    if (currentCustomer.authentication_token) getBooking()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const renderBooking = () => {
    if (!listBooking.length) return <></>
    return (
      <>
        <div className="front-page__list-booking">
          <p className="booking-card__title front-page__title m0">{t('lcl.recent_bookings')}</p>
          <button
            className="btn-see-all"
            onClick={async () => {
              try {
                await window.Moengage.track_event('Select See All On Recent Bookings Front Page')
              } finally {
                window.location.href = '/bookings'
              }
            }}
            type="button"
          >
            <span>{t('lcl.see_all')}</span>
          </button>
        </div>
        <div className="booking-card-wrapper">
          {!!listBooking.length &&
            listBooking.map((item, index) => (
              <BookingCard
                specialClass={index === listBooking.length - 1 && 'mr0'}
                countryCode={countryCode}
                key={index}
                booking={item}
              />
            ))}
        </div>
      </>
    )
  }

  const backToTop = () => {
    frontPageRef.current.scrollTo({
      behavior: 'smooth',
      top: 0,
    })
  }
  const toggleVisible = () => {
    const scrolled = frontPageRef.current.scrollTop
    if (scrolled > 50) {
      setVisible(true)
    } else if (scrolled <= 50) {
      setVisible(false)
    }
  }

  useEffect(() => {
    const frontPageEl = frontPageRef.current
    frontPageEl.addEventListener('scroll', toggleVisible)
    return () => {
      frontPageEl.removeEventListener('scroll', toggleVisible)
    }
  }, [])

  return (
    <div className="frontpage-floating">
      <div ref={frontPageRef} id="front-page-wrapper" className="front-page-wrapper">
        {frontPageMenu?.main_services?.show && (
          <div className="feature-project">
            <div className="front-page__title mb10">{t('lcl.main_services')}</div>
            {frontPageMenu?.main_services?.body?.map((item, index) => (
              <Card
                key={index}
                onClick={() => handleChooseService(item)}
                className="cur-pointer"
                classContent="front-page__content"
                label={item.label}
                image={item.image}
                title={item.title}
                content={item.content}
              />
            ))}
          </div>
        )}
        {renderBooking()}
        <div className="mt30">
          <div className="flex front-page__support">
            {isShowWallet ? (
              <>
                <div className="mr20">
                  <div>
                    <img src={WALLET_ICON} alt="wallet" />
                    <p className="front-page__title">
                      {Utils.formatFee(
                        currentCustomer?.credit?.amount,
                        currentCustomer.country_code,
                        currentCustomer?.credit?.currency
                      )}
                    </p>
                  </div>
                  <p className="front-page__content">{t('lcl.credits_available')}</p>
                  <button
                    onClick={async () => {
                      try {
                        await window.Moengage.track_event('Select Wallet On Front Page')
                      } finally {
                        window.location.href = isBusiness
                          ? `/business/credit_balances?${encodeURI(`search[country_code]=${countryCode}`)}`
                          : `/credit_balances?${encodeURI(`search[country_code]=${countryCode}`)}`
                      }
                    }}
                    type="button"
                  >
                    <span>{t('business.credit_balances.index.wallet')}</span>
                  </button>
                </div>
                <div>
                  <div>
                    <img src={LIVE_CHAT} alt="" />
                    <p className="front-page__title">{t('lcl.customer_support')}</p>
                  </div>
                  <p className="front-page__content">{liveChatContent}</p>
                  <button
                    onClick={async () => {
                      try {
                        await window.Moengage.track_event('Select CS Chat On Front Page')
                      } finally {
                        if (!window.fcWidget.isOpen()) window.fcWidget.open()
                      }
                    }}
                    type="button"
                  >
                    <span>{t('lcl.chat_now')}</span>
                  </button>
                </div>
              </>
            ) : (
              <div className="front-page__full">
                <div>
                  <div>
                    <img src={LIVE_CHAT} alt="" />
                    <p className="front-page__title">{t('lcl.customer_support')}</p>
                  </div>
                  <p className="front-page__content">{liveChatContent}</p>
                </div>
                <button
                  onClick={() => {
                    if (!window.fcWidget.isOpen()) window.fcWidget.open()
                  }}
                  type="button"
                >
                  <span>{t('lcl.chat_now')}</span>
                </button>
              </div>
            )}
          </div>
          {isShowAppExclusive && (
            <div className="front-page__create" style={{ backgroundImage: `url(${FINAL_LEO_GRAPHIC})` }}>
              <p className="front-page__title mb5">{t('lcl.exclusive')}</p>
              <p className="front-page__content m0">{t('lcl.manage_multiple')}</p>
              <div>
                {isShowBatch && (
                  <>
                    <button
                      className="batch"
                      onClick={async () => {
                        try {
                          await window.Moengage.track_event('Select Multiple On Front Page')
                        } finally {
                          window.location.href = '/bookings/multiple'
                        }
                      }}
                      type="button"
                    >
                      <span>{t('batches.label.batch')}</span>
                    </button>
                    <button
                      className="batch-ez"
                      onClick={async () => {
                        try {
                          await window.Moengage.track_event('Select EZ Sheet On Front Page')
                        } finally {
                          window.location.href = '/batches/ez_spread_sheet'
                        }
                      }}
                      type="button"
                    >
                      <span>{t('batches.upload.ez_sheet')}</span>
                    </button>
                  </>
                )}
                {isShowSmartLoad && (
                  <>
                    <button
                      className="batch-smart"
                      onClick={async () => {
                        try {
                          await window.Moengage.track_event('Select SLP On Front Page')
                        } finally {
                          window.location.href = '/batches/smart_load_planner'
                        }
                      }}
                      type="button"
                    >
                      <span>{t('batches.label.smart_load_planner')}</span>
                    </button>
                  </>
                )}
              </div>
            </div>
          )}
          {frontPageMenu?.what_new?.show && (
            <div className="front-page__more">
              <p className="front-page__title">{t('lcl.what_new')}</p>
              <img
                className="cur-pointer"
                onClick={() => handleChooseService(frontPageMenu?.what_new?.body[0])}
                src={frontPageMenu?.what_new?.body[0].image}
                alt=""
                srcSet=""
              />
              <div className="flex justify-content-between">
                {frontPageMenu?.what_new?.body?.slice(1).map((item, index) => (
                  <div
                    key={index}
                    className={`${extraInfos.turn_on_lcl_on_front_page ? 'cur-pointer' : ''} ${
                      item.image_position === 'top' ? 'img-top' : 'img-bottom'
                    }`}
                    onClick={() => handleChooseService(item)}
                  >
                    {item.image_position === 'top' ? (
                      <>
                        <img src={item.image} alt="" />
                        <div>
                          <p className="front-page__title m0">{item.title}</p>
                          <p className="front-page__content">{item.content}</p>
                        </div>
                      </>
                    ) : (
                      <>
                        <div>
                          <p className="front-page__title m0">{item.title}</p>
                          <p className="front-page__content">{item.content}</p>
                        </div>
                        <img src={item.image} alt="" />
                      </>
                    )}
                  </div>
                ))}
              </div>
            </div>
          )}
          {visible && (
            <div onClick={backToTop} className="back-to-stop cur-pointer flex">
              <img src={BACK_TO_TOP_ICON} alt="" className="mr10" />
              <div>{t('lcl.back_to_top')}</div>
            </div>
          )}
        </div>
      </div>
    </div>
  )
}

FrontPage.propTypes = {
  frontPageMenu: PropTypes.shape({
    background_image: PropTypes.string,
    main_services: PropTypes.shape({
      body: PropTypes.array,
      name: PropTypes.string,
      show: PropTypes.bool
    }),
    what_new: PropTypes.shape({
      body: PropTypes.array,
      name: PropTypes.string,
      show: PropTypes.bool
    }),
  }).isRequired
}

export default FrontPage