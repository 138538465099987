import React from 'react'
import PropTypes from 'prop-types'

import { useTranslation } from 'react-i18next'

const Step1Policy = ({ onUpdateReasons, deletionAccount, onUpdateDeleteAccount }) => {
  const { t } = useTranslation()

  return (
    <div className="step-1-policy">
      <h2 className="title">{t('webapp.deletion_account.we_are_sorry_to_see_you_go')}</h2>
      <div className="sub-title">{t('webapp.deletion_account.tell_us_why_leaving')}</div>
      <div className='reason-list'>
        {deletionAccount.reasons.map((reason, index) => (  
          <div className="reason" key={reason.id}>
            <span>
              <div className="Overlay-Checkbox Green-Checkbox With-Icon">
                <input
                  type="checkbox"
                  name="settings_value"
                  value={reason.isChecked}
                  checked={reason.isChecked}
                  onChange={e => onUpdateReasons(index, e.target.checked)}
                />
                <label>
                  <i className="b material-icons Icon">
                    check
                  </i>
                </label>
              </div>
            </span>
            <div>{reason.name}</div>
          </div>
        ))}
          <div className="reason">
          <span>
            <div className="Overlay-Checkbox Green-Checkbox With-Icon">
              <input
                type="checkbox"
                name="settings_value"
                value={deletionAccount.isCheckedOther}
                checked={deletionAccount.isCheckedOther}
                onChange={e => onUpdateDeleteAccount('isCheckedOther', e.target.checked)}
              />
              <label>
                <i className="b material-icons Icon">
                  check
                </i>
              </label>
            </div>
          </span>
          <div>{t('webapp.deletion_account.others')}</div>
        </div>
      </div>
      {deletionAccount.isCheckedOther && (
        <textarea
          className="notify"
          rows={3}
          maxLength={120}
          value={deletionAccount.other_reason}
          onChange={(e) => onUpdateDeleteAccount('other_reason', e.target.value)}
        />
      )}
    </div>
  )
}

Step1Policy.propTypes = {
  onUpdateReasons: PropTypes.func.isRequired,
  onUpdateDeleteAccount: PropTypes.func.isRequired,
  deletionAccount: PropTypes.object.isRequired
}

export default Step1Policy
