import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import _ from 'lodash'

// UTILS
import { UtilsHTML } from 'utils/html'
import { currentVehicleType, isCustomerEditBooking } from 'utils/new_booking/common'
import ReceiptUtils from 'utils/ReceiptUtils'
import { TallyUtils } from 'utils/booking/TallyUtils'
import DiscountCode from '../../common/DiscountCode'
import { Utils } from 'utils/Utils'
import I18n from 'i18n/i18n'
// ACTIONS
import * as discountCodeActionCreators from 'store/actions/new_booking/discountCodeActionCreators'
import * as bookingActionCreators from 'store/actions/common/bookingActionCreators'
import * as customerActionCreators from 'store/actions/common/customerActionCreators'
import * as assignDriverActionCreators from 'store/actions/new_booking/assignDriverActionCreators'
import { othersActionsCreator } from 'store/toolkit/newBooking/others.reducer'
// COMPONENTS
// CONSTANTS
import { FULL_DAY, LONG_HAUL } from 'constants/bookingConstants'
import { DISCOUNT_CODE_STATUS } from 'constants/discountCodeConstants'
import {
  WALLET_ICON,
  WALLET_GRAY
} from 'constants/imageConstants'
import { DRIVER_PREFERENCES } from 'constants/newBookingConstants'
import { getParamFromURL, getQueryVariable, hasAssignDriver, isActivePayment, isEditBooking, isMarketingPage } from 'utils/booking/common'
import PaymentMethod from './PaymentMethod'
import FootNotes from 'components/common/FootNotes'
// COMMON
import CommonUtils from 'utils/common'

// ASSETS
import './Summary.scss'

const mapStateToProps = state => ({
  booking: (state.booking.id !== null && (isEditBooking() || isMarketingPage))
    ? state.bookAgainDetails : state.booking,
  currentVehicleType: currentVehicleType(state),
  locations: state.locations,
  selectedServiceTypeID: state.selectedServiceTypeID,
  serviceTypes: state.serviceTypes,
  extraInfos: state.extraInfos,
  shoppingItems: state.shoppingList,
  selectedVehicleTypeID: state.selectedVehicleTypeID,
  discountCode: state.discountCode,
  currentCustomer: state.currentCustomer,
  checkLocations: state.checkLocations,
  others: state.others,
  timeType: state.timeType,
  extraServices: state.extraServices,
  bookAgainDetails: state.bookAgainDetails,
})

const mapDispatchToProps = dispatch => ({
  discountCodeActions: bindActionCreators(discountCodeActionCreators, dispatch),
  bookingActions: bindActionCreators(bookingActionCreators, dispatch),
  customerActions: bindActionCreators(customerActionCreators, dispatch),
  OthersAction: bindActionCreators(othersActionsCreator, dispatch),
  assignDriverActions: bindActionCreators(assignDriverActionCreators, dispatch),
})

const ADDITIONAL_CONTENT = [
  {
    title: I18n.t('webapp.pricing_display.online_payments'),
    content: [I18n.t('webapp.pricing_display.online_payments_content')],
    index: 0,
    isExpanded: false,
  }
]

class Summary extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      LongHaulCollapse: false,
      showTooltip: false,
      additionalContent: ADDITIONAL_CONTENT,
    }
    this.handleCollapseDropoff = this.collapseDropoff.bind(this)
    this.handleOnApplyDiscountCode = this.handleOnApplyDiscountCode.bind(this)
    this.handleOnRemoveDiscountCode = this.handleOnRemoveDiscountCode.bind(this)
    this.handleOnChangeStatusDiscountCode = this.handleOnChangeStatusDiscountCode.bind(this)
    this.handleDebounceToogleTooltip = _.debounce(() => {
      this.toggleTooltip()
    }, 300)
    this.handleClickOutside = this.handleClickOutside.bind(this)
    this.handleDebounceChangeMethod = _.debounce((e) => {
      this.changeMethodPayment(e)
    }, 500)
  }

  componentDidMount() {
    const { discountCode = {}, customerActions } = this.props

    // try to apply discount code again to make sure it still valid
    if (discountCode && discountCode.status === DISCOUNT_CODE_STATUS.draft) {
      this.handleOnApplyDiscountCode(discountCode.value)
    }
    document.addEventListener('mousedown', this.handleClickOutside)
    customerActions.getCustomerCreditAmount()
  }

  componentDidUpdate(prevProps) {
    const { currentCustomer: prevCurrentCustomer, booking: prevBooking } = prevProps
    const { currentCustomer, bookingActions, booking } = this.props
    // Update bankTransfer to empty when login at step 3 with BP account
    if (currentCustomer?.last_login_employ_id !== prevCurrentCustomer?.last_login_employ_id
      && !!currentCustomer?.last_login_employ_id) {
      bookingActions.updateBookingAction({ bankTransfer: {} })
    }
  }

  componentWillUnmount() {
    document.removeEventListener('mousedown', this.handleClickOutside)
  }

  setDefaultService() {
    const { selectedServiceTypeID, serviceTypes } = this.props
    return _.filter(serviceTypes, e => e.id === selectedServiceTypeID)[0] || {}
  }

  collapseDropoff() {
    this.setState(prevState => ({
      LongHaulCollapse: !prevState.LongHaulCollapse
    }))
  }

  toggleTooltip() {
    const { showTooltip } = this.state
    const {
      customerActions,
      currentCustomer: {
        current_company_id: companyID
      }
    } = this.props

    if (!showTooltip && companyID) {
      customerActions.getCustomerCreditAmount(true, () => {
        this.setState({ showTooltip: !showTooltip })
      })
    } else {
      this.setState({ showTooltip: !showTooltip })
    }
  }

  handleSetExpandedKey = (key) => {
    const { additionalContent } = this.state
    const newArray = [ ...additionalContent ]
    const newItem = { ...newArray[key], isExpanded: !newArray[key].isExpanded }
    newArray[key] = newItem
    this.setState({ additionalContent: newArray })
  }

  handleExpandedAll = () => {
    const { additionalContent } = this.state
    const newArray = [ ...additionalContent ]
    const tempArr = newArray.map(item => ({ ...item, isExpanded: true }))
    this.setState({ additionalContent: tempArr })
  }

  summaryShopping() {
    const {
      booking, extraInfos, shoppingItems,
      selectedVehicleTypeID, serviceTypes, selectedServiceTypeID
    } = this.props
    const defaultService = this.setDefaultService()
    const selectedServiceType = _.filter(serviceTypes, e => e.id === selectedServiceTypeID)[0] || {}
    const vehicleType = _.find(selectedServiceType.vehicle_types, item => (+item.id === +selectedVehicleTypeID))

    const withDistance = TallyUtils.isEnabledTally({ extraInfos, vehicleType })
    /* eslint-disable react/no-danger */
    return (
      <div className="mt20 mr10 ml10 White-bg Radius-default">
        <div className="pt10 pb10">
          <div className="Pricing block-reset block">
            <div className="flex flex-start">
              <img src={defaultService.inactive_icon_url} alt={defaultService.name} height="40" />
              <div className="ml20">
                <p className="reset p m small-font default-color">
                  {I18n.t('webapp.shopping.shopping_order')}
                </p>
                <p className="reset p m small-font default-color">
                  {I18n.t('webapp.shopping.immediate_delivery')}
                </p>
              </div>
            </div>
            {extraInfos.country_code.toLowerCase() === 'th' ? (
              <div className="pt20 pb20">
                <p
                  className="reset p m small-font default-smaller-font-color center"
                  dangerouslySetInnerHTML={{ __html: I18n.t('webapp.shopping.your_order') }}
                />
              </div>
            ) : <div className="pt20" />}
            {ReceiptUtils.renderShoppingItemList(shoppingItems, false)}
            {ReceiptUtils.renderShoppingPriceNote()}
            {ReceiptUtils.renderShoppingPrice(booking, extraInfos.country_language, '', true, withDistance)}
            <div className="pt10 pb20">
              <p className="reset p m small-font default-smaller-font-color center">
                {I18n.t('webapp.shopping.your_order_description')}
              </p>
            </div>
          </div>
        </div>
      </div>
    )
  }

  handleClickOutside(event) {
    const { showTooltip } = this.state
    if (this.tooltipWrapper && !this.tooltipWrapper.contains(event.target) && showTooltip) {
      this.setState({ showTooltip: false })
    }
  }

  handleOnApplyDiscountCode(discountCodeText) {
    const {
      discountCodeActions, setIsWaitingCalculate,
    } = this.props

    if (discountCodeText) {
      setIsWaitingCalculate(true)
      discountCodeActions.applyDiscountCodeToBooking(discountCodeText, () => setIsWaitingCalculate(false))
    }
  }

  handleOnRemoveDiscountCode() {
    const {
      discountCodeActions, setIsWaitingCalculate
    } = this.props

    setIsWaitingCalculate(true)
    discountCodeActions.removeDiscountCodeFromBooking(() => setIsWaitingCalculate(false))
  }

  handleOnChangeStatusDiscountCode(status) {
    const {
      discountCodeActions,
    } = this.props

    discountCodeActions.setDiscountCodeStatus(status)
  }

  changeMethodPayment(event) {
    const {
      bookingActions,
      booking,
      setIsWaitingCalculate,
    } = this.props
    setIsWaitingCalculate(true)
    let bookingAttrUpdate = {
      use_credit: false
    }

    if (!booking.use_credit) {
      bookingAttrUpdate = {
        use_credit: true
      }
    }

    bookingActions.updateBookingAttributes(bookingAttrUpdate)
    bookingActions.calculate(() => setIsWaitingCalculate(false))
  }

  renderSectionExtraServiceNegative(countryLanguage) {
    const { booking } = this.props
    return booking.time_type === FULL_DAY
      && booking.booking_extra_requirements_negative_position
        .map(
          (extra, index) => ReceiptUtils.renderExtraService(booking, extra, index, countryLanguage)
        )
  }

  renderCreditSection() {
    const {
      booking,
      extraInfos,
      currentCustomer: {
        credit,
        current_company_id: companyID,
        allow_post_payment: allowPostPayment
      }
    } = this.props
    const { showTooltip } = this.state
    const creditWalletAmount = _.get(credit, 'amount', 0)
    const acceptEmptyCredit = _.get(credit, 'acceptEmptyCredit', false)
    const isBpPostPaid = companyID && allowPostPayment
    const creditBalance = Utils.formatFee(creditWalletAmount, extraInfos.country_language, undefined, 0)
    const creditAmount = isBpPostPaid ? booking.cashback_credit_used : booking.credit_amount
    const isCheckboxDisabled = isCustomerEditBooking()

    if (creditWalletAmount > 0
      || (isCheckboxDisabled && creditAmount)
      || (!creditWalletAmount && isBpPostPaid && acceptEmptyCredit)
    ) {
      if (!companyID || isBpPostPaid) {
        const displayPrice = creditAmount ? Utils.formatFee(creditAmount, extraInfos.country_language, undefined, 0) : 0
        const isChecked = booking.use_credit

        return (
          <div className="block-item block-item-sub credit-section credit-section-single">
            <span className={`Overlay-Checkbox With-Icon Green-Checkbox ${isCheckboxDisabled ? 'Disable' : ''}`}>
              <input
                type="checkbox"
                checked={booking.use_credit}
                onChange={(e) => {
                  e.persist()
                  this.handleDebounceChangeMethod(e)
                }}
                disabled={isCheckboxDisabled}
              />
              <label>
                <i className="b material-icons Icon">
                  check
                </i>
              </label>
            </span>
            <div className={`block-item block-item-custom ${isChecked ? '' : 'hide-divide'}`}>
              <label htmlFor="usecredit-checkbox">
                <b>
                  {I18n.t('webapp.pricing_display.use_credits')}
                </b>
              </label>
              {
                !isChecked && (
                  <span
                    className={`credit-tooltip ${showTooltip ? 'show-tooltip' : ''}`}
                    data-tooltip={I18n.t('webapp.label.credits_available', { creditBalance })}
                    data-tooltip-position="bottom"
                    onClick={this.handleDebounceToogleTooltip}
                    ref={(ref) => { this.tooltipWrapper = ref }}
                  >
                    <img src={showTooltip ? WALLET_ICON : WALLET_GRAY} alt="cashback-info" />
                  </span>
                )
              }
              {
                isChecked && (
                  <span>
                    {`${displayPrice ? '-' : ''}${displayPrice}`}
                  </span>
                )
              }
            </div>
          </div>
        )
      }
    }

    return null
  }

  renderNotes() {
    const {
      booking, currentCustomer, setIsWaitingCalculate, isWaitingCalculate,
    } = this.props
    const { current_company_id: companyID } = currentCustomer
    const batchIdUrl = getParamFromURL('batch_id')
    const isShowPayment = isActivePayment(booking, currentCustomer, booking?.batch_id)
    const totalFees = (!companyID && !booking.use_credit) ? booking.display_total_fees : booking.total_fees
    const isShowOldPayment = !booking?.special_settings?.enable_online_payment
      || !!currentCustomer?.last_login_employ_id || booking?.batch_id || batchIdUrl
      || (!isShowPayment && booking?.special_settings?.enable_online_payment
        && !booking.use_credit && !booking.discount_code_info && totalFees === 0)
    if (isShowOldPayment) return ReceiptUtils.renderNotesStep3(booking)

    let freeInsideZoneStops
    let priceOutsideZone
    if (booking.time_type === LONG_HAUL) {
      const distanceFeeDetails = booking.distance_fee_details
      if (!_.isEmpty(distanceFeeDetails)) {
        freeInsideZoneStops = distanceFeeDetails.free_inside_zone_stops
        priceOutsideZone = !_.isEmpty(distanceFeeDetails.routes.filter(i => i.type === 'outside'))
      }
    }
    return (
      <div className="booking-summary-notes">
        {isShowPayment && <PaymentMethod setIsWaitingCalculate={setIsWaitingCalculate} isWaitingCalculate={isWaitingCalculate} />}
        {!_.isUndefined(freeInsideZoneStops) && ReceiptUtils.renderNotesLongHaul(freeInsideZoneStops, priceOutsideZone)}
      </div>
    )
  }

  renderTotal() {
    const {
      booking,
      extraInfos: {
        country_language: countryLanguage
      },
      currentCustomer: {
        current_company_id: companyID
      }
    } = this.props
    const totalFees = (!companyID && !booking.use_credit) ? booking.display_total_fees : booking.total_fees
    const isNonBP = booking.company_id === 0 || _.isNil(booking.company_id)
    return (
      <div className="block-item total relative">
        <label>
          <span className="White-bg pr5">
            {isNonBP ? I18n.t('webapp.pricing_display.total') : I18n.t('webapp.pricing_display.invoice')}
          </span>
        </label>
        <span className="White-bg">
          {Utils.formatFee(totalFees, countryLanguage, undefined, 0)}
        </span>
      </div>
    )
  }

  render() {
    const {
      booking, locations, extraInfos, setIsWaitingCalculate,
      isShopping, discountCode, checkLocations, currentCustomer, refStep3Container
    } = this.props
    const { LongHaulCollapse } = this.state
    const countryLanguage = extraInfos.country_language
    const isShowCPOD = currentCustomer?.id && checkLocations && checkLocations.cod_pod
    const minimumAmount = isCustomerEditBooking()
      ? booking?.bookingPaymentDetails?.minimum_amount : booking?.bankTransfer?.settings?.minimumAmountValue

    return !_.isEmpty(booking) && !isShopping ? (
      <div className="mt20 mr10 ml10 White-bg Radius-default">
        {UtilsHTML.renderSectionTitle(`${I18n.t('webapp.pricing_display.fee_summary')} (${extraInfos.currency})`)}
        <div className="Pricing block-reset block">
          {booking.time_type === LONG_HAUL
            ? ReceiptUtils.renderLongHaulStandardFee(
              booking, LongHaulCollapse, this.handleCollapseDropoff, countryLanguage
            ) : ReceiptUtils.renderStandardFee(booking, countryLanguage, booking.vehicle_type)
          }
          {this.renderSectionExtraServiceNegative(countryLanguage)}
          {ReceiptUtils.renderSectionExtraService(booking, locations, countryLanguage, isShowCPOD)}
          {ReceiptUtils.renderSectionBadge(booking.booking_badges, countryLanguage)}
          {ReceiptUtils.renderSubTotal(booking, countryLanguage)}
          {ReceiptUtils.renderStaticAndCustomReimbursement(booking, countryLanguage)}
          {ReceiptUtils.renderDiscountSection(booking, discountCode, countryLanguage)}
          {ReceiptUtils.renderRoundTripDiscount(booking, countryLanguage)}
          {ReceiptUtils.renderSurchargeAdjustment(booking, countryLanguage)}
          {this.renderCreditSection()}
          {ReceiptUtils.renderBusinessSection(booking, countryLanguage)}
          {ReceiptUtils.renderSettlements(booking, countryLanguage, true)}
          {ReceiptUtils.renderOnlinePaymentService(booking, countryLanguage)}
          {ReceiptUtils.renderTopUpToWallet(booking, countryLanguage, minimumAmount)}
          {ReceiptUtils.renderPaidAmountPayment(booking, countryLanguage)}
          {ReceiptUtils.renderRefundToDelivereeWallet(booking, countryLanguage)}
          <DiscountCode
            discountCode={discountCode}
            onApply={this.handleOnApplyDiscountCode}
            onRemove={this.handleOnRemoveDiscountCode}
            onChangeStatus={this.handleOnChangeStatusDiscountCode}
            setIsWaitingCalculate={setIsWaitingCalculate}
            isSingleView
          />
          {this.renderTotal()}
        </div>
        {this.renderNotes()}
        <FootNotes
          booking={booking}
          currentCustomer={currentCustomer}
          refContainerScroll={refStep3Container}
        />
      </div>
    ) : this.summaryShopping()
  }
}

Summary.defaultProps = {
  isShopping: false,
  serviceTypes: [],
  shoppingItems: [],
  selectedServiceTypeID: undefined,
  selectedVehicleTypeID: undefined,
  discountCode: {},
}

Summary.propTypes = {
  locations: PropTypes.instanceOf(Array).isRequired,
  booking: PropTypes.shape({}).isRequired,
  extraInfos: PropTypes.shape({}).isRequired,
  isShopping: PropTypes.bool,
  serviceTypes: PropTypes.instanceOf(Array),
  shoppingItems: PropTypes.instanceOf(Array),
  selectedServiceTypeID: PropTypes.number,
  selectedVehicleTypeID: PropTypes.number,
  discountCode: PropTypes.shape({}),
  discountCodeActions: PropTypes.shape({}).isRequired,
  bookingActions: PropTypes.shape({}).isRequired,
  currentCustomer: PropTypes.shape({}).isRequired,
  customerActions: PropTypes.shape({}).isRequired,
  refStep3Container: PropTypes.shape({}).isRequired,
  setIsWaitingCalculate: PropTypes.func.isRequired,
  isWaitingCalculate: PropTypes.bool.isRequired,
}

export default connect(mapStateToProps, mapDispatchToProps)(Summary)
