import locationUtils from './location'
import toastr from 'utils/toast'
import I18n from 'i18n/i18n'

import * as IMAGES from 'constants/imageConstants'
import mapUtils from './map'

const currentLocationUtils = {
  getLocationAfterCheckBookingDetails({
    mapService, extraInfos, googleMap, isEnableGoogleMap
  }) {
    if (!navigator.geolocation) return null

    const self = this
    return new Promise((resolve) => {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          resolve(self.getLocationDefault({
            position, mapService, extraInfos, googleMap, isEnableGoogleMap
          }))
        },
        () => {
          // Failed to get current location
          toastr.remove()
          toastr.options = {
            positionClass: 'toast-top-center new-booking custom-width',
          }
          // Need translations for deliveree & transportify
          toastr.info(I18n.t('webapp.geolocation'))
        }
      )
    })
  },

  getLocationDefault({ position, mapService, extraInfos, googleMap, isEnableGoogleMap }) {
    // Get geo location result
    const self = this
    return new Promise((resolve) => {
      function processGeolocationResult(pos) {
        const html5Lat = pos.coords.latitude // Get latitude
        const html5Lon = pos.coords.longitude // Get longitude
        // const html5TimeStamp = position.timestamp; //Get timestamp
        // const html5Accuracy = position.coords.accuracy; //Get accuracy in meters

        return `${html5Lat.toFixed(8)}, ${html5Lon.toFixed(8)}`
      }

      function getCurrentAddress(location) {
        locationUtils.handleCallGeocodeAPI({ latlng: location }, (results) => {
          if (results) {
            const { formattedAddress, addressComponent, lat, lng } = locationUtils.getDataResponse(results[0])
            const data = self.setDefaultAddress({
              addressFirstLocation: '',
              mapService,
              extraInfos,
              position: {
                latitude: lat,
                longitude: lng
              },
              positionAddress: formattedAddress,
              googleMap, isEnableGoogleMap, addressComponents: addressComponent
            })
            resolve(data)
          }
          resolve(null)
        }, extraInfos)
      }

      // Check value is present or not & call google api function

      function initializeCurrent(latcurr, longcurr) {
        if (latcurr !== '' && longcurr !== '') {
          const latlng = `${latcurr},${longcurr}`
          getCurrentAddress(latlng)
        }
        return null
      }
      const currLatLong = processGeolocationResult(position).split(',')
      initializeCurrent(currLatLong[0], currLatLong[1])
    })
  },

  setDefaultAddress({
    addressFirstLocation = '',
    mapService,
    extraInfos,
    position,
    positionAddress,
    addressComponents = [],
    googleMap, isEnableGoogleMap = true
  }) {
    let marker = {}
    const pinfrom = {
      bool: true,
      icon: '',
    }

    let locationAttrs = {
      lat: undefined,
      lng: undefined,
      name: undefined,
      marker: undefined,
      contact_id: undefined,
    }

    let currentLat
    let currentLng
    let currentLatBlank
    let currentLngBlank

    if (extraInfos.default_center_lat_lng || position) {

      currentLatBlank = extraInfos.default_center_lat_lng.split(',')[0]
      currentLngBlank = extraInfos.default_center_lat_lng.split(',')[1]
      currentLat = position ? position.latitude : currentLatBlank

      currentLng = position ? position.longitude : currentLngBlank
      if (isEnableGoogleMap) {
        marker = new window.google.maps.Marker({
          position: new window.google.maps.LatLng(currentLat, currentLng),
          map: googleMap?.map || '',
          animation: window.google.maps.Animation.DROP,
          draggable: true,
        })
      } else {
        marker = mapUtils.setMarker(mapService, currentLng, currentLat)
      }

      if(isEnableGoogleMap) {
        switch (I18n.language) {
          case 'TH':
          case 'th':
            pinfrom.icon = IMAGES.PIN_FROM_DRAP_DROP_TH
            break;
          case 'ID':
          case 'id':
            pinfrom.icon = IMAGES.PIN_FROM_DRAP_DROP_ID
            break;
          default:
            pinfrom.icon = IMAGES.PIN_FROM_DRAP_DROP_EN
        }
      } else {
        pinfrom.icon = IMAGES.PIN_FROM_DRAP_DROP
        pinfrom.text = I18n.t('label.pin_from')
        pinfrom.content = I18n.t('label.you_can_drap_drop_pin')
      }

      locationAttrs = {
        pinfrom,
        lat: currentLat,
        lng: currentLng,
        name: positionAddress || addressFirstLocation,
        marker,
        contact_id: undefined,
        address_components: addressComponents || extraInfos.default_address_component_pickup,
      }
      return locationAttrs
    }

    return null
  },
}

export default currentLocationUtils
