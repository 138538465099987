import React, { useEffect, useLayoutEffect, useState } from 'react'
import Datetime from 'react-datetime'
import PropTypes from 'prop-types'
import moment from 'moment'
import { Utils } from 'utils/Utils'
import I18n from 'i18n/i18n'
import ApplyForEntireBatch from '../ez_spread_sheet/validate/popups/ApplyForEntireBatch'
import { CALENDAR_ICON } from 'constants/imageConstants'
import { isEmpty, size } from 'lodash'

const HALF_DAY_HOUR = 12
const BookingCalendar = (props) => {
  const {
    minMinutes, isShowCancelBtn, isShowOkBtn, closePicker, visibleTimer, hasApplyEscapePopup,
    disableDateBefore, countryCode, maximumPickupDays, batchTemplate, modalCssClassName,
    applyAllBookings, viewBatchEZ, confirmText, showWarningMessage, configStyle, selectedDateTime: selectedDateTimeProps
  } = props
  const [selectedDateTime, setSelectedDateTime] = useState(moment(selectedDateTimeProps))
  const [overlayMinute, setOverlayMinute] = useState(moment(selectedDateTimeProps).format('mm'))
  let minSelectedDateTimeHandle = disableDateBefore
    ? moment(disableDateBefore).add(minMinutes, 'minutes')
    : moment().add(minMinutes, 'minutes')
  const hourFormat = Utils.hourFormatByCountry(countryCode)
  const timeFormat = countryCode.toUpperCase() === 'PH' ? `${hourFormat}:mm A` : `${hourFormat}:mm`
  const cssText = 'pointer-events: none; opacity: 0.4'
  let maxHour = 23
  const minHour = 0
  if (hourFormat === 'h') {
    maxHour = HALF_DAY_HOUR
  }
  const [overlayHour, setOverlayhour] = useState(moment(selectedDateTimeProps).format(hourFormat))
  const minYear = minSelectedDateTimeHandle.year()
  const currentYear = selectedDateTime.year()
  const minMonth = minSelectedDateTimeHandle.month()
  const currentMonth = selectedDateTime.month()
  const minDate = minSelectedDateTimeHandle.date()
  const currentDate = selectedDateTime.date()

  const updateMinDateTime = () => {
    const minSelectedDateTime = disableDateBefore
      ? moment(disableDateBefore).add(minMinutes, 'minutes')
      : moment().add(minMinutes, 'minutes')
    minSelectedDateTimeHandle = moment(minSelectedDateTime)
  }

  const disableHourCounter = () => {
    const element = document.querySelector('.rdtCounters .rdtCounter:nth-of-type(1) span:last-child')
    if (element) element.style.cssText = cssText
  }

  const enableHourCounter = () => {
    const element = document.querySelector('.rdtCounters .rdtCounter:nth-of-type(1) span:last-child')
    if (element) element.style = ''
  }

  const disableMinuteCounter = () => {
    const element = document.querySelector('.rdtCounters .rdtCounter:nth-of-type(3) span:last-child')
    if (element) element.style.cssText = cssText
  }

  const enableMinuteCounter = () => {
    const element = document.querySelector('.rdtCounters .rdtCounter:nth-of-type(3) span:last-child')
    if (element) element.style = ''
  }

  const disableAMPMCounter = () => {
    const rdtCounterLast = document.querySelectorAll('.rdtCounters .rdtCounter:last-child span')
    const element0 = rdtCounterLast?.[0]
    const element1 = rdtCounterLast?.[1]
    if (element0) element0.style.cssText = cssText
    if (element1) element1.style.cssText = cssText
  }

  const enableAMPMCounter = () => {
    const rdtCounterLast = document.querySelectorAll('.rdtCounters .rdtCounter:last-child span')
    const element0 = rdtCounterLast?.[0]
    const element1 = rdtCounterLast?.[1]
    if (element0) element0.style = ''
    if (element1) element1.style = ''
  }

  // const hideAMPMCounter = () => {
  //   const rdtCounterLast = document.querySelectorAll('.rdtCounters .rdtCounter:last-child span')
  //   const element0 = rdtCounterLast?.[0]
  //   const element1 = rdtCounterLast?.[1]
  //   if (element0) element0.style.cssText = 'pointer-events: none; opacity: 0'
  //   if (element1) element1.style.cssText = 'pointer-events: none; opacity: 0'
  // }

  const enableAllCounters = () => {
    const elementList = document.querySelectorAll('.rdtCounters .rdtCounter span')
    for (let index = 0; index < elementList.length; index += 1) {
      elementList[index].style = ''
    }
    // if (hourFormat === 'H') {
    //   hideAMPMCounter()
    // }
  }

  const syncUI = () => {
    const isCurrentDateLessOrEqual = currentYear <= minYear && currentMonth <= minMonth && currentDate <= minDate;
    const isHourLessOrEqual = selectedDateTime.hour() <= minSelectedDateTimeHandle.hour();
    const isMinuteLessOrEqual = selectedDateTime.minute() <= minSelectedDateTimeHandle.minute();
    const isHourGreaterThanHalfDay = selectedDateTime.hour() > HALF_DAY_HOUR;
    const isHourDiffLessThanMinHour = selectedDateTime.hour() - HALF_DAY_HOUR < minSelectedDateTimeHandle.hour();
    const isHourFormatH = hourFormat === 'h';
  
    if (!isCurrentDateLessOrEqual) return enableAllCounters();
  
    if (isHourLessOrEqual) {
      disableHourCounter();
      if (isMinuteLessOrEqual && isHourGreaterThanHalfDay && isHourDiffLessThanMinHour && isHourFormatH) disableAMPMCounter();
      return isMinuteLessOrEqual ? disableMinuteCounter() : enableMinuteCounter();
    }
  
    enableHourCounter();
    enableMinuteCounter();
    return (isHourGreaterThanHalfDay && isHourDiffLessThanMinHour && isHourFormatH) ? disableAMPMCounter() : enableAMPMCounter();
  }

  const onChangeDate = (selectedDate) => {
    let date = selectedDate
    updateMinDateTime()
    if (date.isBefore(minSelectedDateTimeHandle)) {
      date = minSelectedDateTimeHandle
    }
    setSelectedDateTime(date)
    setOverlayhour(date.format(hourFormat))
    setOverlayMinute(date.format('mm'))
  }

  const onChangeTime = (selectedDate) => {
    let date = moment(selectedDate, true)
    updateMinDateTime()
    if (date.isValid()) {
      date.year(selectedDateTime.year())
        .month(selectedDateTime.month())
        .date(selectedDateTime.date())
      if (date.isBefore(minSelectedDateTimeHandle)) {
        date = minSelectedDateTimeHandle
      }
      setSelectedDateTime(date)
      setOverlayhour(date.format(hourFormat))
      setOverlayMinute(date.format('mm'))
    }
  }

  const handleClosePicker = (cancel) => {
    syncUI()
    closePicker(cancel, selectedDateTime.format())
  }

  const validDateRange = (currentDateTime) => {
    const minDateTime = Datetime.moment().set('date', minSelectedDateTimeHandle.date() - 1)
    const maxDateTime = Datetime.moment().add(maximumPickupDays, 'days')
    return currentDateTime.isBetween(minDateTime, maxDateTime)
  }


  const renderToggle = () => {
    if (isEmpty(batchTemplate)) {
      return null
    }
    const oneBookingCss = !batchTemplate.same_pickup_datetime ? 'active' : ''
    const allBookingCss = batchTemplate.same_pickup_datetime ? 'active' : ''
    return (
      <div className="Toggle-Choose Custom">
        <div className="Toggle-Choose-Box">
          <h5
            className={oneBookingCss}
            onClick={() => { applyAllBookings(false) }}
          >
            {I18n.t('batches.label.for_this_booking')}
          </h5>
          <h5
            className={allBookingCss}
            onClick={() => { applyAllBookings(true) }}
          >
            {I18n.t('batches.label.for_entire_batch')}
          </h5>
        </div>
      </div>
    )
  }

  const submitInputTime = (type, selectedDate, dateTimeFormat, callback = (() => { })) => {
    let value
    if (type === 'minute') {
      value = !overlayMinute ? 0 : parseFloat(overlayMinute, 10)
      selectedDate.minute(value)
      value = selectedDate.format(dateTimeFormat)
      return callback({ target: { value } })
    }
    let hour
    if (overlayHour === '') {
      hour = moment(selectedDateTime).format(hourFormat)
    } else {
      hour = overlayHour
    }
    value = !overlayMinute ? minHour : parseFloat(hour, 10)
    if (selectedDate.hour() >= HALF_DAY_HOUR
      && maxHour === HALF_DAY_HOUR && value < HALF_DAY_HOUR) value += HALF_DAY_HOUR
    selectedDate.hour(value)
    value = selectedDate.format(dateTimeFormat)
    return callback({ target: { value } })
  }

  const changeOverlayHour = (event) => {
    const value = parseFloat(event.target.value, 10)
    if (!value && value !== 0) setOverlayhour('')
    if (value >= minHour && value <= maxHour) setOverlayhour(value)
  }
  const changeOverlayMinute = (event) => {
    let value = parseFloat(event.target.value, 10)
    if (!value) setOverlayMinute('')
    if (value && value < 10) value = `0${value}`
    if (value && value >= 0 && value <= 59) setOverlayMinute(value)
  }

  const renderInputTime = (data) => {
    const dateTimeFormat = `MM/DD/YYYY ${timeFormat}`
    const selectedDate = moment(data.value, dateTimeFormat)
    const handleKeyPress = (e, type) => {
      if (e.keyCode === 13) submitInputTime(type, selectedDate, dateTimeFormat, data.onChange)
    }
    return (
      <div>
        <input {...props} className="disabled" style={{ display: 'none' }} />
        <input
          type="text"
          value={overlayHour}
          className={`Overlay-Hours ${countryCode !== 'PH' ? 'not-PH' : ''}`}
          onChange={e => changeOverlayHour(e)}
          onKeyPress={e => handleKeyPress(e, 'hour')}
          onBlur={() => submitInputTime('hour', selectedDate, dateTimeFormat, data.onChange)}
        />
        <input
          type="text"
          value={overlayMinute}
          className={`Overlay-Minutes ${countryCode !== 'PH' ? 'not-PH' : ''}`}
          onChange={e => changeOverlayMinute(e)}
          onKeyPress={e => handleKeyPress(e, 'minute')}
          onBlur={() => submitInputTime('minute', selectedDate, dateTimeFormat, data.onChange)}
        />
      </div>
    )
  }

  const escFunction = (e) => {
    if (e.keyCode === 27) {
      if (hasApplyEscapePopup) {
        handleClosePicker(true)
      }
    }
  }

  useEffect(() => {
    let selectedDate = moment(selectedDateTimeProps)
    if (!selectedDate.isValid()
      || selectedDate.isBefore(minSelectedDateTimeHandle)) selectedDate = minSelectedDateTimeHandle
    setSelectedDateTime(selectedDate)
    setOverlayhour(selectedDate.format(hourFormat))
    setOverlayMinute(selectedDate.format('mm'))
    document.addEventListener('keydown', escFunction, false)
    return () => {
      document.removeEventListener('keydown', escFunction, false)
    }
  }, [])

  useLayoutEffect(() => {
    syncUI()
  }, [selectedDateTime])

  return (
    <div
      className={`PickupTime-DatePicker ${modalCssClassName}`}
      style={size(configStyle) ? Utils.modifyPopupMultiple(configStyle.scale, configStyle.id) : {}}
      data-bookingID={size(configStyle) ? configStyle.id : null}
    >
      {
        showWarningMessage && (
          <div className="message-popup message-popup-brown">
            {I18n.t('batches.label.old_pickup_time_warning')}
          </div>
        )
      }
      <div className="DatePicker-Calendar">
        <div className="DatePicker-Calendar-img flex-center">
          <img src={CALENDAR_ICON} alt="calendar icon" className="m-h32px" />
        </div>
        {viewBatchEZ && renderToggle()}
        <Datetime
          input={false}
          open
          className={`${!visibleTimer && 'rdtPicker-border-bottom'}`}
          disableOnClickOutside
          viewMode="days"
          timeFormat={timeFormat}
          locale={I18n.language.toLowerCase()}
          isValidDate={validDateRange}
          onChange={e => onChangeDate(e)}
          value={selectedDateTime}
        />
        <Datetime
          input
          open
          className={`${!visibleTimer && 'hidden'}`}
          disableOnClickOutside
          closeOnTab={false}
          viewMode="time"
          timeFormat={timeFormat}
          value={selectedDateTime}
          onChange={e => onChangeTime(e)}
          overlayHour={overlayHour}
          overlayMinute={overlayMinute}
          hourFormat={hourFormat}
          renderInput={renderInputTime}
          onChangeHour={changeOverlayHour}
          onChangeMinute={changeOverlayMinute}
          handleSubmitTime={submitInputTime}
        />
      </div>

      <div className="DatePicker-Actions">
        {isShowCancelBtn && (
          <button
            className="Button gray"
            type="button"
            onClick={() => { handleClosePicker(true) }}
          >
            {I18n.t('webapp.action.cancel')}
          </button>
        )}
        {isShowOkBtn && (
          <button
            className="Button white green-text"
            type="button"
            onClick={() => handleClosePicker(false)}
          >
            {I18n.t(`webapp.action.${confirmText}`)}
          </button>
        )}
      </div>
      {viewBatchEZ && (
        <ApplyForEntireBatch
          confirmType="pickup_date_time"
          pickupTime={selectedDateTime.format()}
        />)}
    </div>
  )
}

BookingCalendar.defaultProps = {
  applyAllBookings: null,
  batchTemplate: {},
  configStyle: {},
  modalCssClassName: '',
  selectedDateTime: undefined,
  viewBatchEZ: false,
  visibleTimer: true,
  isShowCancelBtn: undefined,
  isShowOkBtn: undefined,
  hasApplyEscapePopup: true,
  showWarningMessage: false,
  disableDateBefore: '',
  confirmText: 'ok'
}

BookingCalendar.propTypes = {
  minMinutes: PropTypes.number.isRequired,
  maximumPickupDays: PropTypes.number.isRequired,
  closePicker: PropTypes.func.isRequired,
  countryCode: PropTypes.string.isRequired,
  applyAllBookings: PropTypes.func,
  batchTemplate: PropTypes.shape({}),
  configStyle: PropTypes.shape({}),
  modalCssClassName: PropTypes.string,
  selectedDateTime: PropTypes.string,
  viewBatchEZ: PropTypes.bool,
  visibleTimer: PropTypes.bool,
  isShowCancelBtn: PropTypes.bool,
  isShowOkBtn: PropTypes.bool,
  hasApplyEscapePopup: PropTypes.bool,
  showWarningMessage: PropTypes.bool,
  disableDateBefore: PropTypes.string,
  confirmText: PropTypes.string,
}
export default BookingCalendar
