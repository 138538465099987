import { NEW_GATEWAY } from 'constants/appConstants'
import apiClient from 'services/axiosApp'

const AuthenticationAPI = {
  getToken: (params) =>
    apiClient.post(`${NEW_GATEWAY}/sts/token`, params, {
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
      },
    }),

  getTokenByRefreshToken: (params) =>
    apiClient.post(`${NEW_GATEWAY}/sts/token`, params, {
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
        'Authorization': ''
      },
    }),
  getUserTokenByAccessToken: (params) =>
    apiClient.post(`${NEW_GATEWAY}/sts/renew_token`, params, {
      headers: {
        'Authorization': ''
      },
    }),
}


export default AuthenticationAPI
