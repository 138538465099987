import { SHOWROUTE_CURVE, SHOWROUTE_SECTION } from 'constants/appConstants'
import * as IMAGES from 'constants/imageConstants'
import _ from 'lodash'
import $ from 'jquery'
import I18n from 'i18n/i18n'
import { v4 as uuidv4 } from 'uuid'
import { renderToStaticMarkup } from 'react-dom/server';
import { labelDropDrapPositions } from 'constants/bookingConstants'
export const ID_ROUTE = 'route'

const degreesToRadians = (degrees) => {
  const pi = Math.PI;
  return degrees * (pi / 180);
}

const radiansToDegrees = (radians) => {
  const pi = Math.PI;
  return radians * (180 / pi);
}

const lineSymbol = {
  path: 'M 0,-1 0,1',
  strokeOpacity: 1,
  scale: 4
}

const mapUtils = {
  reCenterAndZoom(googleMap, location, zoomLevel) {
    if (_.isUndefined(location)) { return }
    if (location.lat && location.lng && location.name) {
      googleMap.map.setZoom(zoomLevel)
      googleMap.map.setCenter(new window.google.maps.LatLng(location.lat, location.lng))
    }
  },
  buildKeyAvoidRoute(currentVehicleType) {
    const settingsAvoidRoute = currentVehicleType.settings.route_options_avoid
    const keyHighWays = I18n.t('settings.attributes.vehicle_type.route_options_avoid.labels.highways').toLowerCase()
    const keyTolls = I18n.t('settings.attributes.vehicle_type.route_options_avoid.labels.tolls').toLowerCase()
    const keyFerries = I18n.t('settings.attributes.vehicle_type.route_options_avoid.labels.ferries').toLowerCase()
    return {
      avoidHighways: _.includes(settingsAvoidRoute, keyHighWays) || false,
      avoidTolls: _.includes(settingsAvoidRoute, keyTolls) || false,
      avoidFerries: _.includes(settingsAvoidRoute, keyFerries) || false
    }
  },
  drawPolyLine({
    mapService, req, outsideList = [],
    googleMap, isEnableGoogleMap
  }) {
    const polylineList = []
    let bounds
    if(isEnableGoogleMap) {
      bounds = new window.google.maps.LatLngBounds()
    } else {
      bounds = new window.maplibregl.LngLatBounds()
    }
    req.map(point => bounds.extend(point.location))
    let curvature = SHOWROUTE_CURVE
    for (let i = 0; i < req.size - 1; i += 1) {
      const outSideFullDay = _.findIndex(outsideList,
        location => _.toString(req.get(i + 1).id) === _.toString(location))

      const lat = req.get(i).location.lat
      const lng = req.get(i).location.lng
      const nextLat = req.get(i + 1).location.lat
      const nextLng = req.get(i + 1).location.lng

      const path = mapUtils.getLatLngPath(
        { lat: lat, lng: lng },
        { lat: nextLat, lng: nextLng },
        curvature, isEnableGoogleMap
      )
      curvature = -curvature

      if(isEnableGoogleMap) {
        const options = {
          path,
          geodesic: false,
          strokeColor: outSideFullDay !== -1 ? '#f04433' : 'rgba(14,115,15,1)',
          strokeOpacity: 0,
          icons: [{
            icon: lineSymbol,
            offset: '0',
            repeat: '20px'
          }],
          map: googleMap.map
        }
        const polyline = new window.google.maps.Polyline(options)
        polylineList.push(polyline)
      } else {
        const idLayer = uuidv4()
        mapService.addSource(idLayer, {
          'type': 'geojson',
          'data': {
            'type': 'FeatureCollection',
            'features': [
              {
                'type': 'Feature',
                'properties': {
                  'color': outSideFullDay !== -1 ? '#f04433' : 'rgba(14,115,15,1)'
                },
                'geometry': {
                  'type': 'LineString',
                  'coordinates': path
                }
              },
            ]
          }
        });
        mapService.addLayer({
          'id': idLayer,
          'type': 'line',
          'source': idLayer,
          'paint': {
            'line-width': 4,
            // Use a get expression (https://maplibre.org/maplibre-style-spec/expressions/#get)
            // to set the line-color to a feature property value.
            'line-color': ['get', 'color'],
            'line-dasharray': [2, 2]
          }
        });
        polylineList.push(idLayer)
      }
    }
    if(isEnableGoogleMap) {
      googleMap.map.fitBounds(bounds)
    } else {
      mapService.fitBounds(bounds, { padding: 100, animate: false })
    }
  
    return polylineList
  },

  drawPolyLineNew({
    mapService, req, outsideList = [],
    isEnableGoogleMap
  }) {
    const polylineList = []
    const bounds = new window.maplibregl.LngLatBounds()
    req.map(point => bounds.extend(point.location))
    let curvature = SHOWROUTE_CURVE
    for (let i = 0; i < req.size - 1; i += 1) {
      const outSideFullDay = _.findIndex(outsideList,
        location => _.toString(req.get(i + 1).id) === _.toString(location))

      const lat = req.get(i).location.lat
      const lng = req.get(i).location.lng
      const nextLat = req.get(i + 1).location.lat
      const nextLng = req.get(i + 1).location.lng

      const path = mapUtils.getLatLngPath(
        { lat, lng },
        { lat: nextLat, lng: nextLng },
        curvature, isEnableGoogleMap
      )
      curvature = -curvature

      const idLayer = uuidv4()
      mapService.addSource(idLayer, {
        type: 'geojson',
        data: {
          type: 'FeatureCollection',
          features: [
            {
              type: 'Feature',
              properties: {
                color: outSideFullDay !== -1 ? '#f04433' : 'rgba(14,115,15,1)'
              },
              geometry: {
                type: 'LineString',
                coordinates: path
              }
            },
          ]
        }
      })
      mapService.addLayer({
        id: idLayer,
        type: 'line',
        source: idLayer,
        paint: {
          'line-width': 4,
          // Use a get expression (https://maplibre.org/maplibre-style-spec/expressions/#get)
          // to set the line-color to a feature property value.
          'line-color': ['get', 'color'],
          'line-dasharray': [2, 2]
        }
      })
      polylineList.push(idLayer)
    }
    mapService.fitBounds(bounds, { padding: 130, animate: false })
    return polylineList
  },
  drawDirectionRouteForGoogle(googleMap, coordinates, outsideList) {
    const flightPath = new google.maps.Polyline({
      path: coordinates,
      strokeColor: outsideList.length > 0 ? '#f04433' : 'rgba(14,115,15,1)',
      strokeOpacity: 1.0,
      strokeWeight: 6,
      map: googleMap.map
    });
    return [flightPath]
  },
  drawDirectionRoute(mapService, coordinates, outsideList) {
    this.removeLayer(mapService, ID_ROUTE)
    mapService.addSource(ID_ROUTE, {
      'type': 'geojson',
      'data': {
        'type': 'Feature',
        'properties': {},
        'geometry': {
          'type': 'LineString',
          'coordinates': coordinates
        }
      }
    });
    mapService.addLayer({
      'id': ID_ROUTE,
      'type': 'line',
      'source': ID_ROUTE,
      'layout': {
        'line-join': 'round',
        'line-cap': 'round'
      },
      'paint': {
        'line-color': outsideList.length > 0 ? '#f04433' : 'rgba(14,115,15,1)',
        'line-width': 6
      }
    });
    return [ID_ROUTE]
  },
  larp(a, b, t) {
    return { lat: a.lat + (b.lat - a.lat) * t, lng: a.lng + (b.lng - a.lng) * t }
  },
  getBezier(points, t) {
    const ab = mapUtils.larp(points[0], points[1], t)
    const bc = mapUtils.larp(points[1], points[2], t)
    const cd = mapUtils.larp(points[2], points[3], t)
    const abbc = mapUtils.larp(ab, bc, t)
    const bccd = mapUtils.larp(bc, cd, t)

    return mapUtils.larp(abbc, bccd, t)
  },
  getBezierHandles(p0, p1, curvature) {
    const q2 = { lat: (p0.lat + p1.lat) / 2, lng: (p0.lng + p1.lng) / 2 }
    const q1 = { lat: (p0.lat + q2.lat) / 2, lng: (p0.lng + q2.lng) / 2 }
    const q3 = { lat: (q2.lat + p1.lat) / 2, lng: (q2.lng + p1.lng) / 2 }

    const dLat = p1.lat - p0.lat
    const dLng = p1.lng - p0.lng

    if (p0.lng > p1.lng) {
      return {
        a: { lat: q1.lat + (-dLng * curvature), lng: q1.lng + (dLat * curvature) },
        b: { lat: q3.lat + (-dLng * curvature), lng: q3.lng + (dLat * curvature) }
      }
    }
    return {
      a: { lat: q1.lat - (-dLng * curvature), lng: q1.lng + (dLat * curvature) },
      b: { lat: q3.lat - (-dLng * curvature), lng: q3.lng + (dLat * curvature) }
    }
  },
  getLatLngPath(p0, p1, curvature = SHOWROUTE_CURVE, isEnableGoogleMap = true) {
    const handles = mapUtils.getBezierHandles(p0, p1, curvature)

    const points = [p0, handles.a, handles.b, p1]

    const path = []

    const sections = SHOWROUTE_SECTION

    for (let t = 0; t <= sections; t += 1) {
      const b = mapUtils.getBezier(points, t / sections)
      if(isEnableGoogleMap) {
        path.push(new window.google.maps.LatLng(b.lat, b.lng))
      } else {
        path.push([b.lng, b.lat])
      }
    }

    return path
  },
  drawPrevRouteByPolyLine(googleMap, response, outsideList) {
    const polyline = new window.google.maps.Polyline({
      path: [],
      strokeColor: outsideList.length > 0 ? '#f04433' : 'rgba(14,115,15,1)',
      strokeWeight: 6,
    })
    const bounds = new window.google.maps.LatLngBounds()
    const legs = response.routes[0].legs
    for (let i = 0; i < legs.length; i += 1) {
      const steps = legs[i].steps
      for (let j = 0; j < steps.length; j += 1) {
        const nextSegment = steps[j].path
        for (let k = 0; k < nextSegment.length; k += 1) {
          polyline.getPath().push(nextSegment[k])
          bounds.extend(nextSegment[k])
        }
      }
    }

    polyline.setMap(googleMap.map)
    googleMap.map.fitBounds(bounds)
    return [polyline]
  },
  buildPrevLocations(locations, isRoundTrip) {
    const prevLocations = []
    const locationLatLng = _.filter(locations, lo => !_.isUndefined(lo.lat) && !_.isUndefined(lo.lng))
    const size = locationLatLng.length
    for (let index = 0; index < size; index += 1) {
      if (!isRoundTrip || (isRoundTrip && index !== size - 1)) {
        prevLocations.push(JSON.stringify([locationLatLng[index].lat, locationLatLng[index].lng]))
      }
    }
    return prevLocations
  },
  isChangeLocations(locations, prevLocations) {
    return (JSON.stringify(locations) !== JSON.stringify(prevLocations))
  },
  handleDurationResult(value) {
    const result = { src: '', duration: '', unitTime: '' }
    let duration = 0
    let unitTime
    if (value / 86400 >= 1) {
      duration = Math.round(value / 86400)
      unitTime = I18n.t('webapp.booking.day')
    } else if (value / 3600 >= 1) {
      duration = Math.round(value / 3600)
      unitTime = I18n.t('webapp.booking.hr')
    } else {
      duration = Math.round(value / 60) || 1
      unitTime = I18n.t('webapp.booking.min')
    }

    $('.infobox_offline_driver').html('')
    result.duration = duration
    result.unitTime = unitTime
    result.value = value

    return result
  },
  getPointAtDistance(degreesLat1, degreesLon1, d, bearing, R = 6371) {
    // lat: initial latitude, in degrees
    // lon: initial longitude, in degrees
    // d: target distance from initial
    // bearing: (true) heading in degrees
    // R: optional radius of sphere, defaults to mean radius of earth

    // Returns new lat/lon coordinate {d}km from initial, in degrees
    const lat1 = degreesToRadians(degreesLat1)
    const lon1 = degreesToRadians(degreesLon1)
    const a = degreesToRadians(bearing)
    const lat2 = Math.asin(Math.sin(lat1) * Math.cos(d / R) + Math.cos(lat1) * Math.sin(d / R) * Math.cos(a))
    const lon2 = lon1 + Math.atan2(
      Math.sin(a) * Math.sin(d / R) * Math.cos(lat1),
      Math.cos(d / R) - Math.sin(lat1) * Math.sin(lat2)
    )
    return [radiansToDegrees(lat2), radiansToDegrees(lon2)]
  },
  updateCustomMarker(marker, optionsIcons) {
    const elementMarker = marker.getElement()
    elementMarker.style.backgroundImage = `url(${optionsIcons.icon})`;
    elementMarker.style.width = optionsIcons.width;
    elementMarker.style.height = optionsIcons.height;
    elementMarker.style.top = optionsIcons.top;
    if (optionsIcons.left) {
      elementMarker.style.left = optionsIcons.left;
    } else {
      elementMarker.style.left = 'unset'
    }
    elementMarker.innerHTML = ''
    if (optionsIcons.content) {
      const content = document.createElement('div')
      content.innerText = optionsIcons.content.text
      content.className = optionsIcons.content.className ? optionsIcons.content.className : "label-marker"
      content.style.color = optionsIcons.content.color

      elementMarker.appendChild(content)
    }
    if (optionsIcons.label) {
      const label = document.createElement('span')
      label.innerText = optionsIcons.label.text
      label.className = optionsIcons.label.className ? optionsIcons.label.className : "label-marker"
      label.style.color = optionsIcons.label.color

      elementMarker.appendChild(label)
    }
  },
  createCustomMarker(optionsIcons, containerClass = 'marker-container') {
    const elMarker = document.createElement('div')
    const icon = (
      // eslint-disable-next-line react/jsx-filename-extension
      <div
        style={{
          backgroundImage: `url(${optionsIcons.icon})`,
          width: optionsIcons.width,
          height: optionsIcons.height,
          ...(optionsIcons.left && { left: optionsIcons.left })
        }}
        className={containerClass}
      >
        {optionsIcons.label && (
          <span
            style={{
              color: optionsIcons.label.color,
              ...(optionsIcons.label.top && { top: optionsIcons.label.top }),
            }}
            className={optionsIcons.label.className}
          >
            {optionsIcons.label.text}
          </span>
        )}
      </div>
    )
    elMarker.innerHTML = renderToStaticMarkup(icon)
    return elMarker
  },
  removeLayer(map, id) {
    const hasLayer = map.getLayer(id)
    if(hasLayer) {
      map.removeLayer(id)
      map.removeSource(id)
    }
  },
  removeLayerMegazone(map, idMegazone) {
    const [idCircle, idLine] = this.getIdForMegazone(idMegazone)
    const hasLayerCircle = map.getLayer(idCircle)
    const hasLayerLine = map.getLayer(idLine)
    if(hasLayerCircle) map.removeLayer(idCircle)
  
    if(hasLayerLine) map.removeLayer(idLine)
  },
  getIdForMegazone(id) {
    return [id + '_circle', id + '_line']
  },
  setMarker(mapService, lng, lat, draggable = true) {
    return new window.maplibregl.Marker({ draggable: draggable, anchor: 'bottom', offset: [0, 0] })
      .setLngLat([lng, lat])
      .addTo(mapService || '')
  },
  removeMarker(marker, isEnableGoogleMap = true) {
    if(!marker) return
    if (isEnableGoogleMap) {
      marker.setMap(null)
    } else {
      marker.remove();
    }
  },
  handleFitBounds({
    mapService, coordinates = [], googleMap, isEnableGoogleMap
  }) {
    if(isEnableGoogleMap) {
      const bounds = new window.google.maps.LatLngBounds()
      coordinates?.forEach(location => {
        const latLng = new window.google.maps.LatLng(location.lat, location.lng)
        bounds.extend(latLng)
      });
      return googleMap.map.fitBounds(bounds)
    }
    const bounds = new window.maplibregl.LngLatBounds()
    coordinates?.forEach(location => {
      const lnglat = new window.maplibregl.LngLat(location.lng, location.lat)
      bounds.extend(lnglat)
    });
    mapService.fitBounds(bounds, { padding: 100, animate: false })
  },
  isEnableGoogleMap: (extraInfos = {}) => {
    return true
    // return !extraInfos?.enable_new_maps_display
  },
  isGoogleMap: (extraInfos = {}) => {
    return !extraInfos?.enable_new_maps_display
  },
  getLatLngFromMarker: (marker, isEnableGoogleMap = true) => {
    if(isEnableGoogleMap) {
      return {
        lat: marker.getPosition().lat(),
        lng: marker.getPosition().lng()
      }
    }
    return {
      lat: marker.getLngLat().lat,
      lng: marker.getLngLat().lng
    }
  },
  getIconPinFromDrapDrop: () => {
    let url
    let labelOrigin = labelDropDrapPositions
    switch (I18n.language) {
      case 'TH':
      case 'th':
        url = IMAGES.PIN_FROM_DRAP_DROP_TH
        labelOrigin = { x: 92, y: 63 }
        break
      case 'ID':
      case 'id':
        url = IMAGES.PIN_FROM_DRAP_DROP_ID
        break
      default:
        url = IMAGES.PIN_FROM_DRAP_DROP_EN
        break
    }
    return { url, labelOrigin } 
  },
  getIconPinToDrapDrop: () => {
    let url
    let labelOrigin = labelDropDrapPositions
    switch (I18n.language) {
      case 'TH':
      case 'th':
        url = IMAGES.PIN_TO_DRAP_DROP_TH
        labelOrigin = { x: 92, y: 63 }
        break
      case 'ID':
      case 'id':
        url = IMAGES.PIN_TO_DRAP_DROP_ID
        break
      default:
        url = IMAGES.PIN_TO_DRAP_DROP_EN
        break
    }
    return { url, labelOrigin } 
  },
  drawPolyLineDetail(googleMap, req, outsideList = []) {
    const polylineList = []
    const bounds = new google.maps.LatLngBounds()
    const lineSymbol = {
      path: 'M 0,-1 0,1',
      strokeOpacity: 1,
      scale: 4
    }
    req.map(point => bounds.extend(point.location))
    let curvature = SHOWROUTE_CURVE
    for (let i = 0; i < req.size - 1; i += 1) {
      const outSideFullDay = _.findIndex(outsideList,
        location => _.toString(req.get(i + 1).id) === _.toString(location))
      const path = mapUtils.getLatLngPath({ lat: req.get(i).location.lat, lng: req.get(i).location.lng },
        { lat: req.get(i + 1).location.lat, lng: req.get(i + 1).location.lng }, curvature)
      curvature = -curvature
      const options = {
        path,
        geodesic: false,
        strokeColor: outSideFullDay !== -1 ? '#f04433' : 'rgba(14,115,15,1)',
        strokeOpacity: 0,
        icons: [{
          icon: lineSymbol,
          offset: '0',
          repeat: '20px'
        }],
        map: googleMap.map
      }
      const polyline = new google.maps.Polyline(options)
      polylineList.push(polyline)
    }
    googleMap.map.fitBounds(bounds)

    return polylineList
  }
}

export default mapUtils
